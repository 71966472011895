import { makeStyles } from '@material-ui/core';

const useStaticContentStyles = makeStyles({
  text: {
    whiteSpace: 'break-spaces',
    '& *': {
      color: 'white !important',
      fontFamily: 'Montserrat !important',
    }
  }
});

export default useStaticContentStyles;