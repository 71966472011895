import {
  CircularProgress,
  Grid,
  TextField,
} from '@material-ui/core';
import PageLayout from 'layout/PageLayout/PageLayout';
import { useEffect, useMemo, useState } from 'react';
import VehicleSelector from 'components/VehicleSelector/VehicleSelector';
import { useHistory, useParams } from 'react-router-dom';
import RequestHandling from 'components/RequestHandling';
import { useFindAirportAlreadyTakenPassengersQuery, useGetAirportCartQuery, useGetEventQuery, useListMyPassengersQuery } from 'apollo/queries';
import PeriodChooser from './PeriodChooser/PeriodChooser';
import StepperLayout from 'layout/StepperLayout/StepperLayout';
import TransportInformation from './TransportInformation/TransportInformation';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';
import { useCreateAirportLineMutation } from 'apollo/mutations';
import { PassengerFragment, VehicleFieldsFragment } from 'apollo/queries/types';
import { FlightInformation } from 'type/FlightInformation';
import { TrainInformation } from 'type/TrainInformation';
import DatePicker from 'components/DatePicker/DatePicker';
import AddPassengerModal from 'components/AddPassengerModal/AddPassengerModal';
import { Autocomplete } from '@material-ui/lab';
import useAirportOrCityTransferStyles from './AirportOrCityTransferStyle';
import compile from 'utils/domain';
import * as Luq from 'luq';
import { formatDateForSQL, formatDateWithTimezone } from 'utils/formatDate';
import moment from 'moment-timezone';

const AirportOrCityTransfert: React.FC = () => {

  const classes = useAirportOrCityTransferStyles();
  const history = useHistory();
  const { eventId, basketId } = useParams<{ eventId: string, basketId: string }>();

  const [step, setStep] = useState<number>(1);

  const isNextAvailable = (step: number): boolean => {
    switch (step) {
      case 1:
        return (arrival || departure);
      case 2:
        return (((arrivalTransport === 'flight' && arrivalFlightInformations.transportCode !== '') || (arrivalTransport === 'train' && arrivalTrainInformation.transportNumber !== '')) && (arrivalPassenger !== null));
      case 3:
        return (arrivalSelectedVehicle !== null);
      case 4:
        return (((departureTransport === 'flight' && departureFlightInformations.transportCode !== '') || (departureTransport === 'train' && departureTrainInformation.transportNumber !== '')) && (departurePassenger !== null));
      case 5:
        return (departureSelectedVehicle !== null);
      default:
        return false;
    }
  };

  const nextStepAction = async (step: number) => {
    switch (step) {
      case 1:
        if (arrival) {
          setStep(2);
        } else {
          setStep(4);
        }
        break;
      case 2:
        setStep(3);
        break;
      case 3:
        if (departure) {
          setStep(4);
        } else {
          try {
            await addLineToBasket(arrivalTransport, arrivingDate, arrivingTime, arrivalTrainInformation, arrivalFlightInformations, arrivalSelectedVehicle, true);
            history.push(`/eventBasket/${basketId}`);
          } catch (err) {
            console.log(err);
          }
        }
        break;
      case 4:
        setStep(5);
        break;
      case 5:
        try {
          await addLineToBasket(arrivalTransport, arrivingDate, arrivingTime, arrivalTrainInformation, arrivalFlightInformations, arrivalSelectedVehicle, true);
          await addLineToBasket(departureTransport, departureDate, departureTime, departureTrainInformation, departureFlightInformations, departureSelectedVehicle, false);
          history.push(`/eventBasket/${basketId}`);
        } catch (err) {
          console.log(err);
        }
        break;
      default:
        break;
    }
  };

  //Step 1 variables
  const [arrival, setArrival] = useState<boolean>(false);
  const [departure, setDeparture] = useState<boolean>(false);

  //Step 2 variables
  const [arrivingDate, setArrivingDate] = useState(new Date());
  const [arrivingTime, setArrivingTime] = useState('00:00');
  const [arrivalTransport, setArrivalTransport] = useState<string | null>(null);
  const [arrivalFlightInformations, setArrivalFlightInformations] = useState<FlightInformation>({
    transportCode: '',
    transportNumber: '',
    transportOrigin: '',
    transportDestination: '',
  });
  const [arrivalTrainInformation, setArrivalTrainInformation] = useState<TrainInformation>({
    transportNumber: '',
    transportOrigin: '',
    transportDestination: ''
  });


  //Step 3 variables
  const [arrivalSelectedVehicle, setArrivalSelectedVehicle] = useState<number | null>(null);

  //Step 4 variables
  const [departureDate, setDepartureDate] = useState(new Date());
  const [departureTime, setDepartureTime] = useState('00:00');
  const [departureTransport, setDepartureTransport] = useState<string | null>(null);
  const [departureFlightInformations, setDepartureFlightInformations] = useState<FlightInformation>({
    transportCode: '',
    transportNumber: '',
    transportOrigin: '',
    transportDestination: '',
  });
  const [departureTrainInformation, setDepartureTrainInformation] = useState<TrainInformation>({
    transportNumber: '',
    transportOrigin: '',
    transportDestination: ''
  });
  const [departurePassenger, setDeparturePassenger] = useState<PassengerFragment | null>(null);
  const [departurePassengerChanged, setDeparturePassengerChanged] = useState<boolean>(false);
  const [arrivalPassenger, setArrivalPassenger] = useState<PassengerFragment | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { data: passengersData, loading: passengersLoading, refetch } = useListMyPassengersQuery();

  //Step 5 variables
  const [departureSelectedVehicle, setDepartureSelectedVehicle] = useState<number | null>(null);

  const getAirportCartQueryResults = useGetAirportCartQuery({
    variables: {
      id: parseInt(basketId)
    },
    skip: basketId === undefined,
  });

  const { data: eventData } = useGetEventQuery({ variables: { eventId: parseInt(eventId) || 0 } });

  useEffect(() => {
    if (!getAirportCartQueryResults.loading && !getAirportCartQueryResults.error) {
      if (getAirportCartQueryResults.data?.getCommunicorBasket?.eventId.startDate) {
        setArrivingDate(new Date(getAirportCartQueryResults.data.getCommunicorBasket.eventId.startDate));
      }
      if (getAirportCartQueryResults.data?.getCommunicorBasket?.eventId.endDate) {
        setDepartureDate(new Date(getAirportCartQueryResults.data.getCommunicorBasket.eventId.endDate));
      }
    }
  }, [getAirportCartQueryResults.loading, getAirportCartQueryResults.error, getAirportCartQueryResults.data?.getCommunicorBasket?.eventId.startDate, getAirportCartQueryResults.data?.getCommunicorBasket?.eventId.endDate]);

  const computePrice = (vehicleId: number | null) => {
    if (getAirportCartQueryResults.error || getAirportCartQueryResults.loading) {
      return 0;
    }

    const vehicleLine = getAirportCartQueryResults.data?.getCommunicorBasket?.pricelistId?.airportLineIds.find(line => line.vehicleTypeId.id === vehicleId);

    return vehicleLine?.pricePerUnit || 0;
  };

  const [createAirportLineMutation] = useCreateAirportLineMutation();

  const addLineToBasket = async (
    transportType: string | null,
    date: Date,
    time: string,
    trainInformation: TrainInformation,
    flightInformation: FlightInformation,
    selectedVehicle: number | null,
    arrival: boolean
  ) => {
    const priceLine = getAirportCartQueryResults.data?.getCommunicorBasket?.pricelistId?.airportLineIds.find(line => line.vehicleTypeId.id === selectedVehicle);

    const transportInformation: FlightInformation | TrainInformation = transportType === 'flight' ? flightInformation : trainInformation;

    if (priceLine?.id &&
      (
        (arrival && arrivalPassenger !== null && arrivalPassenger.id !== null) ||
        (!arrival && departurePassenger !== null && departurePassenger.id !== null)
      )
    ) {
      const [ day, month, year ] = date.toLocaleDateString().split('/');
      const [ hour, minute ]: string[] = time.split(':');
      const offset = -(eventData?.getCommunicorEvent?.tz?.value ? (moment.tz.zone(eventData?.getCommunicorEvent?.tz?.value)?.utcOffset(date.getTime()) || 0)/60 : 0);
      const offsetHours = Math.floor(Math.abs(offset));
      const offsetMinutes = (Math.abs(offset) - offsetHours) * 60;

      const localDateTime = new Date(`${year}-${month}-${day}T${hour}:${minute}:00.000${offset >= 0 ? '+' : '-'}${Math.abs(offsetHours) >= 10 ? '' + Math.abs(offsetHours) : '0' + Math.abs(offsetHours)}:${Math.abs(offsetMinutes) >= 10 ? '' + Math.abs(offsetMinutes) : '0' + Math.abs(offsetMinutes)}`);
      try {
        await createAirportLineMutation({
          variables: {
            basketId: parseInt(basketId),
            templateId: priceLine?.id,
            passengerId: arrival ? arrivalPassenger?.id || -1 : departurePassenger?.id || -1,
            quantity: 1,
            [arrival ? 'startDate' : 'endDate']: formatDateForSQL(localDateTime),
            ...transportInformation,
            externalTransportType: transportType || ''
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const tzValue = eventData?.getCommunicorEvent?.tz?.value;

  const vehicles = getAirportCartQueryResults.data?.getCommunicorBasket?.pricelistId?.airportLineIds.reduce((acc: VehicleFieldsFragment[], value) => {
    acc.push(value.vehicleTypeId);
    return acc;
  }, []);

  const arrivalSelectedVehicleDetails = useMemo(() => {
    if (vehicles !== null && vehicles !== undefined && arrivalSelectedVehicle !== null) {
      const vehicle = vehicles.find((vehicle) => vehicle.id === arrivalSelectedVehicle);
      return vehicle || null;
    } else {
      return null;
    }
  }, [vehicles, arrivalSelectedVehicle]);

  const departureSelectedVehicleDetails = useMemo(() => {
    if (vehicles !== null && vehicles !== undefined && departureSelectedVehicle !== null) {
      const vehicle = vehicles.find((vehicle) => vehicle.id === departureSelectedVehicle);
      return vehicle || null;
    } else {
      return null;
    }
  }, [vehicles, departureSelectedVehicle]);




  const { data: arrivalAlreadyTakenPassengersData, loading: arrivalAlreadyTakenPassengersLoading } = useFindAirportAlreadyTakenPassengersQuery({
    variables: {
      filter: compile(
        <Luq.And>
          <Luq.Eq field="airport_ids.basket_id" value={parseInt(basketId)}></Luq.Eq>
          <Luq.Neq field="airport_ids.start_date" value={null}></Luq.Neq>
        </Luq.And>
      )
    },
    fetchPolicy: 'network-only',
    skip: basketId === undefined
  });

  const { data: departureAlreadyTakenPassengersData, loading: departureAlreadyTakenPassengersLoading } = useFindAirportAlreadyTakenPassengersQuery({
    variables: {
      filter: compile(
        <Luq.And>
          <Luq.Eq field="airport_ids.basket_id" value={parseInt(basketId)}></Luq.Eq>
          <Luq.Neq field="airport_ids.end_date" value={null}></Luq.Neq>
        </Luq.And>
      )
    },
    fetchPolicy: 'network-only',
    skip: basketId === undefined
  });

  const arrivalAlreadyTakenPassengersIds = useMemo(() => {
    if (arrivalAlreadyTakenPassengersData !== undefined && !arrivalAlreadyTakenPassengersLoading) {
      return arrivalAlreadyTakenPassengersData.searchResUsers.map((passenger) => passenger.id);
    } else {
      return [];
    }
  }, [arrivalAlreadyTakenPassengersData, arrivalAlreadyTakenPassengersLoading]);

  const departureAlreadyTakenPassengersIds = useMemo(() => {
    if (departureAlreadyTakenPassengersData !== undefined && !departureAlreadyTakenPassengersLoading) {
      return departureAlreadyTakenPassengersData.searchResUsers.map((passenger) => passenger.id);
    } else {
      return [];
    }
  }, [departureAlreadyTakenPassengersData, departureAlreadyTakenPassengersLoading]);


  return (
    <PageLayout
      navigation
      title="Airport or city transfer"
      backButton
    >
      <RequestHandling {...getAirportCartQueryResults}>
        {cart =>
          <>
            {step === 1 && (
              <PeriodChooser
                arrival={arrival}
                departure={departure}
                setArrival={setArrival}
                setDeparture={setDeparture}
                onValidate={() => nextStepAction(step)}
              />
            )}
            {step === 2 && arrival && (
              <StepperLayout
                stepName={'Arrival Information'}
                onPrevious={() => setStep(step - 1)}
                onNext={() => nextStepAction(step)}
                canNext={isNextAvailable(step)}
                align="stretch"
                contentSize={8}
                disabled={arrivalPassenger === null || arrivalAlreadyTakenPassengersIds.includes(arrivalPassenger.id)}
                errorChild={
                  arrivalPassenger !== null && arrivalAlreadyTakenPassengersIds.includes(arrivalPassenger.id) &&
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    This passenger already has an arrival
                  </div>
                }
              >
                <Grid item xs={5}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="none"
                    id="arrival-date"
                    label="Arriving Date"
                    value={formatDateWithTimezone(arrivingDate, tzValue)}
                    onChange={(date) => date !== null && setArrivingDate(date)}
                    inputVariant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    id="date"
                    label="Arriving Time (if available)"
                    type="time"
                    defaultValue={arrivingTime}
                    onChange={(event) => setArrivingTime(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={[{ id: null }, ...passengersData?.searchResUsers || []]}
                    value={arrivalPassenger}
                    getOptionLabel={
                      (passenger) => {
                        if (passenger.id === null) {
                          return 'Create a new passenger';
                        } else {
                          return passenger.isPassengersGroup ? `${passenger.groupName}` : `${passenger.name} ${passenger.firstname}`;
                        }
                      }
                    }
                    renderInput={(params) => passengersLoading ? <CircularProgress /> : <TextField {...params} label={arrivalPassenger?.isPassengersGroup ? 'Passenger group name *' : 'Passenger *'} />}
                    classes={{
                      paper: classes.root,
                    }}
                    onChange={
                      (event, newValue) => {
                        if (newValue === null) {
                          setArrivalPassenger(newValue);
                          !departurePassengerChanged && setDeparturePassenger(newValue);
                        } else if (newValue.id === null) {
                          setOpenModal(true);
                        } else {
                          setArrivalPassenger(newValue);
                          !departurePassengerChanged && setDeparturePassenger(newValue);
                        }
                      }
                    }
                  />
                </Grid>
                <TransportInformation
                  transportType={arrivalTransport}
                  setTransportType={setArrivalTransport}
                  flightInformation={arrivalFlightInformations}
                  setFlightInformation={setArrivalFlightInformations}
                  trainInformation={arrivalTrainInformation}
                  setTrainInformation={setArrivalTrainInformation}
                />
              </StepperLayout>
            )}
            {
              step === 3 && arrival && (
                <StepperLayout
                  stepName={'Arrival Car & Services'}
                  onPrevious={() => setStep(step - 1)}
                  onNext={() => nextStepAction(step)}
                  canNext={isNextAvailable(step)}
                  controlAdorned={
                    <PriceDisplay pricelist={cart.getCommunicorBasket?.pricelistId} price={computePrice(arrivalSelectedVehicle)} />
                  }
                  isLastStep={!departure}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <VehicleSelector
                      options={vehicles || []}
                      selectedVehicle={arrivalSelectedVehicle}
                      setSelectedVehicle={setArrivalSelectedVehicle}
                    />
                    {
                      arrivalSelectedVehicleDetails !== null &&
                      arrivalPassenger !== null &&
                      arrivalPassenger.isPassengersGroup &&
                      arrivalPassenger.groupSize &&
                      arrivalPassenger.groupSize > arrivalSelectedVehicleDetails.numberSeats! &&
                      <div style={{ marginTop: '15px' }}>
                        Selected vehicle has less seats than the group size
                      </div>
                    }
                  </div>
                </StepperLayout>
              )
            }
            {
              step === 4 && departure && (
                <StepperLayout
                  stepName={'Departure Information'}
                  onPrevious={() => setStep(step - 1)}
                  onNext={() => nextStepAction(step)}
                  canNext={isNextAvailable(step)}
                  contentSize={8}
                  align="stretch"
                  disabled={departurePassenger === null || departureAlreadyTakenPassengersIds.includes(departurePassenger.id)}
                  errorChild={
                    departurePassenger !== null && departureAlreadyTakenPassengersIds.includes(departurePassenger.id) &&
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      This passenger already has a departure
                    </div>
                  }
                >
                  <Grid item xs={5}>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="none"
                      id="departure-date"
                      label="Date of departure"
                      value={departureDate}
                      onChange={(date) => date !== null && setDepartureDate(date)}
                      inputVariant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      id="date"
                      label="Departure Time (if available)"
                      type="time"
                      defaultValue={departureTime}
                      onChange={(event) => setDepartureTime(event.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={[{ id: null }, ...passengersData?.searchResUsers || []]}
                      value={departurePassenger}
                      getOptionLabel={
                        (passenger) => {
                          if (passenger.id === null) {
                            return 'Create a new passenger';
                          } else {
                            return passenger.isPassengersGroup ? `${passenger.groupName}` : `${passenger.name} ${passenger.firstname}`;
                          }
                        }
                      }
                      renderInput={(params) => passengersLoading ? <CircularProgress /> : <TextField {...params} label={departurePassenger?.isPassengersGroup ? 'Passenger group name *' : 'Passenger *'} />}
                      classes={{
                        paper: classes.root,
                      }}
                      onChange={
                        (event, newValue) => {
                          if (newValue === null) {
                            setDeparturePassenger(newValue);
                            setDeparturePassengerChanged(true);
                          } else if (newValue.id === null) {
                            setOpenModal(true);
                          } else {
                            setDeparturePassenger(newValue);
                            setDeparturePassengerChanged(true);
                          }
                        }
                      }
                    />
                  </Grid>
                  <TransportInformation
                    transportType={departureTransport}
                    setTransportType={setDepartureTransport}
                    flightInformation={departureFlightInformations}
                    setFlightInformation={setDepartureFlightInformations}
                    trainInformation={departureTrainInformation}
                    setTrainInformation={setDepartureTrainInformation}
                  />
                </StepperLayout>
              )
            }
            {
              step === 5 && departure && (
                <StepperLayout
                  stepName={'Departure Car & Services'}
                  onPrevious={() => setStep(step - 1)}
                  onNext={() => nextStepAction(step)}
                  canNext={isNextAvailable(step)}
                  isLastStep
                  controlAdorned={
                    <PriceDisplay pricelist={cart.getCommunicorBasket?.pricelistId} price={computePrice(departureSelectedVehicle)} />
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <VehicleSelector
                      options={vehicles || []}
                      selectedVehicle={departureSelectedVehicle}
                      setSelectedVehicle={setDepartureSelectedVehicle}
                    />
                    {
                      departureSelectedVehicleDetails !== null &&
                      departurePassenger !== null &&
                      departurePassenger.isPassengersGroup &&
                      departurePassenger.groupSize &&
                      departurePassenger.groupSize > departureSelectedVehicleDetails.numberSeats! &&
                      <div style={{ marginTop: '15px' }}>
                        Selected vehicle has less seats than the group size
                      </div>
                    }
                  </div>
                </StepperLayout>
              )
            }
          </>
        }
      </RequestHandling>
      <AddPassengerModal
        opened={openModal}
        setOpened={setOpenModal}
        setPassenger={step === 4 && departure ? setDeparturePassenger : setArrivalPassenger}
        refetch={refetch}
      />
    </PageLayout>
  );
};

export default AirportOrCityTransfert;