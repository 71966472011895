export type Nodes<T> = T[];
export type NodeOrNodes<T> = T | Nodes<T>;

export type JSXChildren = NodeOrNodes<JSXNode>;
export type JSXChild = JSXNode;

export enum NodeType {
  And = 'And',
  Or = 'Or',
  Not = 'Not',
  Defined = 'Defined',
  Undefined = 'Undefined',
  Eq = 'Eq',
  Neq = 'Neq',
  Gt = 'Gt',
  Gte = 'Gte',
  Lt = 'Lt',
  Lte = 'Lte',
  Like = 'Like',
  In = 'In',
  NotIn = 'NotIn',

  Order = 'Order',
  Asc = 'Asc',
  Desc = 'Desc',
}

export type JSXNodeProps = {
  children?: JSXChildren;
  [key: string]: unknown;
};

export type JSXNode = {
  $$typeof?: symbol,
  type: (props: JSXNodeProps) => JSXBuiltNode | JSXNode;
  props: JSXNodeProps;
};

export type JSXBuiltNodeProps = {
  type: NodeType;
  children?: JSXChildren;
  [key: string]: unknown;
};

export type JSXBuiltNode = {
  type: (props: JSXNodeProps) => JSXBuiltNode | JSXNode;
  props: JSXBuiltNodeProps;
};

export type BuiltNodeProps = {
  [key: string]: unknown;
};

export interface BuiltNode {
  type: NodeType;
  props: BuiltNodeProps;
  children: Nodes<BuiltNode> | null;
}



export type CompilerType = (node: BuiltNode) => string;
