/* Framework imports ----------------------------------- */
import { TextField } from '@material-ui/core';
import React from 'react';

/* Module imports -------------------------------------- */

/* Component imports ----------------------------------- */

/* CustomTextfiled component prop types -------------------- */
interface CustomTextfiledProps {
  editable: boolean;
  value?: string | number | null;
  onChange: (value: string) => void;
  label: string;
  type?: string;
}

/* CustomTextfiled component ------------------------------- */
const CustomTextfiled: React.FC<CustomTextfiledProps> = ({ editable, value, onChange, label, type = 'text' }) => {
  if (editable) {
    return (
      <TextField
        error={value === null || value === undefined || value === '' || (type === 'number' && (isNaN(value as number) || (value as number) < 2))}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        fullWidth
        required
        label={label}
        type={type}
        InputProps={{
          inputProps: {
            min: type === 'number' ? 2 : undefined,
          }
        }}
      />
    );
  } else {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', color: 'lightgray', marginBottom: '15px' }}>
        <div style={{ marginBottom: '5px' }}>{label}</div>
        <div>{value}</div>
      </div>
    );
  }

};

/* Export CustomTextfiled component ------------------------ */
export default CustomTextfiled;
