import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { login } from 'utils/authentication';
import PageLayout from 'layout/PageLayout/PageLayout';
import useSignInStyles from './SignInStyle';

interface SignInProps {
  afterLogin?: () => void;
  onFailure?: (err: Error) => void;
}

const SignIn: React.FC<SignInProps> = ({ afterLogin, onFailure }) => {
  const classes = useSignInStyles();
  const history = useHistory();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loginFailed, setLoginFailed] = useState<boolean>(false);

  const handleLoginLogic = () => {
    login({ email, password })
      .then(
        () => {
          if (afterLogin !== undefined) {
            afterLogin();
          }
        }
      )
      .catch(
        (error) => {
          setLoginFailed(true);
          if (onFailure !== undefined) {
            onFailure(error);
          }
        }
      );
  };

  const handleLogin: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    handleLoginLogic();
  };

  const onForgotPasswordClicked: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLSpanElement> = (event) => {
    history.push(
      email ?
        `/login/forgotPassword/${encodeURIComponent(email)}` :
        `/login/forgotPassword/`
    );
  };

  return (
    <PageLayout
      title="Login"
    >
      <div className={classes.paper}>
        <Typography variant="h1" className={classes.margin}>
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            required
            id="email"
            label="Email Address"
            defaultValue={email}
            onChange={(event) => setEmail(event.target.value)}
            autoFocus
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
            className={classes.margin}
          />
          <TextField
            required
            id="password"
            label="Password"
            defaultValue={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter'){
                handleLoginLogic();
              }
            }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
            className={classes.margin}
            type="password"
          />
          <Button
            onClick={handleLogin}
            variant="contained"
            size="large"
          >
            Sign In
          </Button>
          <Link
            onClick={onForgotPasswordClicked}
            variant="body2"
          >
            Forgot password ?
          </Link>
          <Snackbar open={loginFailed} autoHideDuration={6000} onClose={() => setLoginFailed(false)}>
            <Alert onClose={() => setLoginFailed(false)} variant="filled" severity="error">
              Login failed
            </Alert>
          </Snackbar>
        </form>
      </div>
    </PageLayout>
  );
};

export default SignIn;
