import { Grid } from '@material-ui/core';
import { useDeleteTechnicalLineMutation, useUpdateTechnicalLineMutation } from 'apollo/mutations';
import { CommunicorTechnicalFieldsFragment } from 'apollo/queries/types';
import { CommunicorTechnicalTemplate } from 'apollo/types';
import React, { useRef, useState } from 'react';
import { formatPrice } from 'utils/formatPrice';
import CartItem from './CartItem';
import useCartItemCommonStyles from './CartItemCommonStyles';
import EditTechnicalLineModal from './EditTechnicalLineModal/EditTechnicalLineModal';

interface Props {
  line: CommunicorTechnicalFieldsFragment;
  currency: string | undefined;
  canDelete?: boolean;
  canEdit?: boolean;
  pricelist: Omit<CommunicorTechnicalTemplate, 'pricelistId'>[] | undefined;
}

const MeetAndGreet: React.FC<Props> = ({
  line,
  currency,
  canDelete = true,
  canEdit = true,
  pricelist
}) => {
  const classes = useCartItemCommonStyles();

  const editTechnicalLineModal = useRef();

  const [quantity, setQuantity] = useState<number>(line.quantity || 0);

  const [deleteTechnicalLineMutation] = useDeleteTechnicalLineMutation();

  const onDelete = async () => {
    try {
      await deleteTechnicalLineMutation({
        variables: {
          id: line.id
        },
        update(cache) {
          const id = cache.identify({
            __typename: 'CommunicorTechnical',
            id: line.id
          });
          cache.evict({ id: id });
          cache.gc();
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onEdit = () => {
    if (editTechnicalLineModal && editTechnicalLineModal.current) {
      // @ts-ignore
      editTechnicalLineModal.current.open();
    }
  };

  const [updateTechnicalLineMutation] = useUpdateTechnicalLineMutation();

  const handleEdit = async () => {
    try {
      await updateTechnicalLineMutation({
        variables: {
          id: line.id,
          quantity: quantity
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const currentPriceline = pricelist?.find((current) => current.technicalTypeId?.id === line.technicalTypeId?.id && line.mgType?.id === current.mgType?.id);

  return (
    <CartItem
      title={`Technical Support`}
      price={(line.totalPrice !== null && line.totalPrice !== undefined) ? formatPrice(line.totalPrice, currency || '£') : ''}
      canDelete={canDelete}
      canEdit={canEdit}
      onDelete={onDelete}
      onEdit={onEdit}
    >
      <Grid item xs={6} className={classes.title}>
        {`${line.technicalTypeId?.isMg ? 'Type of M&G' : 'Service'}:`}
      </Grid>
      <Grid item xs={6} className={classes.title}>
          Quantity:
      </Grid>
      <Grid item xs={6} className={classes.content}>
        {line.technicalTypeId?.isMg ? line.mgType?.name : line.name}
      </Grid>
      <Grid item xs={6} className={classes.content}>
        {line.quantity}
      </Grid>
      <EditTechnicalLineModal
        quantity={quantity}
        setQuantity={setQuantity}
        onValidate={handleEdit}
        ref={editTechnicalLineModal}
        priceline={currentPriceline}
      />
    </CartItem>
  );
};

export default MeetAndGreet;