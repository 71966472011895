import { Maybe } from 'apollo/types';
import moment from 'moment-timezone';

const defaultDateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const formatDate = (date: Date, options: Intl.DateTimeFormatOptions = defaultDateFormatOptions, locale: string = 'fr-FR'): string => {
  return (date).toLocaleDateString(
    locale,
    options
  );
};

export const formatDateStr = (dateStr: string, options?: Intl.DateTimeFormatOptions): string => {
  return formatDate(new Date(dateStr), options);
};

export const formatDateForSQL = (date: Date): string => {
  /* Exported format is '%Y-%m-%d %H:%M:%S' */

  return date.toISOString().slice(0, 19).replace('T', ' ');
};

export const formatDateStrForSQL = (dateStr: string): string => {
  return formatDateForSQL(new Date(dateStr.replace(' ', 'T')));
};

export const formatDateForMobile = (date: Date): string => {
  /* Exported format is '%Y-%m-%dT%H:%M:%S' */

  /* This is especially useful for iOS
   * See : https://stackoverflow.com/a/13363791/8007661
   */

  return date.toISOString().slice(0, 19);
};

export const formatDateStrForMobile = (dateStr: string): string => {
  /* This is especially useful for iOS
   * See : https://stackoverflow.com/a/13363791/8007661
   */

  return formatDateForMobile(new Date(dateStr.replace(' ', 'T')));
};


export const formatDateForDatePicker = (date: Date | null) : string => {
  if (date === null) {
    return 'NC';
  }
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  return `${year}-${month}-${day < 10 ? `0${day}` : day  }`;
};

export const dateFromOdooDatetime = (str: string): Date | null => {
  if (str !== null && str !== undefined) {
    return new Date(str.replace(' ', 'T'));
  } else {
    return null;
  }
};

export const dateFromOdooDate = (str: string): Date | null => {
  if (str !== null && str !== undefined) {
    return new Date(str);
  } else {
    return null;
  }
};

const monthNames = [
  'January', 
  'February', 
  'March', 
  'April', 
  'May', 
  'June',
  'July', 
  'August', 
  'September', 
  'October', 
  'November', 
  'December'
];

export const monthName = (month: number): string => monthNames[month];

export const formatPeriod = (str1: string, str2: string): string => {
  const date1 = dateFromOdooDatetime(str1);
  const date2 = dateFromOdooDatetime(str2);

  if (date1 === null || date2 === null) {
    return 'NC';
  } else {
    const startDay = date1.getDate();
    const endDay = date2.getDate();

    const startMonth = date1.getMonth();
    const endMonth = date2.getMonth();
    
    const startYear = date1.getFullYear();
    const endYear = date2.getFullYear();

    let result = `${startDay} ${monthName(startMonth)}`;

    if (startYear !== endYear) {
      result += ` ${startYear}`;
    }
    result += ` to ${endDay} ${monthName(endMonth)} ${endYear}`;

    return result;
  }
};

export const formatLitteralDate = (str1: string): string => {
  const date1 = dateFromOdooDatetime(str1);

  if (date1 === null) {
    return 'NC';
  } else {
    const startDay = date1.getDate();

    const startMonth = date1.getMonth();
    
    const startYear = date1.getFullYear();

    let result = `${startDay} ${monthName(startMonth)} ${startYear}`;

    return result;
  }
};

export const formatDateWithTimezone = (date: Date, tzValue : Maybe<string> | undefined) => {
  const [ day, month, year ] = date.toLocaleDateString().split('/');
  const [ hour, minute ]: string[] = date.toLocaleTimeString(undefined, { hour12: false }).split(':');
  const offset = -(tzValue ? (moment.tz.zone(tzValue)?.utcOffset(date.getTime()) || 0)/60 : 0);
  const offsetHours = Math.floor(Math.abs(offset));
  const offsetMinutes = (Math.abs(offset) - offsetHours) * 60;

  return new Date(`${year}-${month}-${day}T${hour}:${minute}:00.000${offset >= 0 ? '+' : '-'}${Math.abs(offsetHours) >= 10 ? '' + Math.abs(offsetHours) : '0' + Math.abs(offsetHours)}:${Math.abs(offsetMinutes) >= 10 ? '' + Math.abs(offsetMinutes) : '0' + Math.abs(offsetMinutes)}`);
};

export const formatLitteralTime = (str1: string, tzValue : Maybe<string> | undefined): string => {
  const date1 = dateFromOdooDatetime(str1);
  if (date1 === null) {
    return 'NC';
  } else {
    const localeOffset = moment().utcOffset();
    const eventOffset = tzValue ? moment.tz.zone(tzValue)?.utcOffset(date1.getTime()) : 0;

    date1.setMinutes(- localeOffset - (eventOffset ?? 0));
    const [hours, minutes, seconds] = date1.toLocaleTimeString(undefined, { hour12: false }).split(':');
    return `${hours}:${minutes}`;
  }
};