import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import { CommunicorAccreditationTemplate } from 'apollo/types';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { formatPrice } from 'utils/formatPrice';

interface EditCarPassLineModalProps {
  quantity: number;
  setQuantity: (quantity: number) => void;
  onValidate: () => Promise<void>;
  priceline: Omit<CommunicorAccreditationTemplate, 'pricelistId'> | undefined;
}

const EditCarPassLineModal = forwardRef((props: EditCarPassLineModalProps, ref) => {

  const [opened, setOpened] = useState<boolean>(false);

  const handleValidate = async () => {
    try {
      await props.onValidate();
      setOpened(false);
    } catch (err) {
      console.log(err);
    }
  };

  const open = () => {
    setOpened(true);
  };

  useImperativeHandle(ref, () => ({
    open
  }));

  const price = (props.priceline?.pricePerUnit || 0) * props.quantity;

  return (
    <Dialog
      open={opened}
      onClose={() => setOpened(false)}
    >
      <DialogTitle> Editing Accreditation </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="quantity"
              label="Quantity"
              type="number"
              defaultValue={props.quantity}
              onChange={(event) => props.setQuantity(parseInt(event.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Typography>
            {`Price: ${formatPrice(price, props.priceline?.currency.symbol || '£')}`}
          </Typography>
          <Button 
            onClick={() => handleValidate()} 
            color="primary" 
            autoFocus
            variant="outlined" 
            style={{ marginLeft: '30px' }}
          >
            Validate
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
});

export default EditCarPassLineModal;