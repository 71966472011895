import PageLayout from 'layout/PageLayout/PageLayout';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, InputAdornment, Grid, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useGetEventsListQuery, useLoggedInQuery } from 'apollo/queries';
import PMEventTableRow from './PMEventTableRow/PMEventTableRow';
import UnderlinedTitle from 'components/UnderlinedTitle/UnderlinedTitle';
import usePMEventListStyles from './PMEventListStyles';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { Maybe } from 'apollo/types';

interface Event {
  id: number;
  name: string;
  startDate?: Maybe<string> | undefined;
  endDate?: Maybe<string> | undefined;
}
const PMEventsList: React.FC = () => {

  const classes = usePMEventListStyles();
  const history = useHistory();

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchResult, setSearchResult] = useState<Event[] | null>(null);
  const { data: loggedInData, loading: loggedInLoading } = useLoggedInQuery();
  const { data, loading } = useGetEventsListQuery({
    skip: loggedInData === undefined || !loggedInData.isLoggedIn
  });

  // @ts-ignore
  useEffect(() => {
    let cancelled = false;
    if (!loading && data?.searchCommunicorEvent) {
      setTimeout(() => {
        if (!cancelled) {
          setSearchResult(data.searchCommunicorEvent.filter(
            (current) =>
              current
                .name
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(
                  searchValue
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                )
          ));
        }
      }, 500);
    }
    return () => cancelled = true;
  }, [data, loading, searchValue]);

  if (loading || loggedInLoading) {
    return (
      <PageLayout
        navigation
        title="Home"
      >
        <CircularProgress />
      </PageLayout>
    );
  } else {
    return (
      <PageLayout
        navigation
        title="Home"
      >
        <Grid container className={classes.container}>
          <Grid item xs={7}>
            <UnderlinedTitle>List Of Events</UnderlinedTitle>
          </Grid>
          <Grid item xs={5}>
            <Button style={{ float: 'right' }} variant="contained" size="large" onClick={() => history.push('/maReservations')}>My Bookings</Button>
            <TextField
              id="search"
              variant="outlined"
              margin="normal"
              label="Search an event"
              className={classes.input}
              inputProps={{ 'aria-label': 'search google maps' }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Nbr. of individual</TableCell>
                    <TableCell align="center">Nbr. of booking</TableCell>
                    <TableCell align="center">View More</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchResult && searchResult.map((event) => (
                    <PMEventTableRow key={event.id} event={event} />
                  ))}
                </TableBody>
              </Table>
              {
                searchResult && searchResult.length < 1 && <div style={{ fontSize: '18px', paddingTop: '25px' }}>
                  You have not yet been invited to any event
                </div>
              }
            </TableContainer>
          </Grid>
        </Grid>
      </PageLayout>
    );
  }
};

export default PMEventsList;