import React, { useState } from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { sendForgotPasswordRequest } from 'utils/sendForgotPasswordRequest';
import RegExLibrary from 'utils/RegExLibrary';
import PageLayout from 'layout/PageLayout/PageLayout';
import useForgotPasswordPageStyle from './ForgotPasswordPageStyle';

const ForgotPasswordPage: React.FC = () => {
  const classes = useForgotPasswordPageStyle();
  const history = useHistory();
  const { emailAddr } = useParams<{ emailAddr: string }>();

  const [ email, setEmail ] = useState<string>(decodeURIComponent(emailAddr ?? ''));
  const [ requestSentModalOpen, setRequestSentModalOpen ] = useState<boolean>(false);

  const onEmailChanged: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    setEmail(event.target.value);
  };

  const onForgotPasswordSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    sendForgotPasswordRequest(email)
      .then(
        (response: Response) => {
          setRequestSentModalOpen(true);
        }
      )
      .catch(
        (error: Error) => {
          console.error(error);
        }
      );
  };

  const onModalClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }

    setRequestSentModalOpen(false);
  };

  const onModalOkClicked: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setRequestSentModalOpen(false);

    history.push(`/login`);
  };

  const emailIsValid: boolean = RegExLibrary.email.test(email);

  /**
   * Si un compte client utilisant cette adresse e-mail existe,
   * la procédure de réinitialisation de mot de passe
   * a été envoyée à : kegheobeof@test.com
   */

  return (
    <PageLayout
      title="Forgot Password"
    >
      <div className={classes.paper}>
        <Typography variant="h1" className={classes.margin}>
          Reset your password
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            required
            autoFocus
            fullWidth
            variant="outlined"
            id="email"
            label="Email Address"
            name="emailAddr"
            value={email}
            onChange={onEmailChanged}
            error={!emailIsValid && email.length > 0}
            helperText={!emailIsValid && email.length > 0 && 'Email is invalid'}
            InputLabelProps={{ shrink: true }}
            className={classes.margin}
          />
          <Button
            variant="contained"
            size="large"
            onClick={onForgotPasswordSubmit}
            disabled={!emailIsValid || email.length < 1}
          >
            Reset password
          </Button>
        </form>
      </div>
      <Dialog
        open={requestSentModalOpen}
        onClose={onModalClose}
        scroll="body"
        maxWidth={false}
      >
        <DialogTitle>
          {`Reset password`}
        </DialogTitle>
        <DialogContent
          className={classes.popUpContentDiv}
          dividers
        >
          <DialogContentText>
            {`If an account using this email address exists,
            the procedure to reset the account's password will be sent
            to : ${email}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onModalOkClicked}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
};

/**
   * Si un compte client utilisant cette adresse e-mail existe,
   * la procédure de réinitialisation de mot de passe
   * a été envoyée à : kegheobeof@test.com
   */

export default ForgotPasswordPage;
