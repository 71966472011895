import config from 'config/config.json';

export const getStaticContents = async () => {
  const response = await fetch(`${config.ODOO_HOST}/getStaticTexts`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return response.json();
};