import React from 'react';
import { JSXChild, JSXChildren, NodeType } from './types';
import { buildLuqComponent } from './compile';


export type AndProps = {
  children: JSXChildren;
};

export const And: React.FC<AndProps> = buildLuqComponent(NodeType.And);


export type OrProps = {
  children: JSXChildren;
};

export const Or: React.FC<OrProps> = buildLuqComponent(NodeType.Or);


export type NotProps = {
  children: JSXChild;
};

export const Not: React.FC<NotProps> = buildLuqComponent(NodeType.Not);


export type DefinedProps = {
  children?: undefined;
  field: string;
};

export const Defined: React.FC<DefinedProps> = buildLuqComponent(NodeType.Defined);


export type UndefinedProps = {
  children?: undefined;
  field: string;
};

export const Undefined: React.FC<UndefinedProps> = buildLuqComponent(NodeType.Undefined);


export type EqProps = {
  children?: undefined;
  field: string;
  value: unknown;
};

export const Eq: React.FC<EqProps> = buildLuqComponent(NodeType.Eq);


export type NeqProps = {
  children?: undefined;
  field: string;
  value: unknown;
};

export const Neq: React.FC<NeqProps> = buildLuqComponent(NodeType.Neq);


export type GtProps = {
  children?: undefined;
  field: string;
  value: unknown;
};

export const Gt: React.FC<GtProps> = buildLuqComponent(NodeType.Gt);


export type GteProps = {
  children?: undefined;
  field: string;
  value: unknown;
};

export const Gte: React.FC<GteProps> = buildLuqComponent(NodeType.Gte);


export type LtProps = {
  children?: undefined;
  field: string;
  value: unknown;
};

export const Lt: React.FC<LtProps> = buildLuqComponent(NodeType.Lt);


export type LteProps = {
  children?: undefined;
  field: string;
  value: unknown;
};

export const Lte: React.FC<LteProps> = buildLuqComponent(NodeType.Lte);


export type LikeProps = {
  children?: undefined;
  field: string;
  pattern: unknown;
  caseInsensitive?: boolean;
};

export const Like: React.FC<LikeProps> = buildLuqComponent(NodeType.Like);


export type InProps = {
  children?: undefined;
  field: string;
  values: unknown[];
};

export const In: React.FC<InProps> = buildLuqComponent(NodeType.In);


export type NotInProps = {
  children?: undefined;
  field: string;
  values: unknown[];
};

export const NotIn: React.FC<NotInProps> = buildLuqComponent(NodeType.NotIn);


export type OrderProps = {
  children: JSXChildren;
};

export const Order: React.FC<AndProps> = buildLuqComponent(NodeType.Order);


export type AscProps = {
  children?: undefined;
  field: string;
};

export const Asc: React.FC<AscProps> = buildLuqComponent(NodeType.Asc);


export type DescProps = {
  children?: undefined;
  field: string;
};

export const Desc: React.FC<DescProps> = buildLuqComponent(NodeType.Desc);