/* Framework imports ----------------------------------- */
import React, {
  useState
} from 'react';

/* Module imports -------------------------------------- */
import { useCreatePassengerMutation } from 'apollo/mutations';

/* Component imports ----------------------------------- */
import {
  Button,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import PageLayout from 'layout/PageLayout/PageLayout';
import AddPassengerForm from 'components/AddPassengerForm/AddPassengerForm';

/* Type imports ---------------------------------------- */
import type { Passenger } from 'type/Passenger';
import { useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

/* AddPassenger component prop types -------------------- */
interface AddPassengerProps { }

/* AddPassenger component ------------------------------- */
const AddPassenger: React.FC<AddPassengerProps> = () => {

  const history = useHistory();

  const [formValues, setFormValues] = useState<Passenger>({
    id: '',
    groupName: '',
    name: '',
    firstName: '',
    mail: '',
    phoneNumber: '',
    groupSize: 2,
    isGroup: false,
  });
  const [error, setError] = useState<unknown>();

  const [createPassengerMutation, { loading }] = useCreatePassengerMutation();

  const createPassenger = async (): Promise<void> => {
    try {
      const variables = {
        firstname: formValues.firstName,
        name: formValues.name,
        email: formValues.mail,
        phone: formValues.phoneNumber,
      };
      await createPassengerMutation({
        variables: formValues.isGroup
          ? {
            ...variables,
            isGroup: true,
            groupName: formValues.groupName,
            groupSize: formValues.groupSize,
          } :
          {
            ...variables,
            isGroup: false,
          }
      });
      history.push('/passengers');
    } catch (error) {
      setError(error);
      console.error(`[AddPassenger.tsx] createPassenger error : ${JSON.stringify(error)}`);
    }
  };

  return (
    <PageLayout
      backButton
      navigation
      title="Add a passenger"
    >
      <Grid container justifyContent="center">
        <AddPassengerForm
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </Grid>
      <Grid style={{ display: 'flex', justifyContent: 'start', marginTop: '15px' }}>
        <Button
          onClick={createPassenger}
          color="primary"
          autoFocus
          variant="contained"
          size="large"
          disabled={loading ||
            formValues.firstName.length === 0 ||
            formValues.name.length === 0 ||
            formValues.mail.length === 0 ||
            formValues.phoneNumber.length === 0 ||
            (formValues.isGroup && 
              (
                formValues.groupName === undefined || formValues.groupName.length === 0 ||
                formValues.groupSize === undefined || isNaN(formValues.groupSize) || formValues.groupSize < 2
              )
            )
          }
        >
          {
            loading ?
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <CircularProgress />
              </div>
              :
              'Create'
          }
        </Button>
      </Grid>
      {
        error !== undefined &&
        <Alert
          severity="error"
          onClose={() => { setError(undefined); }}
        >
          An error has occured, please retry
        </Alert>
      }
    </PageLayout>
  );
};

/* Export AddPassenger component ------------------------ */
export default AddPassenger;
