import config from 'config/config.json';

export const sendForgotPasswordRequest = async (emailAddr: string) => {
  const response: Response = await fetch(
    `${config.ODOO_HOST}/web/session/forgotPassword`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        {
          email: emailAddr,
        },
      ),
    }
  );

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return response;
};
