import compile from 'utils/domain';
import * as Luq from 'luq';
import { useCurrentUserQuery, useSearchPricelistQuery } from 'apollo/queries';

const usePricelist = (eventId: number) => {
  const { data: userQueryResults } = useCurrentUserQuery();

  const { data, loading, error } = useSearchPricelistQuery({
    variables: {
      filter: compile(
        <Luq.And>
          <Luq.Eq field="event_id" value={eventId} />
          <Luq.Or>
            <Luq.Eq field="partner_id" value={userQueryResults?.getMyData?.parentId?.id} />
            <Luq.Eq field="default" value={true} />
          </Luq.Or>
          {/* <Luq.Gte field="deadline" value={today} /> */}
        </Luq.And>
      )
    },
    skip: !userQueryResults,
  });

  const pricelists = data?.searchCommunicorPricelist;

  if (pricelists === undefined || pricelists.length === 0) {
    return {
      data: null,
      loading,
      error
    };
  }
  
  const companyDivisionPriceListIndex = pricelists.findIndex((pricelist) => pricelist.divisionId?.id === userQueryResults?.getMyData?.divisionId?.id);

  if (companyDivisionPriceListIndex !== -1) {
    return {
      data: pricelists[companyDivisionPriceListIndex],
      loading,
      error
    };
  }

  const companyPriceListIndex = pricelists.findIndex((pricelist) => pricelist.divisionId === null && !pricelist.default);

  if (companyPriceListIndex !== -1) {
    return {
      data: pricelists[companyPriceListIndex],
      loading,
      error
    };
  }

  const defaultPriceListIndex = pricelists.findIndex((pricelist) => pricelist.default);

  if (defaultPriceListIndex !== -1) {
    return {
      data: pricelists[defaultPriceListIndex],
      loading,
      error
    };
  }

  return {
    data: null,
    loading,
    error
  };
};

export default usePricelist;