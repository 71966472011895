/* Framework imports ----------------------------------- */
import React from 'react';

/* Module imports -------------------------------------- */
import { useHistory } from 'react-router-dom';
import useMyPassengersStyles from './MyPassengersStyles';

/* Component imports ----------------------------------- */
import {
  Button,
  Grid,
  Typography
} from '@material-ui/core';
import PageLayout from 'layout/PageLayout/PageLayout';
import PassengerDetails from './PassengerDetails/PassengerDetails';
import { useListMyPassengersQuery } from 'apollo/queries';

/* MyPassengers component prop types ------------------- */
interface MyPassengersProps { }

/* MyPassengers component ------------------------------- */
const MyPassengers: React.FC<MyPassengersProps> = () => {

  const history = useHistory();
  const classes = useMyPassengersStyles();

  const { data: passengersData, refetch } = useListMyPassengersQuery({
    fetchPolicy: 'cache-and-network',
  });

  return (
    <PageLayout
      backButton
      navigation
      title="My Passengers"
    >
      <Grid container spacing={2}>
        <Grid item xs={9} className={classes.title}>
          <Typography variant="h2">
            List of passengers
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" size="large" fullWidth onClick={() => history.push(`/passengers/add`)}>
            Add passenger
          </Button>
        </Grid>
        <Grid container spacing={4} className={classes.noPadding}>
          {
            passengersData?.searchResUsers?.map(
              (passenger) => (
                <PassengerDetails key={passenger.id} passenger={passenger} refetch={refetch} />
              )
            )
          }
        </Grid>
      </Grid>
    </PageLayout>
  );
};

/* Export MyPassengers component ------------------------ */
export default MyPassengers;
