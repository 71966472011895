import { Typography } from '@material-ui/core';
import React from 'react';
import useUnderlinedTitleStyles from './UnderlinedTitleStyles';

interface UnderlinedTitleProps {
  children: React.ReactNode;
}

const UnderlinedTitle: React.FC<UnderlinedTitleProps> = ({ children }) => {

  const classes = useUnderlinedTitleStyles();

  return (
    <div className={classes.underlined}>
      <Typography variant="h1">
        { children }
      </Typography>
    </div>
  );
};

export default UnderlinedTitle;