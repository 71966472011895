import React from 'react';
import { Redirect } from 'react-router-dom';
import AddAnItem from 'pages/AddAnItem/AddAnItem';
import AirportOrCityTransfert from 'pages/AirportOrCityTransfer/AirportOrCityTransfer';
import BookingConfirmed from 'pages/BookingConfirmed/BookingConfirmed';
import CarPass from 'pages/CarPass/CarPass';
import DailyDisposal from 'pages/DailyDisposal/DailyDisposal';
import IBAddBooking from 'pages/IBAddBooking/IBAddBooking';
import IBEventReservation from 'pages/IBEventReservation/IBEventReservation';
import IBEventSummary from 'pages/IBEventSummary/IBEventSummary';
import IndividualHome from 'pages/IndividualHome/IndividualHome';
import InvitationView from 'pages/InvitationView/InvitationView';
import MAAddBooking from 'pages/MAAddBooking/MAAddBooking';
import MAInvitationView from 'pages/MAInvitationView/MAInvitationView';
import MASignUp from 'pages/MASignUp/MASignUp';
import PMAddIndividual from 'pages/PMAddIndividual/PMAddIndividual';
import PMEventDetails from 'pages/PMEventDetails/PMEventDetails';
import PMEventsList from 'pages/PMEventsList/PMEventsList';
import PMIBReservations from 'pages/PMIBReservations/PMIBReservations';
import Profile from 'pages/Profile/Profile';
import SignUp from 'pages/SignUp/Signup';
import TechnicalSupports from 'pages/TechnicalSupports/TechnicalSupports';
import ForgotPasswordPage from 'pages/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from 'pages/ResetPasswordPage/ResetPasswordPage';
import QuoteConfirmed from 'pages/QuoteConfirmed/QuoteConfirmed';
import PrivacyPolicy from 'pages/StaticContents/PrivacyPolicy';
import Legal from 'pages/StaticContents/Legal';
import TermsOfUse from 'pages/StaticContents/TermsOfUse';
import InvoiceConfirmed from 'pages/InvoiceConfirmed/InvoiceConfirmed';
import MyPassengers from 'pages/MyPassengers/MyPassengers';
import AddPassenger from 'pages/AddPassenger/AddPassenger';
import ConfirmRide from 'pages/ConfirmRide/ConfirmRide';

type Routes = Array<{
  path: string;
  component?: React.FC;
  render?: () => React.ReactElement;
}>

export const individualRoutes : Routes = [
  {
    path: '/',
    component : IndividualHome
  },
  {
    path: '/eventBasket/:basketId',
    component : IBEventReservation
  },
  {
    path: '/eventSummary/:basketId',
    component : IBEventSummary
  },
  {
    path: '/addItem/:eventId/:basketId',
    component : AddAnItem
  },
  {
    path: '/dailyDisposal/:eventId/:basketId',
    component : DailyDisposal
  },
  {
    path: '/airportCityTransfert/:eventId/:basketId',
    component : AirportOrCityTransfert
  },
  {
    path: '/carPass/:eventId/:basketId',
    component : CarPass
  },
  {
    path: '/technicalSupports/:eventId/:basketId',
    component : TechnicalSupports
  },
  {
    path: '/addBooking',
    component: IBAddBooking
  },
  {
    path: '/bookingConfirmed/:eventId',
    component: BookingConfirmed
  },
  {
    path: '/invoiceConfirmed/:eventId',
    component: InvoiceConfirmed
  },
  {
    path: '/quoteConfirmed/:eventId',
    component: QuoteConfirmed
  },
  {
    path: '/signup/:userId/:companyId',
    component: SignUp
  },
  {
    path: '/profile',
    component: Profile
  },
  {
    path: '/privacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/legal',
    component: Legal,
  },
  {
    path: '/termsOfUse',
    component: TermsOfUse,
  },
  {
    path: '/passengers',
    component: MyPassengers,
  },
  {
    path: '/passengers/add',
    component: AddPassenger,
  },
  {
    path: '/confirmRide/:rideId/:token',
    component: ConfirmRide,
  },
];

export const publicRoutes : Routes = [
  {
    path: '/',
    render: () => <Redirect to="/login" />,
  },
  {
    path: '/login/forgotPassword/:emailAddr?',
    component: ForgotPasswordPage,
  },
  {
    path: '/forgotPassword',
    component: ResetPasswordPage,
  },
  {
    path: '/signup/:userId/:companyId',
    component: SignUp
  },
  {
    path: '/masignup/:userId',
    component: MASignUp
  },
  {
    path: '/ibInvitation',
    component : InvitationView
  },
  {
    path: '/maInvitation',
    component : MAInvitationView
  },
  {
    path: '/login/forgotPassword/:emailAddr',
    component: ForgotPasswordPage,
  },
  {
    path: '/privacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/legal',
    component: Legal,
  },
  {
    path: '/termsOfUse',
    component: TermsOfUse,
  },
  {
    path: '/confirmRide/:rideId/:token',
    component: ConfirmRide,
  },
];

export const masterRoute : Routes = [
  {
    path: '/',
    component: PMEventsList
  },
  {
    path: '/eventDetails/:eventId',
    component: PMEventDetails
  },
  {
    path: '/IBReservations/:individualId/:eventId',
    component: PMIBReservations
  },
  {
    path: '/addIndividual/:eventId',
    component: PMAddIndividual
  },
  {
    path: '/signup',
    component: SignUp
  },
  {
    path: '/ibInvitation',
    component : InvitationView
  },
  {
    path: '/maReservations',
    component: IndividualHome,
  },
  {
    path: '/eventBasket/:basketId',
    component : IBEventReservation
  },
  {
    path: '/eventSummary/:basketId',
    component : IBEventSummary
  },
  {
    path: '/addItem/:eventId/:basketId',
    component : AddAnItem
  },
  {
    path: '/dailyDisposal/:eventId/:basketId',
    component : DailyDisposal
  },
  {
    path: '/airportCityTransfert/:eventId/:basketId',
    component : AirportOrCityTransfert
  },
  {
    path: '/carPass/:eventId/:basketId',
    component : CarPass
  },
  {
    path: '/technicalSupports/:eventId/:basketId',
    component : TechnicalSupports
  },
  {
    path: '/addBooking',
    component: MAAddBooking,
  },
  {
    path: '/bookingConfirmed/:eventId',
    component: BookingConfirmed
  },
  {
    path: '/invoiceConfirmed/:eventId',
    component: InvoiceConfirmed
  },
  {
    path: '/quoteConfirmed/:eventId',
    component: QuoteConfirmed
  },
  {
    path: '/profile',
    component: Profile
  },
  {
    path: '/privacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/legal',
    component: Legal,
  },
  {
    path: '/termsOfUse',
    component: TermsOfUse,
  },
  {
    path: '/passengers',
    component: MyPassengers,
  },
  {
    path: '/passengers/add',
    component: AddPassenger,
  },
  {
    path: '/confirmRide/:rideId/:token',
    component: ConfirmRide,
  },
];