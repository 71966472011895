import { makeStyles } from '@material-ui/core';

const useTechnicalSupportsStyles = makeStyles({
  space: {
    marginTop: '30px',
  },
  mg: {
    marginTop: '10px',
  },
  summary: {
    maxHeight: 'calc(100vh - 174px)',
    border: '1px solid #41474D',
    borderRadius: '10px',
    padding: '10px',
    overflowY: 'auto',
    marginBottom: '10px'
  },
  stickyColumn: {
    position: 'sticky'
  },
  validateCart: {
    textAlign: 'end'
  },
  placeholder: {
    fontSize: '14px',
    color: '#5D6369',
    fontStyle: 'italic',
    fontWeight: 400,
    fontFamily: 'Karla'
  },
  dialogTitle: {
    fontSize: '36px',
    fontFamily: 'Karla',
    fontWeight: 700
  },
  type: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  },
  typeName: {
    marginRight: '10px',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: '16px',
    color: '#CED4DA',
    overflowWrap: 'break-word'
  },
  typeQuantity: {
    margin: '0',
    height: '40px'
  },
  verticalAlign: {
    alignSelf: 'center'
  },
});

export default useTechnicalSupportsStyles;