import { makeStyles } from '@material-ui/core';

const useCartItemStyles = makeStyles({
  title: {
    textAlign: 'left',
    fontSize: '22px',
    fontFamily: 'Karla',
    fontWeight: 700,
    alignSelf: 'center'
  },
  container: {
    padding: '0px'
  },
  buttons: {
    textAlign: 'right'
  },
  price: {
    textAlign: 'right',
    fontWeight: 500,
    fontSize: '20px',
    fontFamily: 'Karla',
    alignSelf: 'end'
  }
});

export default useCartItemStyles;