import { Button, Grid, Typography } from '@material-ui/core';
import CreatePassword from 'pages/SignUp/CreatePassword/CreatePassword';
import PageLayout from 'layout/PageLayout/PageLayout';
import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import useMASignupStyles from './MASignupStyles';
import GridContainerWithPadding from 'components/GridContainerWithPadding/GridContainerWithPadding';
import { useHistory, useParams } from 'react-router';
import { useUpdatePasswordMutation } from 'apollo/mutations';

const MASignUp: React.FC = () => {
  const history = useHistory(); 

  const { userId } = useParams<{ userId: string }>();

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const classes = useMASignupStyles();

  const [ updatePassword ] = useUpdatePasswordMutation();

  const createAccount = async () => {
    if (password !== '' && confirmPassword === password) {
      try {
        await updatePassword({
          variables: {
            id: parseInt(userId),
            password: password,
          }
        });

        history.push('/login');
      } catch (err) {
        console.log(err);
      }
    }
  };

  const isDisabled = useMemo(() => {
    return password === '' || password !== confirmPassword;
  }, [password, confirmPassword]);

  return (
    <PageLayout>
      <GridContainerWithPadding container spacing={3} className={classes.container}>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} className={classes.step}>
          <Typography variant="h1">
            Password
          </Typography>
        </Grid>
        <Grid item xs={6} alignItems="center" className={clsx(classes.fullHeight, classes.alignCenter)}>
          <CreatePassword
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
          />
        </Grid>
      </GridContainerWithPadding>
      <GridContainerWithPadding container className={classes.bottomButton} alignItems="center" alignContent="center">
        <Grid item xs={12} alignContent="center">
          <Button disabled={isDisabled} onClick={createAccount} variant="contained" size="large">Validate</Button>
        </Grid>
      </GridContainerWithPadding>
    </PageLayout>
  );
};


export default MASignUp;