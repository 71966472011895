import { Button, withStyles } from '@material-ui/core';

const GreyButton = withStyles({
  root: {
    backgroundColor: '#495057',
    color: '#CED4DA',
    '&:hover': {
      backgroundColor: '#3E444A',
    }
  }
})(Button);

export default GreyButton;