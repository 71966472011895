import { makeStyles } from '@material-ui/core';
import { formatPrice } from 'utils/formatPrice';

const useStyles = makeStyles({
  priceLabel: {
    fontSize: '24px',
    fontWeight: 300,
    fontFamily: 'Karla',
    color: '#CED4DA',
  },
  price: {
    fontSize: '24px',
    fontWeight: 700,
    fontFamily: 'Karla',
    color: '#CED4DA'
  }
});


interface PriceDisplayProps {
  pricelist: any | undefined | null;
  price: number;
}

const PriceDisplay: React.FC<PriceDisplayProps> = ({ pricelist, price }) => {

  const classes = useStyles();

  return (
    <span className={classes.priceLabel}>Price : <span className={classes.price}>{formatPrice(price, pricelist?.currency?.symbol || 'NC')}</span></span>
  );
};

export default PriceDisplay;