import { makeStyles } from '@material-ui/core/styles';

const useAddBookingStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 156px)',
  },
  form: {
    width: '494px', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  margin: {
    marginBottom: '55px'
  },
}));

export default useAddBookingStyles;