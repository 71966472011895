import { withStyles } from '@material-ui/core';
import { DatePicker as DP } from '@material-ui/pickers';

const DatePicker = withStyles({
  root: {
    '& .MuiInputBase-root': {
      paddingLeft: '10px',
      height: '100%',
      fontSize: '14px',
      '& button': {
        padding: '0',
        color: '#E8FFFF'
      }
    }
  },
})(DP);

export default DatePicker;