import { Button, CircularProgress, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useCreateDailyLineMutation } from 'apollo/mutations';
import { useFindDailyAlreadyTakenPassengersQuery, useGetDailyCartQuery, useListMyPassengersQuery } from 'apollo/queries';
import { PassengerFragment } from 'apollo/queries/types';
import { CommunicorVehicleType } from 'apollo/types';
import AddPassengerModal from 'components/AddPassengerModal/AddPassengerModal';
import DatePicker from 'components/DatePicker/DatePicker';
import GridContainerWithPadding from 'components/GridContainerWithPadding/GridContainerWithPadding';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';
import RequestHandling from 'components/RequestHandling';
import VehicleSelector from 'components/VehicleSelector/VehicleSelector';
import PageLayout from 'layout/PageLayout/PageLayout';
import { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { daysBetween } from 'utils/datesManipulation';
import compile from 'utils/domain';
import * as Luq from 'luq';

const useStyles = makeStyles({
  container: {
    placeContent: 'center',
    textAlign: 'center',
    paddingBottom: '20px'
  },
  margin: {
    marginBottom: '40px'
  },
  alignRight: {
    textAlign: 'right'
  },
  alignPrice: {
    textAlign: 'left',
    alignSelf: 'center'
  },
  priceLabel: {
    fontSize: '24px',
    fontWeight: 300,
    fontFamily: 'Karla',
    color: '#CED4DA',
  },
  price: {
    fontSize: '24px',
    fontWeight: 700,
    fontFamily: 'Karla',
    color: '#CED4DA'
  },
  root: {
    color: 'black'
  }
});

const DailyDisposal: React.FC = () => {

  const history = useHistory();

  const classes = useStyles();

  //TODO : requête sur eventId pour avoir l'event
  const { basketId } = useParams<{ eventId: string, basketId: string }>();
  const [selectedVehicle, setSelectedVehicle] = useState<number | null>(null);
  const [startingDate, setStartingDate] = useState<string>('01/01/1970');
  const [endingDate, setEndingDate] = useState<string>('01/01/1970');
  const [passenger, setPassenger] = useState<PassengerFragment | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const fullBasketQueryResult = useGetDailyCartQuery({
    variables: {
      id: parseInt(basketId),
    },
    fetchPolicy: 'cache-and-network',
    skip: basketId === undefined,
  });

  const { data: passengersData, loading: passengersLoading, refetch } = useListMyPassengersQuery();

  const { data: dailyAlreadyTakenPassengersData, loading: dailyAlreadyTakenPassengersLoading } = useFindDailyAlreadyTakenPassengersQuery({
    variables: {
      filter: compile(
        <Luq.Eq field="daily_ids.basket_id" value={parseInt(basketId)}></Luq.Eq>
      )
    },
    fetchPolicy: 'network-only',
    skip: basketId === undefined
  });

  const dailyAlreadyTakenPassengersIds = useMemo(() => {
    if (dailyAlreadyTakenPassengersData !== undefined && !dailyAlreadyTakenPassengersLoading) {
      return dailyAlreadyTakenPassengersData.searchResUsers.map((passenger) => passenger.id);
    } else {
      return [];
    }
  }, [dailyAlreadyTakenPassengersData, dailyAlreadyTakenPassengersLoading]);

  useEffect(() => {
    if (startingDate > endingDate) setEndingDate(startingDate);
  }, [startingDate, endingDate]);

  useEffect(() => {
    if (fullBasketQueryResult.data?.getCommunicorBasket?.eventId?.startDate) {
      setStartingDate(fullBasketQueryResult.data.getCommunicorBasket.eventId.startDate);
    }
    if (fullBasketQueryResult.data?.getCommunicorBasket?.eventId?.endDate) {
      setEndingDate(fullBasketQueryResult.data.getCommunicorBasket.eventId.endDate);
    }
  }, [fullBasketQueryResult]);

  const [createBasketDailyLineMutation] = useCreateDailyLineMutation();

  const addLineToBasket = async () => {

    const priceLine = fullBasketQueryResult.data?.getCommunicorBasket?.pricelistId?.dailyLineIds.find(line => line.vehicleTypeId.id === selectedVehicle);

    try {
      if (priceLine?.id && passenger !== null && passenger.id !== null) {
        await createBasketDailyLineMutation({
          variables: {
            basketId: parseInt(basketId),
            startDate: startingDate.slice(0, 10),
            endDate: endingDate.slice(0, 10),
            passengerId: passenger.id,
            quantity: daysBetween(new Date(startingDate), new Date(endingDate)) + 1,
            templateId: priceLine?.id,
          }
        });
      }

      history.push(`/eventBasket/${basketId}`);
    } catch (err) {
      console.log(err);
    }
  };

  const computePrice = () => {
    if (fullBasketQueryResult.error || fullBasketQueryResult.loading) {
      return 0;
    }

    const priceLine = fullBasketQueryResult.data?.getCommunicorBasket?.pricelistId?.dailyLineIds.find(line => line.vehicleTypeId.id === selectedVehicle);

    return (priceLine?.pricePerDay || 0) * (daysBetween(new Date(startingDate), new Date(endingDate)) + 1);
  };

  const vehicles = fullBasketQueryResult.data?.getCommunicorBasket?.pricelistId?.dailyLineIds.reduce((acc: CommunicorVehicleType[], value) => {
    acc.push(value.vehicleTypeId);
    return acc;
  }, [] as CommunicorVehicleType[]);

  const selectedVehicleDetails = useMemo(() => {
    if (vehicles !== null && vehicles !== undefined && selectedVehicle !== null) {
      const vehicle = vehicles.find((vehicle) => vehicle.id === selectedVehicle);
      return vehicle || null;
    } else {
      return null;
    }
  }, [vehicles, selectedVehicle]);

  return (
    <PageLayout
      title="Daily Disposal"
      navigation
      backButton
    >
      <GridContainerWithPadding container className={classes.container}>
        <Grid item xs={10} lg={8}>
          <GridContainerWithPadding container spacing={2}>
            <RequestHandling
              {...fullBasketQueryResult}
            >
              {basket => {
                return (
                  <>
                    <Grid item xs={12} className={classes.margin}>
                      <Typography variant="h1">Daily disposal</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="none"
                        id="starting-date"
                        label="Starting Date"
                        value={startingDate}
                        onChange={(date) => setStartingDate(date?.toISOString() || '')}
                        inputVariant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="none"
                        id="finishing-date"
                        label="Finishing Date"
                        value={endingDate}
                        onChange={(date) => setEndingDate(date?.toISOString() || '')}
                        inputVariant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.margin}>
                      <VehicleSelector
                        options={vehicles || []}
                        selectedVehicle={selectedVehicle}
                        setSelectedVehicle={setSelectedVehicle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={[{ id: null }, ...passengersData?.searchResUsers || []]}
                        value={passenger}
                        getOptionLabel={
                          (passenger) => {
                            if (passenger.id === null) {
                              return 'Create a new passenger';
                            } else {
                              return passenger.isPassengersGroup ? `${passenger.groupName}` : `${passenger.name} ${passenger.firstname}`;
                            }
                          }
                        }
                        renderInput={(params) => passengersLoading ? <CircularProgress /> : <TextField {...params} label={passenger?.isPassengersGroup ? 'Passenger group name *' : 'Passenger *'} />}
                        classes={{
                          paper: classes.root,
                        }}
                        onChange={
                          (event, newValue) => {
                            if (newValue === null) {
                              setPassenger(newValue);
                            } else if (newValue.id === null) {
                              setOpenModal(true);
                            } else {
                              setPassenger(newValue);
                            }
                          }
                        }
                      />
                      {
                        selectedVehicleDetails !== null &&
                        passenger !== null &&
                        passenger.isPassengersGroup &&
                        passenger.groupSize &&
                        passenger.groupSize > selectedVehicleDetails.numberSeats! &&
                        <div>
                          Selected vehicle has less seats than the group size
                        </div>
                      }
                    </Grid>
                    {selectedVehicle !== null && passenger !== null && (
                      <>
                        <Grid item xs={6} className={classes.alignRight}>
                          <Button
                            onClick={addLineToBasket}
                            disabled={dailyAlreadyTakenPassengersIds.includes(passenger.id)}
                            variant="contained"
                            size="large">
                            Validate
                          </Button>
                        </Grid>
                        <Grid item xs={6} className={classes.alignPrice}>
                          <PriceDisplay
                            pricelist={basket.getCommunicorBasket?.pricelistId}
                            price={computePrice()}
                          />
                        </Grid>
                        {
                          dailyAlreadyTakenPassengersIds.includes(passenger.id) &&
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              width: '100%',
                            }}
                          >
                            This passenger already has a Daily Disposal
                          </div>
                        }
                      </>
                    )}
                  </>
                );
              }}
            </RequestHandling>
          </GridContainerWithPadding>
        </Grid>
      </GridContainerWithPadding>
      <AddPassengerModal
        opened={openModal}
        setOpened={setOpenModal}
        setPassenger={setPassenger}
        refetch={refetch}
      />
    </PageLayout>
  );
};

export default DailyDisposal;