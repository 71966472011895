import { makeStyles } from '@material-ui/core';

const useBookingCardStyles = makeStyles({
  title: {
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: 'Karla'
  },
  confirmation: {
    color: 'red'
  },
  notification: {
    color: 'red'
  },
  section: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Montserrat',
    color: '#CED4DA',
  },
  value: {
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Montserrat',
    color: '#CED4DA'
  },
  card: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

export default useBookingCardStyles;