import { Grid } from '@material-ui/core';
import { useDeleteDailyLineMutation, useUpdateDailyLineMutation } from 'apollo/mutations';
import { CommunicorDailyFieldsFragment, VehicleFieldsFragment } from 'apollo/queries/types';
import EditDailyLineModal from './EditDailyLineModal/EditDailyLineModal';
import React, { useMemo, useRef, useState } from 'react';
import { DailyLine, DailyLineIDS } from 'type/DailyLine';
import { formatPeriod } from 'utils/formatDate';
import { formatPrice } from 'utils/formatPrice';
import CartItem from './CartItem';
import useCartItemCommonStyles from './CartItemCommonStyles';
import { daysBetween } from 'utils/datesManipulation';
import { useListMyPassengersQuery } from 'apollo/queries';
import compile from 'utils/domain';
import * as Luq from 'luq';
import { useFindDailyAlreadyTakenPassengersQuery } from 'apollo/queries';
import { OneOf } from 'type/OneOf';

interface Props {
  basketId: string;
  line: CommunicorDailyFieldsFragment;
  currency: string | undefined;
  canDelete?: boolean;
  canEdit?: boolean;
  pricelist: DailyLineIDS | undefined;
}

const Daily: React.FC<Props> = ({
  basketId,
  line,
  currency,
  canDelete = true,
  canEdit = true,
  pricelist
}) => {
  const classes = useCartItemCommonStyles();

  const { data: passengersData, loading: passengersLoading, refetch } = useListMyPassengersQuery();
  const { data: dailyAlreadyTakenPassengersData } = useFindDailyAlreadyTakenPassengersQuery({
    variables: {
      filter: compile(
        <Luq.Eq field="daily_ids.basket_id" value={parseInt(basketId)}></Luq.Eq>
      )
    },
    fetchPolicy: 'network-only',
    skip: basketId === undefined
  });

  const dailyAlreadyTakenPassengersIds = useMemo(() => {
    if (dailyAlreadyTakenPassengersData !== undefined && !passengersLoading) {
      return dailyAlreadyTakenPassengersData.searchResUsers.map((passenger) => passenger.id);
    } else {
      return [];
    }
  }, [dailyAlreadyTakenPassengersData, passengersLoading]);

  const editdailyLineModalRef = useRef();

  const [lineEdit, setLineEdit] = useState<DailyLine>({
    vehicle: line.vehicleTypeId.id,
    startDate: line.startDate,
    endDate: line.endDate,
    passenger: line.passengerId || null,
  });

  const onEdit = () => {
    if (editdailyLineModalRef && editdailyLineModalRef.current) {
      // @ts-ignore
      editdailyLineModalRef.current.open();
    }
  };

  const [deleteDailyLineMutation] = useDeleteDailyLineMutation();

  const onDelete = async () => {
    try {
      await deleteDailyLineMutation({
        variables: {
          id: line.id
        },
        update(cache) {
          const id = cache.identify({
            __typename: 'CommunicorDaily',
            id: line.id
          });
          cache.evict({ id: id });
          cache.gc();
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const [updateDailyLineMutation] = useUpdateDailyLineMutation();

  const currentPriceline = pricelist?.find((line) => line.vehicleTypeId.id === lineEdit.vehicle);

  const handleEdit = async () => {
    if (currentPriceline && lineEdit !== undefined && lineEdit.passenger !== null && lineEdit.passenger !== undefined && lineEdit.passenger.id !== null) {
      try {
        await updateDailyLineMutation({
          variables: {
            ...lineEdit,
            id: line.id,
            quantity: daysBetween(new Date(lineEdit.startDate), new Date(lineEdit.endDate)) + 1,
            template: currentPriceline.id,
            passengerId: lineEdit.passenger.id
          },
        });
      } catch (err) {
        console.log(err);
      }
    }
  };


  const vehicles = pricelist?.reduce((acc: VehicleFieldsFragment[], value: OneOf<DailyLineIDS>) => {
    acc.push(value.vehicleTypeId);
    return acc;
  }, []);

  return (
    <CartItem
      title={`Daily disposal`}
      price={(line.totalPrice !== null && line.totalPrice !== undefined) ? formatPrice(line.totalPrice, currency || '£') : ''}
      canDelete={canDelete}
      canEdit={canEdit}
      onDelete={onDelete}
      onEdit={onEdit}
    >
      <Grid item xs={12} className={classes.title}>
        Vehicle Type :
      </Grid>
      <Grid item xs={12} className={classes.content}>
        {line.vehicleTypeId.name}
      </Grid>
      <Grid item xs={12} className={classes.title}>
        Dates :
      </Grid>
      <Grid item xs={12} className={classes.content}>
        {formatPeriod(line.startDate, line.endDate)}
      </Grid>
      {
        line.passengerId !== null && line.passengerId !== undefined && (
          <>
            <Grid item xs={12} className={classes.title}>
              {line.passengerId.isPassengersGroup ? 'Passenger group name :' : 'Passenger :'}
            </Grid>
            <Grid item xs={12} className={classes.content}>
              { line.passengerId.isPassengersGroup ? line.passengerId.groupName : `${line.passengerId.name} ${line.passengerId.firstname}`}
            </Grid>
          </>
        )
      }
      <EditDailyLineModal
        dailyLine={lineEdit}
        setDailyLine={setLineEdit}
        onValidate={handleEdit}
        ref={editdailyLineModalRef}
        vehicles={vehicles}
        priceline={currentPriceline}
        passengersList={passengersData}
        passengersLoading={passengersLoading}
        refetch={refetch}
        dailyAlreadyTakenPassengersIds={dailyAlreadyTakenPassengersIds}
      />
    </CartItem>
  );
};

export default Daily;