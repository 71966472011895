import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  useGetFullBasketQuery,
} from 'apollo/queries';
import {
  CommunicorAccreditationFieldsFragment,
  CommunicorAirportFieldsFragment,
  CommunicorDailyFieldsFragment,
  CommunicorTechnicalFieldsFragment,
  AccountTaxFieldsFragment,
} from 'apollo/queries/types';
import RequestHandling from 'components/RequestHandling';
import PageLayout from 'layout/PageLayout/PageLayout';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import GridContainerWithPadding from 'components/GridContainerWithPadding/GridContainerWithPadding';
import TableCellWithoutBorder from 'components/TableCellWithoutBorder/TableCellWithoutBordel';
import { formatPrice } from 'utils/formatPrice';
import useIBEventSummaryStyles from './IBEventSummaryStyles';
import { formatLitteralDate, formatLitteralTime, formatPeriod } from 'utils/formatDate';
import GreyButton from 'components/GreyButton/GreyButton';
import { useValidateEventCartMutation } from 'apollo/mutations';
import React, { useState } from 'react';
import QuoteModal from 'components/QuoteModal/QuoteModal';
import config from 'config/config.json';
import InvoiceModal from 'components/InvoiceModal/InvoiceModal';
import { downloadFile } from 'utils/downloadFile';

const rateFromTax = (tax: AccountTaxFieldsFragment | undefined | null): number => {
  if (tax === null || tax === undefined) {
    return 0;
  } else {
    return tax.amount;
  }
};


const IBEventSummary: React.FC = () => {
  const { basketId } = useParams<{ eventId: string; basketId: string }>();
  const classes = useIBEventSummaryStyles();
  const history = useHistory();
  const location = useLocation<{ isMasterAccount?: boolean }>();
  const isMasterAccount = location.state?.isMasterAccount; //state can be undefined because it is only used for MA
  const [isPurchaseOrder, setIsPurchaseOrder] = useState<string>('purchaseOrder');
  const [quoteOpen, setQuoteOpen] = useState<boolean>(false);
  const [invoiceOpen, setInvoiceOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const fullBasketQueryResult = useGetFullBasketQuery({
    variables: {
      id: parseInt(basketId),
    },
    fetchPolicy: 'cache-and-network',
    skip: basketId === undefined,
  });

  const [validateEventCartMutation] = useValidateEventCartMutation();

  const validateCart = async () => {
    setLoading(true);
    try {
      await validateEventCartMutation({
        variables: {
          id: parseInt(basketId),
          isPurchaseOrder: isPurchaseOrder === 'purchaseOrder',
        },
        refetchQueries: ['MyBaskets']
      });
      setLoading(false);
      history.push(`/bookingConfirmed/${fullBasketQueryResult.data?.getCommunicorBasket?.eventId.id}`);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };


  const calcVAT = (): { [key: number]: number } => {
    if (!fullBasketQueryResult.data || fullBasketQueryResult.data?.getCommunicorBasket?.tvaExempt) {
      return {
        0: 0,
      };
    }
    const basket = fullBasketQueryResult.data.getCommunicorBasket;
    if (basket === null || basket === undefined) {
      return {
        0: 0,
      };
    }
    const rates = {
      accreditation: rateFromTax(basket.accreditationTvaId),
      technical: rateFromTax(basket.technicalTvaId),
    };

    const groups: { [key: number]: number } = {};

    Object.values(rates).forEach(rate => {
      groups[rate] = 0;
    });
    basket.dailyLineIds.forEach(dailyLine => {
      if (dailyLine.tvaId?.amount) {
        if (groups[dailyLine.tvaId.amount] !== undefined) {
          groups[dailyLine.tvaId.amount] += (dailyLine.totalPrice || 0) * dailyLine.tvaId.amount / 100;
        } else {
          groups[dailyLine.tvaId.amount] = (dailyLine.totalPrice || 0) * dailyLine.tvaId.amount / 100;
        }
      }
    });
    basket.airportLineIds.forEach(airportLine => {
      if (airportLine.tvaId?.amount) {
        if (groups[airportLine.tvaId.amount] !== undefined) {
          groups[airportLine.tvaId.amount] += (airportLine.totalPrice || 0) * airportLine.tvaId.amount / 100;
        } else {
          groups[airportLine.tvaId.amount] = (airportLine.totalPrice || 0) * airportLine.tvaId.amount / 100;
        }
      }
    });

    groups[rates.accreditation] += (basket.priceAccreditation || 0) * rates.accreditation / 100;
    groups[rates.technical] += (basket.priceTechnical || 0) * rates.technical / 100;

    return groups;
  };

  const bookingState = fullBasketQueryResult.data?.getCommunicorBasket?.state?.key;

  const saleOrderState = fullBasketQueryResult.data?.getCommunicorBasket?.saleOrderId?.state?.key;

  const invoices = fullBasketQueryResult.data?.getCommunicorBasket?.saleOrderId?.invoiceIds;

  const invoiceState = invoices && invoices.length > 0 ? invoices[0].state.key : undefined;

  const markReady = invoices && invoices.length > 0 ? invoices[0].markReady : false;

  const noButtons =
    (invoiceState === 'cancel')
    || (bookingState === 'waiting' && saleOrderState === undefined)
    || (bookingState === 'waiting' && saleOrderState === 'draft')
    || (bookingState === 'declined')
    || (bookingState === 'sale' && (saleOrderState === 'sale' || saleOrderState === 'done') && (invoiceState === 'cancel'));

  const confirmQuoteButton =
    ((bookingState === 'sale' || bookingState === 'waiting') && saleOrderState === 'sent');

  const downloadQuoteButton = (bookingState === 'sale' && saleOrderState === 'cancel') || (bookingState === 'sale' && (saleOrderState === 'sale' || saleOrderState === 'done')) || (invoices && invoices.length > 0 && invoices[0].markReady === false); // && invoiceState === undefined);

  const confirmInvoiceButton =
    (bookingState === 'sale' && (saleOrderState === 'sale' || saleOrderState === 'done') && invoiceState === 'draft' && markReady);

  const downloadInvoiceButton =
    (bookingState === 'sale' && (saleOrderState === 'sale' || saleOrderState === 'done') && (invoiceState === 'posted' || invoiceState === 'cancel'));

  const downloadQuote = async () => {
    await downloadFile({
      fileName: `Quote - ${fullBasketQueryResult.data?.getCommunicorBasket?.saleOrderId?.name}.pdf`,
      filePath: `${config.ODOO_HOST}/report/quotation/${fullBasketQueryResult.data?.getCommunicorBasket?.saleOrderId?.id}`,
    });
  };

  const downloadInvoice = async () => {
    await downloadFile({
      fileName: `Invoice - ${invoices && invoices[0].name}.pdf`,
      filePath: `${config.ODOO_HOST}/report/invoice/${invoices && invoices[0].id}`
    });
  };

  const state =
    invoices && invoices.length > 0 ?
      markReady ?
        invoices[0].state.value === 'Draft' ?
          'Provisional invoice'
          :
          `Invoice ${invoices[0].state.value}`
        :
        'Sales order'
      :
      fullBasketQueryResult.data?.getCommunicorBasket?.saleOrderId !== null ?
        fullBasketQueryResult.data?.getCommunicorBasket?.saleOrderId?.state?.value
        :
        fullBasketQueryResult.data?.getCommunicorBasket?.state?.value === 'Not Complete' ?
          'Confirm Booking'
          :
          fullBasketQueryResult.data?.getCommunicorBasket?.state?.value;

  return (
    <PageLayout title={fullBasketQueryResult.data?.getCommunicorBasket?.eventId.name} navigation backButton>
      <RequestHandling {...fullBasketQueryResult}>
        {eventCart => {
          const canEdit = eventCart.getCommunicorBasket?.state?.value === 'empty' || eventCart.getCommunicorBasket?.state?.value === 'Not Complete';
          return (
            <GridContainerWithPadding container spacing={9}>
              <Grid item xs={12} className={classes.eventTitle}>
                <Typography className={classes.eventName}>
                  {eventCart.getCommunicorBasket?.eventId?.name}
                </Typography>
                <Typography className={classes.stepLabel}>Step : <span className={classes.step}>{state}</span></Typography>
                <hr className={classes.eventDivider} />
              </Grid>
              <Grid direction="column" item xs={8} lg={7} container spacing={2} className={classes.items}>
                <Grid item className={classes.title}>
                  <Typography className={classes.itemsTitle}>Items :</Typography>
                </Grid>
                <Grid item>
                  <Table>
                    <TableBody>
                      {
                        eventCart.getCommunicorBasket?.dailyLineIds.map((dailyLine: CommunicorDailyFieldsFragment) => (
                          <>
                            <TableRow>
                              <TableCellWithoutBorder className={classes.itemName}>Daily Disposal</TableCellWithoutBorder>
                            </TableRow>
                            {dailyLine.passengerId !== null && dailyLine.passengerId !== undefined && <TableRow>
                              <TableCellWithoutBorder className={classes.itemLabel}>
                                {
                                  dailyLine.passengerId.isPassengersGroup ?
                                    'Passenger group name : '
                                    :
                                    'Passenger :'
                                }
                                <span className={classes.itemValue}>
                                  {
                                    dailyLine.passengerId.isPassengersGroup ?
                                      dailyLine.passengerId.groupName
                                      :
                                      `${dailyLine.passengerId.firstname} ${dailyLine.passengerId.name}`
                                  }
                                </span>
                              </TableCellWithoutBorder>
                            </TableRow>}
                            <TableRow>
                              <TableCellWithoutBorder className={classes.itemLabel}>Vehicle Type: <span className={classes.itemValue}>{dailyLine.vehicleTypeId.name}</span></TableCellWithoutBorder>
                            </TableRow>
                            <TableRow>
                              <TableCellWithoutBorder className={classes.itemLabel}>Dates: <span className={classes.itemValue}>{formatPeriod(dailyLine.startDate, dailyLine.endDate)}</span></TableCellWithoutBorder>
                              <TableCellWithoutBorder className={classes.itemPrice}>{formatPrice(dailyLine.totalPrice || 0, eventCart.getCommunicorBasket?.currency?.symbol || '£')}</TableCellWithoutBorder>
                            </TableRow>
                            <TableRow>
                              <TableCellWithoutBorder colSpan={2} className={classes.itemDividerRow}>
                                <hr className={classes.itemDivider} />
                              </TableCellWithoutBorder>
                            </TableRow>
                          </>
                        ))
                      }
                      {
                        eventCart.getCommunicorBasket?.airportLineIds.map((airportLine: CommunicorAirportFieldsFragment) => (
                          <>
                            <TableRow>
                              <TableCellWithoutBorder className={classes.itemName}>Airport Transfer - {airportLine.startDate ? 'Arrival' : 'Departure'}</TableCellWithoutBorder>
                            </TableRow>
                            {airportLine.passengerId !== null && airportLine.passengerId !== undefined && <TableRow>
                              <TableCellWithoutBorder className={classes.itemLabel}>
                                {
                                  airportLine.passengerId.isPassengersGroup ?
                                    'Passenger group name : '
                                    :
                                    'Passenger :'
                                }
                                <span className={classes.itemValue}>
                                  {
                                    airportLine.passengerId.isPassengersGroup ?
                                      airportLine.passengerId.groupName
                                      :
                                      `${airportLine.passengerId.firstname} ${airportLine.passengerId.name}`
                                  }
                                </span>
                              </TableCellWithoutBorder>
                            </TableRow>}
                            <TableRow>
                              <TableCellWithoutBorder className={classes.itemLabel}>Vehicle Type: <span className={classes.itemValue}>{airportLine.vehicleTypeId.name}</span></TableCellWithoutBorder>
                            </TableRow>
                            <TableRow>
                              <TableCellWithoutBorder className={classes.itemLabel}>{airportLine.startDate ? 'Arriving Date' : 'Date of Departure'}: <span className={classes.itemValue}>{formatLitteralDate(airportLine.startDate ? airportLine.startDate : airportLine.endDate || '')}</span></TableCellWithoutBorder>
                            </TableRow>
                            <TableRow>
                              <TableCellWithoutBorder className={classes.itemLabel}>{airportLine.startDate ? 'Arriving Time' : 'Time of Departure'}: <span className={classes.itemValue}>{formatLitteralTime(airportLine.startDate ? airportLine.startDate : airportLine.endDate || '', fullBasketQueryResult.data?.getCommunicorBasket?.eventId?.tz.value)}</span></TableCellWithoutBorder>
                              <TableCellWithoutBorder className={classes.itemPrice}>{formatPrice(airportLine.totalPrice || 0, eventCart.getCommunicorBasket?.currency?.symbol || '£')}</TableCellWithoutBorder>
                            </TableRow>
                            <TableRow>
                              <TableCellWithoutBorder colSpan={2} className={classes.itemDividerRow}>
                                <hr className={classes.itemDivider} />
                              </TableCellWithoutBorder>
                            </TableRow>
                          </>
                        ))
                      }
                      {
                        eventCart.getCommunicorBasket?.accreditationLineIds.map((accreditationLine: CommunicorAccreditationFieldsFragment) => (
                          <>
                            <TableRow>
                              <TableCellWithoutBorder className={classes.itemName}>Car Pass</TableCellWithoutBorder>
                            </TableRow>
                            {
                              accreditationLine.accreditationTypeId.isCarpass
                                ? (
                                  <TableRow>
                                    <TableCellWithoutBorder className={classes.itemLabel}>Type of Car Pass: <span className={classes.itemValue}>{accreditationLine.carpassType?.name}</span></TableCellWithoutBorder>
                                  </TableRow>
                                )
                                : (
                                  <TableRow>
                                    <TableCellWithoutBorder className={classes.itemLabel}>Service: <span className={classes.itemValue}>{accreditationLine.name}</span></TableCellWithoutBorder>
                                  </TableRow>
                                )
                            }
                            <TableRow>
                              <TableCellWithoutBorder className={classes.itemLabel}>Quantity: <span className={classes.itemValue}>{accreditationLine.quantity}</span></TableCellWithoutBorder>
                              <TableCellWithoutBorder className={classes.itemPrice}>{formatPrice(accreditationLine.totalPrice || 0, eventCart.getCommunicorBasket?.currency?.symbol || '£')}</TableCellWithoutBorder>
                            </TableRow>
                            <TableRow>
                              <TableCellWithoutBorder colSpan={2} className={classes.itemDividerRow}>
                                <hr className={classes.itemDivider} />
                              </TableCellWithoutBorder>
                            </TableRow>
                          </>
                        ))
                      }
                      {
                        eventCart.getCommunicorBasket?.technicalLineIds.map((technicalLine: CommunicorTechnicalFieldsFragment) => (
                          <>
                            <TableRow>
                              <TableCellWithoutBorder className={classes.itemName}>Technical Support</TableCellWithoutBorder>
                            </TableRow>
                            {
                              technicalLine.technicalTypeId?.isMg
                                ? (
                                  <TableRow>
                                    <TableCellWithoutBorder className={classes.itemLabel}>{'Type of M&G:'} <span className={classes.itemValue}>{technicalLine.mgType?.name}</span></TableCellWithoutBorder>
                                  </TableRow>
                                )
                                : (
                                  <TableRow>
                                    <TableCellWithoutBorder className={classes.itemLabel}>{'Service:'} <span className={classes.itemValue}>{technicalLine.name}</span></TableCellWithoutBorder>
                                  </TableRow>
                                )
                            }
                            <TableRow>
                              <TableCellWithoutBorder className={classes.itemLabel}>Quantity: <span className={classes.itemValue}>{technicalLine.quantity}</span></TableCellWithoutBorder>
                              <TableCellWithoutBorder className={classes.itemPrice}>{formatPrice(technicalLine.totalPrice || 0, eventCart.getCommunicorBasket?.currency?.symbol || '£')}</TableCellWithoutBorder>
                            </TableRow>
                            <TableRow>
                              <TableCellWithoutBorder colSpan={2} className={classes.itemDividerRow}>
                                <hr className={classes.itemDivider} />
                              </TableCellWithoutBorder>
                            </TableRow>
                          </>
                        ))
                      }
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
              <Grid item xs={4} lg={5} container direction="column" spacing={2}>
                <Grid item className={classes.title}>
                  <Typography className={classes.itemsTitle}>Price :</Typography>
                </Grid>
                <Grid item>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCellWithoutBorder className={classes.label}>Total VAT excluded:</TableCellWithoutBorder>
                        <TableCellWithoutBorder className={classes.value}>{formatPrice(eventCart.getCommunicorBasket?.priceTotalHt || 0, eventCart.getCommunicorBasket?.currency?.symbol || '£')}</TableCellWithoutBorder>
                      </TableRow>
                      {Object.entries(calcVAT()).map(([rate, amount]) => rate !== '0' && amount !== 0 && (
                        <TableRow>
                          <TableCellWithoutBorder className={classes.label}>VAT {rate}%:</TableCellWithoutBorder>
                          <TableCellWithoutBorder className={classes.value}>{formatPrice(amount, eventCart.getCommunicorBasket?.currency?.symbol || '£')}</TableCellWithoutBorder>
                        </TableRow>
                      ))}
                      {eventCart.getCommunicorBasket?.tvaExempt && <TableRow>
                        <TableCellWithoutBorder className={classes.label}>VAT:</TableCellWithoutBorder>
                        <TableCellWithoutBorder className={classes.value}>EXEMPT</TableCellWithoutBorder>
                      </TableRow>}
                      <TableRow>
                        <TableCellWithoutBorder className={classes.label}>Total VAT included:</TableCellWithoutBorder>
                        <TableCellWithoutBorder className={classes.value}>{formatPrice(eventCart.getCommunicorBasket?.priceTotalTtc || 0, eventCart.getCommunicorBasket?.currency?.symbol || '£')}</TableCellWithoutBorder>
                      </TableRow>
                      {!isMasterAccount && !noButtons &&
                        <>
                          {
                            downloadInvoiceButton ?
                              <TableRow>
                                <TableCellWithoutBorder colSpan={2} className={classes.bookingButtonRow}>
                                  <Button variant="contained" size="large" className={classes.bookingButton} onClick={downloadInvoice}>Download the invoice </Button> {/* {isQuote ? 'Confirm the quote' : 'Confirm the invoice'} */}
                                </TableCellWithoutBorder>
                              </TableRow>
                              : confirmInvoiceButton ?
                                <TableRow>
                                  <TableCellWithoutBorder colSpan={2} className={classes.bookingButtonRow}>
                                    <Button variant="contained" size="large" className={classes.bookingButton} onClick={() => setInvoiceOpen(true)}>Open the invoice </Button> {/* {isQuote ? 'Confirm the quote' : 'Confirm the invoice'} */}
                                  </TableCellWithoutBorder>
                                </TableRow>
                                :
                                downloadQuoteButton ?
                                  <TableRow>
                                    <TableCellWithoutBorder colSpan={2} className={classes.bookingButtonRow}>
                                      <Button variant="contained" size="large" className={classes.bookingButton} onClick={downloadQuote}>Download the quote </Button> {/* {isQuote ? 'Confirm the quote' : 'Confirm the invoice'} */}
                                    </TableCellWithoutBorder>
                                  </TableRow>
                                  : confirmQuoteButton ?
                                    <TableRow>
                                      <TableCellWithoutBorder colSpan={2} className={classes.bookingButtonRow}>
                                        <Button variant="contained" size="large" className={classes.bookingButton} onClick={() => setQuoteOpen(true)}>Open the quote </Button> {/* {isQuote ? 'Confirm the quote' : 'Confirm the invoice'} */}
                                      </TableCellWithoutBorder>
                                    </TableRow>
                                    :
                                    <>
                                      {canEdit && <TableRow className={classes.paiementInfo}>
                                        Please note, unless you are in a position to submit a credit card number to secure the booking, you must proceed with a purchase ordre number request.
                                      </TableRow>}
                                      {canEdit && <TableRow>
                                        <RadioGroup row aria-label="paiement" name="paiement" value={isPurchaseOrder} onChange={(event) => setIsPurchaseOrder(event.target.value)}>
                                          <FormControlLabel value="purchaseOrder" control={<Radio />} label="Purchase Order" />
                                          <FormControlLabel value="creditCard" control={<Radio />} label="Credit Card (4% transaction fees)" />
                                        </RadioGroup>
                                      </TableRow>}
                                      {canEdit && <TableRow>
                                        <TableCellWithoutBorder colSpan={2} className={classes.bookingButtonRow}>
                                          <GreyButton variant="contained" size="large" className={classes.bookingButton} onClick={() => history.goBack()}>Edit the booking</GreyButton>
                                        </TableCellWithoutBorder>
                                      </TableRow>}
                                      {canEdit && <TableRow>
                                        <TableCellWithoutBorder colSpan={2} className={classes.bookingButtonRow} style={{ marginBottom: '10px' }}>
                                          <Button variant="contained" size="large" disabled={loading} className={classes.bookingButton} onClick={() => validateCart()}>{!loading ? 'Confirm the booking' : <CircularProgress />}</Button>
                                        </TableCellWithoutBorder>
                                      </TableRow>}
                                      {!canEdit && confirmQuoteButton && <TableRow>
                                        <TableCellWithoutBorder colSpan={2} className={classes.bookingButtonRow}>
                                          <Button variant="contained" size="large" className={classes.bookingButton} onClick={() => setQuoteOpen(true)}>Open the quote </Button> {/* {isQuote ? 'Confirm the quote' : 'Confirm the invoice'} */}
                                        </TableCellWithoutBorder>
                                      </TableRow>}
                                    </>
                          }
                        </>
                      }
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
              {
                eventCart.getCommunicorBasket?.saleOrderId !== undefined && eventCart.getCommunicorBasket?.saleOrderId !== null &&
                <QuoteModal isOpen={quoteOpen} setIsOpen={setQuoteOpen} booking={eventCart.getCommunicorBasket} />
              }
              {
                eventCart.getCommunicorBasket && invoices && invoices.length > 0 &&
                <InvoiceModal isOpen={invoiceOpen} setIsOpen={setInvoiceOpen} booking={eventCart.getCommunicorBasket} />
              }
            </GridContainerWithPadding>
          );
        }}
      </RequestHandling>
    </PageLayout>
  );
};

export default IBEventSummary;
