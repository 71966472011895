/* Framework imports ----------------------------------- */
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';

/* Module imports -------------------------------------- */

/* Component imports ----------------------------------- */

/* CustomRadio component prop types -------------------- */
interface CustomRadioProps {
  editable: boolean;
  value: string;
  onChange: (value: string) => void;
  label: string;
}

/* CustomRadio component ------------------------------- */
const CustomRadio: React.FC<CustomRadioProps> = ({ editable, value, onChange, label }) => {
  if (editable) {
    return (
      <FormControl>
        <FormLabel id="demo-controlled-radio-buttons-group" style={{ marginBottom: '5px' }}>Group type</FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name={label}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        >
          <FormControlLabel value={'individual'} control={<Radio size="small" />} label="Individual" />
          <FormControlLabel value={'group'} control={<Radio size="small" />} label="Group" />
        </RadioGroup>
      </FormControl>
    );
  } else {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', color: 'lightgray', marginBottom: '15px', fontWeight: 'bold' }}>
        {
          value === 'individual' ? 
            'Individual passenger' :
            'Passenger group'
        }
      </div>
    );
  }
};

/* Export CustomRadio component ------------------------ */
export default CustomRadio;
