import { CircularProgress, Typography, Grid, Button } from '@material-ui/core';
import { useGetProfileQuery } from 'apollo/queries';
import PageLayout from 'layout/PageLayout/PageLayout';
import PersonalInformation from 'pages/SignUp/PersonalInformation/PersonalInformation';
import { useEffect, useState } from 'react';
import { User } from 'type/User';
import GridContainerWithPadding from 'components/GridContainerWithPadding/GridContainerWithPadding';
import clsx from 'clsx';
import useProfileStyles from './ProfileStyle';
import { useUpdateUserProfileMutation } from 'apollo/mutations';
import { useHistory } from 'react-router';

const Profile: React.FC = () => {
  const classes = useProfileStyles();
  const history = useHistory();

  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [user, setUser] = useState<User>({
    firstName: '',
    lastName: '',
    gender: '',
    mobilePhone: '',
  });

  const { data, loading } = useGetProfileQuery();

  useEffect(() => {
    if (data?.getMyData) {
      const profile = data.getMyData;
      setUser({
        firstName: profile.firstname as string,
        lastName: profile.name as string,
        gender: profile.title?.id.toString() as string,
        mobilePhone: profile.phone as string
      });
    }
  }, [data?.getMyData]);


  const [updateProfile] = useUpdateUserProfileMutation();

  const updateInformation = async () => {
    if (data?.getMyData && user.firstName !== '' && user.lastName !== '' && user.mobilePhone !== '' && user.gender !== '') {
      setUpdateLoading(true);
      try {
        await updateProfile({
          variables: {
            id: data.getMyData.id,
            firstName: user.firstName,
            lastName: user.lastName,
            gender: parseInt(user.gender),
            phone: user.mobilePhone
          },
          refetchQueries: ['getProfile']
        });
        setUpdateLoading(false);
        history.push('/');
      } catch (err) {
        setUpdateLoading(false);
        console.log(err);
      }
    }
  };

  if (loading) {
    return (
      <PageLayout
        navigation
        title="Home"
      >
        <CircularProgress />
      </PageLayout>
    );
  } else {
    return (
      <PageLayout
        backButton
        navigation
        title="Profile">
        <GridContainerWithPadding container spacing={3} className={classes.container}>
          <Grid item xs={3} className={classes.step}>
            <Typography variant="h1">
              Profile
            </Typography>
          </Grid>
          <Grid item xs={6} alignItems="center" className={clsx(classes.fullHeight, classes.alignCenter)}>
            <PersonalInformation
              user={user}
              setUser={setUser}
              edit={true}
            />
          </Grid>
        </GridContainerWithPadding>
        <GridContainerWithPadding container className={classes.bottomButton} alignItems="center" alignContent="center">
          <Grid item xs={12} alignContent="center">
            <Button
              onClick={updateInformation}
              variant="contained"
              size="large"
              disabled={user.firstName === '' || user.lastName === '' || user.mobilePhone === '' || user.gender === '' || updateLoading}
            >
              {!updateLoading ? 'Update' : <CircularProgress />}
            </Button>
          </Grid>
        </GridContainerWithPadding>
      </PageLayout>
    );
  }
};

export default Profile;