import { Grid } from '@material-ui/core';
import { useDeleteAccreditationLineMutation, useUpdateCarPassLineMutation } from 'apollo/mutations';
import { CommunicorAccreditationFieldsFragment } from 'apollo/queries/types';
import { CommunicorAccreditationTemplate } from 'apollo/types';
import React, { useRef, useState } from 'react';
import { formatPrice } from 'utils/formatPrice';
import CartItem from './CartItem';
import useCartItemCommonStyles from './CartItemCommonStyles';
import EditCarPassLineModal from './EditCarPassLineModal/EditCarPassLineModal';

interface Props {
  line: CommunicorAccreditationFieldsFragment;
  currency: string | undefined;
  canDelete?: boolean;
  canEdit?: boolean;
  pricelist: Omit<CommunicorAccreditationTemplate, 'pricelistId'>[] | undefined;
};

const CarPass: React.FC<Props> = ({ 
  line,
  currency,
  canDelete = true, 
  canEdit = true,
  pricelist
}) => {
  const classes = useCartItemCommonStyles();

  const [ quantity, setQuantity ] = useState<number>(line.quantity);

  const [ deleteAccreditationLineMutation ] = useDeleteAccreditationLineMutation();

  const editCarPassLineModalRef = useRef();

  const onDelete = async () => {
    try {
      await deleteAccreditationLineMutation({
        variables: {
          id: line.id
        },
        update(cache) {
          const id = cache.identify({
            __typename: 'CommunicorAccreditation',
            id: line.id
          });
          cache.evict({ id: id });
          cache.gc();
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const [ updateCarPassLineMutation ] = useUpdateCarPassLineMutation();

  const onEdit = () => {
    if (editCarPassLineModalRef && editCarPassLineModalRef.current) {
      // @ts-ignore
      editCarPassLineModalRef.current.open();
    }
  };

  const handleEdit = async () => {
    try {
      await updateCarPassLineMutation({
        variables: {
          id: line.id,
          quantity: quantity
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const currentPriceline = pricelist?.find((current) => line.accreditationTypeId.id === current.accreditationTypeId.id && line.carpassType?.id === current.carpassType?.id);

  return (
    <CartItem 
      title={`Car Pass`} 
      price={(line.totalPrice !== null && line.totalPrice !== undefined) ? formatPrice(line.totalPrice, currency || '£') : ''}
      canDelete={canDelete}
      canEdit={canEdit}
      onDelete={onDelete}
      onEdit={onEdit}
    >
      <Grid item xs={6} className={classes.title}>
        { `${line.accreditationTypeId.isCarpass ? 'Type of Car Pass' : 'Service'}:` }
      </Grid>
      <Grid item xs={6} className={classes.title}>
        Quantity:
      </Grid>
      <Grid item xs={6} className={classes.content}>
        { line.carpassType ? line.carpassType.name : line.name}
      </Grid>
      <Grid item xs={6} className={classes.content}>
        { line.quantity }
      </Grid>
      <EditCarPassLineModal
        quantity={quantity}
        setQuantity={setQuantity}
        onValidate={handleEdit}
        ref={editCarPassLineModalRef}
        priceline={currentPriceline}
      />
    </CartItem>
  );
};

export default CarPass;