
import { makeVar, ReactiveVar } from '@apollo/client';

const variables: { [key: string]: ReactiveVar<any> } = {};

type DataStoreId = 'memory' | 'sessionStorage' | 'localStorage';
type DataStore<T> = {
  getInitialValue: (key: string, defaultValue: T) => T,
  setValue: (key: string, value: T) => void,
};

const dataStores: { [key in DataStoreId]: DataStore<any> } = {
  memory: {
    getInitialValue: (key, defaultValue) => defaultValue,
    setValue: () => {},
  },
  sessionStorage: {
    getInitialValue: (key, defaultValue) => {
      const storageItem = window.sessionStorage.getItem(key);

      if (storageItem === null) {
        window.sessionStorage.setItem(key, JSON.stringify(defaultValue));
        return defaultValue;
      }

      return JSON.parse(storageItem);
    },
    setValue: (key, value) => {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    },
  },
  localStorage: {
    getInitialValue: (key, defaultValue) => {
      const storageItem = window.localStorage.getItem(key);
      
      if (storageItem === null) {
        window.localStorage.setItem(key, JSON.stringify(defaultValue));
        return defaultValue;
      }

      return JSON.parse(storageItem);
    },
    setValue: (key, value) => {
      window.localStorage.setItem(key, JSON.stringify(value));
    },
  },
};

export const storeKey = <T>(key: string, defaultValue: T, dataStore: DataStoreId = 'memory') => {

  const initialValue: T = dataStores[dataStore].getInitialValue(key, defaultValue);

  const variable = makeVar<T>(initialValue);

  variables[key] = variable;

  return (value: T) => {
    dataStores[dataStore].setValue(key, value);
    variable(value);
  };
};

export const computeLocalQueryPolicies = () => {

  const queryPolicies: { [key: string]: { read: () => any } } = {};
  
  return Object.entries(variables).reduce((queryPolicies, [key, variable]) => {
    queryPolicies[key] = {
      read: () => variable(),
    };

    return queryPolicies;
  }, queryPolicies);
};
