import * as Types from "./types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
const defaultOptions = {};
export const EventFieldsFragmentDoc = gql`
  fragment EventFields on CommunicorEvent {
    id
    name
    startDate
    endDate
    website
    tz {
      key
      value
    }
  }
`;
export const PassengerFragmentDoc = gql`
  fragment Passenger on ResUsers {
    id
    name
    firstname
    email
    phone
    isPassengersGroup
    groupName
    groupSize
  }
`;
export const CommunicorDailyFieldsFragmentDoc = gql`
  fragment CommunicorDailyFields on CommunicorDaily {
    id
    vehicleTypeId {
      id
      name
    }
    passengerId {
      ...Passenger
    }
    startDate
    endDate
    totalPrice
    tvaId {
      id
      amount
    }
  }
  ${PassengerFragmentDoc}
`;
export const CommunicorAirportFieldsFragmentDoc = gql`
  fragment CommunicorAirportFields on CommunicorAirport {
    id
    vehicleTypeId {
      id
      name
    }
    passengerId {
      ...Passenger
    }
    startDate
    endDate
    totalPrice
    quantity
    pricePerUnit
    externalTransportType {
      key
      value
    }
    transportCode
    transportNumber
    transportOrigin
    transportDestination
    tvaId {
      id
      amount
    }
  }
  ${PassengerFragmentDoc}
`;
export const CommunicorAccreditationFieldsFragmentDoc = gql`
  fragment CommunicorAccreditationFields on CommunicorAccreditation {
    id
    name
    quantity
    totalPrice
    accreditationTypeId {
      id
      name
      isCarpass
    }
    carpassType {
      id
      name
    }
  }
`;
export const AccountTaxFieldsFragmentDoc = gql`
  fragment AccountTaxFields on AccountTax {
    id
    name
    amount
  }
`;
export const CommunicorTechnicalFieldsFragmentDoc = gql`
  fragment CommunicorTechnicalFields on CommunicorTechnical {
    id
    name
    quantity
    totalPrice
    technicalTypeId {
      id
      name
      isMg
    }
    mgType {
      id
      name
    }
    startDate
    endDate
  }
`;
export const CurrencyFieldsFragmentDoc = gql`
  fragment CurrencyFields on ResCurrency {
    id
    name
    symbol
  }
`;
export const VehicleFieldsFragmentDoc = gql`
  fragment VehicleFields on CommunicorVehicleType {
    id
    name
    description
    numberSeats
    luxury
  }
`;
export const PricelistFieldsFullFragmentDoc = gql`
  fragment PricelistFieldsFull on CommunicorPricelist {
    id
    currency {
      ...CurrencyFields
    }
    default
    divisionId {
      id
    }
    dailyLineIds {
      id
      vehicleTypeId {
        ...VehicleFields
      }
      pricePerDay
      ratePerExtraHour
      ratePerExtraKm
      maxHours
      maxKm
      currency {
        id
        name
        symbol
      }
    }
    airportLineIds {
      id
      vehicleTypeId {
        ...VehicleFields
      }
      pricePerUnit
      ratePerExtraHour
      ratePerExtra
      currency {
        id
        name
        symbol
      }
    }
    accreditationLineIds {
      id
      accreditationTypeId {
        id
        name
        isCarpass
      }
      name
      pricePerUnit
      createDate
      carpassType {
        id
        name
        description
      }
      currency {
        id
        name
        symbol
      }
    }
    technicalLineIds {
      id
      technicalTypeId {
        id
        name
        isMg
      }
      name
      pricePerUnit
      mgType {
        id
        name
        description
      }
      currency {
        id
        name
        symbol
      }
    }
  }
  ${CurrencyFieldsFragmentDoc}
  ${VehicleFieldsFragmentDoc}
`;
export const SaleOrderFieldsFragmentDoc = gql`
  fragment SaleOrderFields on SaleOrder {
    id
    dateOrder
    name
    state {
      key
      value
    }
    invoiceIds {
      id
      markReady
      state {
        key
        value
      }
      name
    }
  }
`;
export const BasketFieldsFullFragmentDoc = gql`
  fragment BasketFieldsFull on CommunicorBasket {
    id
    priceTotalHt
    priceTotalTtc
    priceTransportation
    priceTransfers
    priceAccreditation
    priceTechnical
    tvaExempt
    state {
      key
      value
    }
    eventId {
      ...EventFields
    }
    dailyLineIds {
      ...CommunicorDailyFields
    }
    airportLineIds {
      ...CommunicorAirportFields
    }
    accreditationLineIds {
      ...CommunicorAccreditationFields
    }
    accreditationTvaId {
      ...AccountTaxFields
    }
    technicalLineIds {
      ...CommunicorTechnicalFields
    }
    technicalTvaId {
      ...AccountTaxFields
    }
    currency {
      ...CurrencyFields
    }
    pricelistId {
      ...PricelistFieldsFull
    }
    saleOrderId {
      ...SaleOrderFields
    }
    pricelistId {
      cgv
      cancelPolicy
    }
  }
  ${EventFieldsFragmentDoc}
  ${CommunicorDailyFieldsFragmentDoc}
  ${CommunicorAirportFieldsFragmentDoc}
  ${CommunicorAccreditationFieldsFragmentDoc}
  ${AccountTaxFieldsFragmentDoc}
  ${CommunicorTechnicalFieldsFragmentDoc}
  ${CurrencyFieldsFragmentDoc}
  ${PricelistFieldsFullFragmentDoc}
  ${SaleOrderFieldsFragmentDoc}
`;
export const BasketFieldsLightFragmentDoc = gql`
  fragment BasketFieldsLight on CommunicorBasket {
    id
    createDate
    state {
      key
      value
    }
    eventId {
      id
      name
    }
    saleOrderId {
      ...SaleOrderFields
    }
    pricelistId {
      cgv
      cancelPolicy
    }
    priceTotalTtc
    currency {
      symbol
    }
  }
  ${SaleOrderFieldsFragmentDoc}
`;
export const DivisionInformationsFragmentDoc = gql`
  fragment DivisionInformations on ResPartner {
    id
    name
    vat
  }
`;
export const IbFieldsFragmentDoc = gql`
  fragment IBFields on ResUsers {
    id
    name
    displayName
    basketIds {
      id
      eventId {
        id
      }
    }
  }
`;
export const PartnerFieldsFragmentDoc = gql`
  fragment PartnerFields on ResPartner {
    id
    name
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on ResUsers {
    id
    firstname
    name
    role {
      key
    }
    parentId {
      ...PartnerFields
    }
    divisionId {
      ...PartnerFields
    }
  }
  ${PartnerFieldsFragmentDoc}
`;
export const CreateAccreditationLineDocument = gql`
  mutation CreateAccreditationLine(
    $basketId: Int!
    $templateId: Int!
    $quantity: Int!
  ) {
    createCommunicorAccreditation(
      basketId: $basketId
      templateId: $templateId
      quantity: $quantity
    ) {
      created {
        ...CommunicorAccreditationFields
      }
    }
  }
  ${CommunicorAccreditationFieldsFragmentDoc}
`;
export type CreateAccreditationLineMutationFn = Apollo.MutationFunction<
  Types.CreateAccreditationLineMutation,
  Types.CreateAccreditationLineMutationVariables
>;
export type CreateAccreditationLineProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.CreateAccreditationLineMutation,
    Types.CreateAccreditationLineMutationVariables
  >;
} &
  TChildProps;
export function withCreateAccreditationLine<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CreateAccreditationLineMutation,
    Types.CreateAccreditationLineMutationVariables,
    CreateAccreditationLineProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.CreateAccreditationLineMutation,
    Types.CreateAccreditationLineMutationVariables,
    CreateAccreditationLineProps<TChildProps, TDataName>
  >(CreateAccreditationLineDocument, {
    alias: "createAccreditationLine",
    ...operationOptions,
  });
}

/**
 * __useCreateAccreditationLineMutation__
 *
 * To run a mutation, you first call `useCreateAccreditationLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccreditationLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccreditationLineMutation, { data, loading, error }] = useCreateAccreditationLineMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      templateId: // value for 'templateId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useCreateAccreditationLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateAccreditationLineMutation,
    Types.CreateAccreditationLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateAccreditationLineMutation,
    Types.CreateAccreditationLineMutationVariables
  >(CreateAccreditationLineDocument, options);
}
export type CreateAccreditationLineMutationHookResult = ReturnType<
  typeof useCreateAccreditationLineMutation
>;
export type CreateAccreditationLineMutationResult =
  Apollo.MutationResult<Types.CreateAccreditationLineMutation>;
export type CreateAccreditationLineMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateAccreditationLineMutation,
  Types.CreateAccreditationLineMutationVariables
>;
export const CreateAirportLineDocument = gql`
  mutation CreateAirportLine(
    $basketId: Int!
    $templateId: Int!
    $passengerId: Int!
    $startDate: String
    $endDate: String
    $quantity: Int!
    $transportCode: String
    $transportNumber: String
    $transportOrigin: String
    $transportDestination: String
    $externalTransportType: String!
  ) {
    createCommunicorAirport(
      basketId: $basketId
      templateId: $templateId
      passengerId: $passengerId
      startDate: $startDate
      endDate: $endDate
      quantity: $quantity
      transportCode: $transportCode
      transportNumber: $transportNumber
      transportOrigin: $transportOrigin
      transportDestination: $transportDestination
      externalTransportType: $externalTransportType
    ) {
      created {
        ...CommunicorAirportFields
      }
    }
  }
  ${CommunicorAirportFieldsFragmentDoc}
`;
export type CreateAirportLineMutationFn = Apollo.MutationFunction<
  Types.CreateAirportLineMutation,
  Types.CreateAirportLineMutationVariables
>;
export type CreateAirportLineProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.CreateAirportLineMutation,
    Types.CreateAirportLineMutationVariables
  >;
} &
  TChildProps;
export function withCreateAirportLine<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CreateAirportLineMutation,
    Types.CreateAirportLineMutationVariables,
    CreateAirportLineProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.CreateAirportLineMutation,
    Types.CreateAirportLineMutationVariables,
    CreateAirportLineProps<TChildProps, TDataName>
  >(CreateAirportLineDocument, {
    alias: "createAirportLine",
    ...operationOptions,
  });
}

/**
 * __useCreateAirportLineMutation__
 *
 * To run a mutation, you first call `useCreateAirportLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAirportLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAirportLineMutation, { data, loading, error }] = useCreateAirportLineMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      templateId: // value for 'templateId'
 *      passengerId: // value for 'passengerId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      quantity: // value for 'quantity'
 *      transportCode: // value for 'transportCode'
 *      transportNumber: // value for 'transportNumber'
 *      transportOrigin: // value for 'transportOrigin'
 *      transportDestination: // value for 'transportDestination'
 *      externalTransportType: // value for 'externalTransportType'
 *   },
 * });
 */
export function useCreateAirportLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateAirportLineMutation,
    Types.CreateAirportLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateAirportLineMutation,
    Types.CreateAirportLineMutationVariables
  >(CreateAirportLineDocument, options);
}
export type CreateAirportLineMutationHookResult = ReturnType<
  typeof useCreateAirportLineMutation
>;
export type CreateAirportLineMutationResult =
  Apollo.MutationResult<Types.CreateAirportLineMutation>;
export type CreateAirportLineMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateAirportLineMutation,
  Types.CreateAirportLineMutationVariables
>;
export const CreateBasketDocument = gql`
  mutation CreateBasket($eventId: Int!, $userId: Int!, $pricelist: Int!) {
    createCommunicorBasket(
      eventId: $eventId
      userId: $userId
      pricelistId: $pricelist
    ) {
      created {
        ...BasketFieldsLight
      }
    }
  }
  ${BasketFieldsLightFragmentDoc}
`;
export type CreateBasketMutationFn = Apollo.MutationFunction<
  Types.CreateBasketMutation,
  Types.CreateBasketMutationVariables
>;
export type CreateBasketProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.CreateBasketMutation,
    Types.CreateBasketMutationVariables
  >;
} &
  TChildProps;
export function withCreateBasket<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CreateBasketMutation,
    Types.CreateBasketMutationVariables,
    CreateBasketProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.CreateBasketMutation,
    Types.CreateBasketMutationVariables,
    CreateBasketProps<TChildProps, TDataName>
  >(CreateBasketDocument, {
    alias: "createBasket",
    ...operationOptions,
  });
}

/**
 * __useCreateBasketMutation__
 *
 * To run a mutation, you first call `useCreateBasketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBasketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBasketMutation, { data, loading, error }] = useCreateBasketMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      userId: // value for 'userId'
 *      pricelist: // value for 'pricelist'
 *   },
 * });
 */
export function useCreateBasketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateBasketMutation,
    Types.CreateBasketMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateBasketMutation,
    Types.CreateBasketMutationVariables
  >(CreateBasketDocument, options);
}
export type CreateBasketMutationHookResult = ReturnType<
  typeof useCreateBasketMutation
>;
export type CreateBasketMutationResult =
  Apollo.MutationResult<Types.CreateBasketMutation>;
export type CreateBasketMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateBasketMutation,
  Types.CreateBasketMutationVariables
>;
export const CreateDailyLineDocument = gql`
  mutation CreateDailyLine(
    $basketId: Int!
    $templateId: Int!
    $passengerId: Int!
    $startDate: String!
    $endDate: String!
    $quantity: Int!
  ) {
    createCommunicorDaily(
      basketId: $basketId
      templateId: $templateId
      passengerId: $passengerId
      startDate: $startDate
      endDate: $endDate
      quantity: $quantity
    ) {
      created {
        ...CommunicorDailyFields
      }
    }
  }
  ${CommunicorDailyFieldsFragmentDoc}
`;
export type CreateDailyLineMutationFn = Apollo.MutationFunction<
  Types.CreateDailyLineMutation,
  Types.CreateDailyLineMutationVariables
>;
export type CreateDailyLineProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.CreateDailyLineMutation,
    Types.CreateDailyLineMutationVariables
  >;
} &
  TChildProps;
export function withCreateDailyLine<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CreateDailyLineMutation,
    Types.CreateDailyLineMutationVariables,
    CreateDailyLineProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.CreateDailyLineMutation,
    Types.CreateDailyLineMutationVariables,
    CreateDailyLineProps<TChildProps, TDataName>
  >(CreateDailyLineDocument, {
    alias: "createDailyLine",
    ...operationOptions,
  });
}

/**
 * __useCreateDailyLineMutation__
 *
 * To run a mutation, you first call `useCreateDailyLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDailyLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDailyLineMutation, { data, loading, error }] = useCreateDailyLineMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      templateId: // value for 'templateId'
 *      passengerId: // value for 'passengerId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useCreateDailyLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateDailyLineMutation,
    Types.CreateDailyLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateDailyLineMutation,
    Types.CreateDailyLineMutationVariables
  >(CreateDailyLineDocument, options);
}
export type CreateDailyLineMutationHookResult = ReturnType<
  typeof useCreateDailyLineMutation
>;
export type CreateDailyLineMutationResult =
  Apollo.MutationResult<Types.CreateDailyLineMutation>;
export type CreateDailyLineMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateDailyLineMutation,
  Types.CreateDailyLineMutationVariables
>;
export const CreatePassengerDocument = gql`
  mutation CreatePassenger(
    $name: String!
    $firstname: String!
    $email: String!
    $phone: String!
    $isGroup: Boolean!
    $groupName: String
    $groupSize: Int
  ) {
    actionCreatePassenger(
      name: $name
      firstname: $firstname
      email: $email
      phone: $phone
      group: $isGroup
      groupName: $groupName
      groupSize: $groupSize
    ) {
      created
      id
    }
  }
`;
export type CreatePassengerMutationFn = Apollo.MutationFunction<
  Types.CreatePassengerMutation,
  Types.CreatePassengerMutationVariables
>;
export type CreatePassengerProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.CreatePassengerMutation,
    Types.CreatePassengerMutationVariables
  >;
} &
  TChildProps;
export function withCreatePassenger<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CreatePassengerMutation,
    Types.CreatePassengerMutationVariables,
    CreatePassengerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.CreatePassengerMutation,
    Types.CreatePassengerMutationVariables,
    CreatePassengerProps<TChildProps, TDataName>
  >(CreatePassengerDocument, {
    alias: "createPassenger",
    ...operationOptions,
  });
}

/**
 * __useCreatePassengerMutation__
 *
 * To run a mutation, you first call `useCreatePassengerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePassengerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPassengerMutation, { data, loading, error }] = useCreatePassengerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      firstname: // value for 'firstname'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      isGroup: // value for 'isGroup'
 *      groupName: // value for 'groupName'
 *      groupSize: // value for 'groupSize'
 *   },
 * });
 */
export function useCreatePassengerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreatePassengerMutation,
    Types.CreatePassengerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreatePassengerMutation,
    Types.CreatePassengerMutationVariables
  >(CreatePassengerDocument, options);
}
export type CreatePassengerMutationHookResult = ReturnType<
  typeof useCreatePassengerMutation
>;
export type CreatePassengerMutationResult =
  Apollo.MutationResult<Types.CreatePassengerMutation>;
export type CreatePassengerMutationOptions = Apollo.BaseMutationOptions<
  Types.CreatePassengerMutation,
  Types.CreatePassengerMutationVariables
>;
export const CreateTechnicalLineDocument = gql`
  mutation CreateTechnicalLine(
    $basketId: Int!
    $templateId: Int!
    $quantity: Int!
  ) {
    createCommunicorTechnical(
      basketId: $basketId
      templateId: $templateId
      quantity: $quantity
    ) {
      created {
        ...CommunicorTechnicalFields
      }
    }
  }
  ${CommunicorTechnicalFieldsFragmentDoc}
`;
export type CreateTechnicalLineMutationFn = Apollo.MutationFunction<
  Types.CreateTechnicalLineMutation,
  Types.CreateTechnicalLineMutationVariables
>;
export type CreateTechnicalLineProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.CreateTechnicalLineMutation,
    Types.CreateTechnicalLineMutationVariables
  >;
} &
  TChildProps;
export function withCreateTechnicalLine<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CreateTechnicalLineMutation,
    Types.CreateTechnicalLineMutationVariables,
    CreateTechnicalLineProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.CreateTechnicalLineMutation,
    Types.CreateTechnicalLineMutationVariables,
    CreateTechnicalLineProps<TChildProps, TDataName>
  >(CreateTechnicalLineDocument, {
    alias: "createTechnicalLine",
    ...operationOptions,
  });
}

/**
 * __useCreateTechnicalLineMutation__
 *
 * To run a mutation, you first call `useCreateTechnicalLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTechnicalLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTechnicalLineMutation, { data, loading, error }] = useCreateTechnicalLineMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      templateId: // value for 'templateId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useCreateTechnicalLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateTechnicalLineMutation,
    Types.CreateTechnicalLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateTechnicalLineMutation,
    Types.CreateTechnicalLineMutationVariables
  >(CreateTechnicalLineDocument, options);
}
export type CreateTechnicalLineMutationHookResult = ReturnType<
  typeof useCreateTechnicalLineMutation
>;
export type CreateTechnicalLineMutationResult =
  Apollo.MutationResult<Types.CreateTechnicalLineMutation>;
export type CreateTechnicalLineMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateTechnicalLineMutation,
  Types.CreateTechnicalLineMutationVariables
>;
export const DeleteAccreditationLineDocument = gql`
  mutation DeleteAccreditationLine($id: Int!) {
    deleteCommunicorAccreditation(id: $id) {
      ok
    }
  }
`;
export type DeleteAccreditationLineMutationFn = Apollo.MutationFunction<
  Types.DeleteAccreditationLineMutation,
  Types.DeleteAccreditationLineMutationVariables
>;
export type DeleteAccreditationLineProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.DeleteAccreditationLineMutation,
    Types.DeleteAccreditationLineMutationVariables
  >;
} &
  TChildProps;
export function withDeleteAccreditationLine<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.DeleteAccreditationLineMutation,
    Types.DeleteAccreditationLineMutationVariables,
    DeleteAccreditationLineProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.DeleteAccreditationLineMutation,
    Types.DeleteAccreditationLineMutationVariables,
    DeleteAccreditationLineProps<TChildProps, TDataName>
  >(DeleteAccreditationLineDocument, {
    alias: "deleteAccreditationLine",
    ...operationOptions,
  });
}

/**
 * __useDeleteAccreditationLineMutation__
 *
 * To run a mutation, you first call `useDeleteAccreditationLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccreditationLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccreditationLineMutation, { data, loading, error }] = useDeleteAccreditationLineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAccreditationLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteAccreditationLineMutation,
    Types.DeleteAccreditationLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteAccreditationLineMutation,
    Types.DeleteAccreditationLineMutationVariables
  >(DeleteAccreditationLineDocument, options);
}
export type DeleteAccreditationLineMutationHookResult = ReturnType<
  typeof useDeleteAccreditationLineMutation
>;
export type DeleteAccreditationLineMutationResult =
  Apollo.MutationResult<Types.DeleteAccreditationLineMutation>;
export type DeleteAccreditationLineMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteAccreditationLineMutation,
  Types.DeleteAccreditationLineMutationVariables
>;
export const DeleteAirportLineDocument = gql`
  mutation DeleteAirportLine($id: Int!) {
    deleteCommunicorAirport(id: $id) {
      ok
    }
  }
`;
export type DeleteAirportLineMutationFn = Apollo.MutationFunction<
  Types.DeleteAirportLineMutation,
  Types.DeleteAirportLineMutationVariables
>;
export type DeleteAirportLineProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.DeleteAirportLineMutation,
    Types.DeleteAirportLineMutationVariables
  >;
} &
  TChildProps;
export function withDeleteAirportLine<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.DeleteAirportLineMutation,
    Types.DeleteAirportLineMutationVariables,
    DeleteAirportLineProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.DeleteAirportLineMutation,
    Types.DeleteAirportLineMutationVariables,
    DeleteAirportLineProps<TChildProps, TDataName>
  >(DeleteAirportLineDocument, {
    alias: "deleteAirportLine",
    ...operationOptions,
  });
}

/**
 * __useDeleteAirportLineMutation__
 *
 * To run a mutation, you first call `useDeleteAirportLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAirportLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAirportLineMutation, { data, loading, error }] = useDeleteAirportLineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAirportLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteAirportLineMutation,
    Types.DeleteAirportLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteAirportLineMutation,
    Types.DeleteAirportLineMutationVariables
  >(DeleteAirportLineDocument, options);
}
export type DeleteAirportLineMutationHookResult = ReturnType<
  typeof useDeleteAirportLineMutation
>;
export type DeleteAirportLineMutationResult =
  Apollo.MutationResult<Types.DeleteAirportLineMutation>;
export type DeleteAirportLineMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteAirportLineMutation,
  Types.DeleteAirportLineMutationVariables
>;
export const DeleteDailyLineDocument = gql`
  mutation DeleteDailyLine($id: Int!) {
    deleteCommunicorDaily(id: $id) {
      ok
    }
  }
`;
export type DeleteDailyLineMutationFn = Apollo.MutationFunction<
  Types.DeleteDailyLineMutation,
  Types.DeleteDailyLineMutationVariables
>;
export type DeleteDailyLineProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.DeleteDailyLineMutation,
    Types.DeleteDailyLineMutationVariables
  >;
} &
  TChildProps;
export function withDeleteDailyLine<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.DeleteDailyLineMutation,
    Types.DeleteDailyLineMutationVariables,
    DeleteDailyLineProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.DeleteDailyLineMutation,
    Types.DeleteDailyLineMutationVariables,
    DeleteDailyLineProps<TChildProps, TDataName>
  >(DeleteDailyLineDocument, {
    alias: "deleteDailyLine",
    ...operationOptions,
  });
}

/**
 * __useDeleteDailyLineMutation__
 *
 * To run a mutation, you first call `useDeleteDailyLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDailyLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDailyLineMutation, { data, loading, error }] = useDeleteDailyLineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDailyLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteDailyLineMutation,
    Types.DeleteDailyLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteDailyLineMutation,
    Types.DeleteDailyLineMutationVariables
  >(DeleteDailyLineDocument, options);
}
export type DeleteDailyLineMutationHookResult = ReturnType<
  typeof useDeleteDailyLineMutation
>;
export type DeleteDailyLineMutationResult =
  Apollo.MutationResult<Types.DeleteDailyLineMutation>;
export type DeleteDailyLineMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteDailyLineMutation,
  Types.DeleteDailyLineMutationVariables
>;
export const DeletePassengerDocument = gql`
  mutation DeletePassenger($id: Int!) {
    deleteResUsers(id: $id) {
      ok
    }
  }
`;
export type DeletePassengerMutationFn = Apollo.MutationFunction<
  Types.DeletePassengerMutation,
  Types.DeletePassengerMutationVariables
>;
export type DeletePassengerProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.DeletePassengerMutation,
    Types.DeletePassengerMutationVariables
  >;
} &
  TChildProps;
export function withDeletePassenger<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.DeletePassengerMutation,
    Types.DeletePassengerMutationVariables,
    DeletePassengerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.DeletePassengerMutation,
    Types.DeletePassengerMutationVariables,
    DeletePassengerProps<TChildProps, TDataName>
  >(DeletePassengerDocument, {
    alias: "deletePassenger",
    ...operationOptions,
  });
}

/**
 * __useDeletePassengerMutation__
 *
 * To run a mutation, you first call `useDeletePassengerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePassengerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePassengerMutation, { data, loading, error }] = useDeletePassengerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePassengerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeletePassengerMutation,
    Types.DeletePassengerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeletePassengerMutation,
    Types.DeletePassengerMutationVariables
  >(DeletePassengerDocument, options);
}
export type DeletePassengerMutationHookResult = ReturnType<
  typeof useDeletePassengerMutation
>;
export type DeletePassengerMutationResult =
  Apollo.MutationResult<Types.DeletePassengerMutation>;
export type DeletePassengerMutationOptions = Apollo.BaseMutationOptions<
  Types.DeletePassengerMutation,
  Types.DeletePassengerMutationVariables
>;
export const DeleteTechnicalLineDocument = gql`
  mutation DeleteTechnicalLine($id: Int!) {
    deleteCommunicorTechnical(id: $id) {
      ok
    }
  }
`;
export type DeleteTechnicalLineMutationFn = Apollo.MutationFunction<
  Types.DeleteTechnicalLineMutation,
  Types.DeleteTechnicalLineMutationVariables
>;
export type DeleteTechnicalLineProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.DeleteTechnicalLineMutation,
    Types.DeleteTechnicalLineMutationVariables
  >;
} &
  TChildProps;
export function withDeleteTechnicalLine<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.DeleteTechnicalLineMutation,
    Types.DeleteTechnicalLineMutationVariables,
    DeleteTechnicalLineProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.DeleteTechnicalLineMutation,
    Types.DeleteTechnicalLineMutationVariables,
    DeleteTechnicalLineProps<TChildProps, TDataName>
  >(DeleteTechnicalLineDocument, {
    alias: "deleteTechnicalLine",
    ...operationOptions,
  });
}

/**
 * __useDeleteTechnicalLineMutation__
 *
 * To run a mutation, you first call `useDeleteTechnicalLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTechnicalLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTechnicalLineMutation, { data, loading, error }] = useDeleteTechnicalLineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTechnicalLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTechnicalLineMutation,
    Types.DeleteTechnicalLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteTechnicalLineMutation,
    Types.DeleteTechnicalLineMutationVariables
  >(DeleteTechnicalLineDocument, options);
}
export type DeleteTechnicalLineMutationHookResult = ReturnType<
  typeof useDeleteTechnicalLineMutation
>;
export type DeleteTechnicalLineMutationResult =
  Apollo.MutationResult<Types.DeleteTechnicalLineMutation>;
export type DeleteTechnicalLineMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTechnicalLineMutation,
  Types.DeleteTechnicalLineMutationVariables
>;
export const EditPassengerDocument = gql`
  mutation EditPassenger(
    $isPassengersGroup: Boolean!
    $id: Int!
    $name: String!
    $firstname: String!
    $email: String!
    $phone: String!
    $groupName: String
    $groupSize: Int
  ) {
    updateResUsers(
      id: $id
      isPassengersGroup: $isPassengersGroup
      name: $name
      firstname: $firstname
      email: $email
      phone: $phone
      groupName: $groupName
      groupSize: $groupSize
    ) {
      updated {
        id
      }
    }
  }
`;
export type EditPassengerMutationFn = Apollo.MutationFunction<
  Types.EditPassengerMutation,
  Types.EditPassengerMutationVariables
>;
export type EditPassengerProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.EditPassengerMutation,
    Types.EditPassengerMutationVariables
  >;
} &
  TChildProps;
export function withEditPassenger<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.EditPassengerMutation,
    Types.EditPassengerMutationVariables,
    EditPassengerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.EditPassengerMutation,
    Types.EditPassengerMutationVariables,
    EditPassengerProps<TChildProps, TDataName>
  >(EditPassengerDocument, {
    alias: "editPassenger",
    ...operationOptions,
  });
}

/**
 * __useEditPassengerMutation__
 *
 * To run a mutation, you first call `useEditPassengerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPassengerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPassengerMutation, { data, loading, error }] = useEditPassengerMutation({
 *   variables: {
 *      isPassengersGroup: // value for 'isPassengersGroup'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      firstname: // value for 'firstname'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      groupName: // value for 'groupName'
 *      groupSize: // value for 'groupSize'
 *   },
 * });
 */
export function useEditPassengerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.EditPassengerMutation,
    Types.EditPassengerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.EditPassengerMutation,
    Types.EditPassengerMutationVariables
  >(EditPassengerDocument, options);
}
export type EditPassengerMutationHookResult = ReturnType<
  typeof useEditPassengerMutation
>;
export type EditPassengerMutationResult =
  Apollo.MutationResult<Types.EditPassengerMutation>;
export type EditPassengerMutationOptions = Apollo.BaseMutationOptions<
  Types.EditPassengerMutation,
  Types.EditPassengerMutationVariables
>;
export const AcceptInvoiceDocument = gql`
  mutation acceptInvoice($id: Int!) {
    actionPostInvoice(accountMoveId: $id) {
      ok
    }
  }
`;
export type AcceptInvoiceMutationFn = Apollo.MutationFunction<
  Types.AcceptInvoiceMutation,
  Types.AcceptInvoiceMutationVariables
>;
export type AcceptInvoiceProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.AcceptInvoiceMutation,
    Types.AcceptInvoiceMutationVariables
  >;
} &
  TChildProps;
export function withAcceptInvoice<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.AcceptInvoiceMutation,
    Types.AcceptInvoiceMutationVariables,
    AcceptInvoiceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.AcceptInvoiceMutation,
    Types.AcceptInvoiceMutationVariables,
    AcceptInvoiceProps<TChildProps, TDataName>
  >(AcceptInvoiceDocument, {
    alias: "acceptInvoice",
    ...operationOptions,
  });
}

/**
 * __useAcceptInvoiceMutation__
 *
 * To run a mutation, you first call `useAcceptInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvoiceMutation, { data, loading, error }] = useAcceptInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AcceptInvoiceMutation,
    Types.AcceptInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AcceptInvoiceMutation,
    Types.AcceptInvoiceMutationVariables
  >(AcceptInvoiceDocument, options);
}
export type AcceptInvoiceMutationHookResult = ReturnType<
  typeof useAcceptInvoiceMutation
>;
export type AcceptInvoiceMutationResult =
  Apollo.MutationResult<Types.AcceptInvoiceMutation>;
export type AcceptInvoiceMutationOptions = Apollo.BaseMutationOptions<
  Types.AcceptInvoiceMutation,
  Types.AcceptInvoiceMutationVariables
>;
export const AddIndividualsDocument = gql`
  mutation addIndividuals($eventId: Int!, $individuals: [String!]!) {
    actionInviteBooker(eventId: $eventId, bookers: $individuals) {
      rejected
    }
  }
`;
export type AddIndividualsMutationFn = Apollo.MutationFunction<
  Types.AddIndividualsMutation,
  Types.AddIndividualsMutationVariables
>;
export type AddIndividualsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.AddIndividualsMutation,
    Types.AddIndividualsMutationVariables
  >;
} &
  TChildProps;
export function withAddIndividuals<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.AddIndividualsMutation,
    Types.AddIndividualsMutationVariables,
    AddIndividualsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.AddIndividualsMutation,
    Types.AddIndividualsMutationVariables,
    AddIndividualsProps<TChildProps, TDataName>
  >(AddIndividualsDocument, {
    alias: "addIndividuals",
    ...operationOptions,
  });
}

/**
 * __useAddIndividualsMutation__
 *
 * To run a mutation, you first call `useAddIndividualsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIndividualsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIndividualsMutation, { data, loading, error }] = useAddIndividualsMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      individuals: // value for 'individuals'
 *   },
 * });
 */
export function useAddIndividualsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AddIndividualsMutation,
    Types.AddIndividualsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AddIndividualsMutation,
    Types.AddIndividualsMutationVariables
  >(AddIndividualsDocument, options);
}
export type AddIndividualsMutationHookResult = ReturnType<
  typeof useAddIndividualsMutation
>;
export type AddIndividualsMutationResult =
  Apollo.MutationResult<Types.AddIndividualsMutation>;
export type AddIndividualsMutationOptions = Apollo.BaseMutationOptions<
  Types.AddIndividualsMutation,
  Types.AddIndividualsMutationVariables
>;
export const UpdateUserProfileDocument = gql`
  mutation UpdateUserProfile(
    $id: Int!
    $firstName: String!
    $lastName: String!
    $gender: Int!
    $phone: String!
  ) {
    updateResUsers(
      id: $id
      firstname: $firstName
      name: $lastName
      phone: $phone
      title: $gender
    ) {
      updated {
        id
      }
    }
  }
`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<
  Types.UpdateUserProfileMutation,
  Types.UpdateUserProfileMutationVariables
>;
export type UpdateUserProfileProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.UpdateUserProfileMutation,
    Types.UpdateUserProfileMutationVariables
  >;
} &
  TChildProps;
export function withUpdateUserProfile<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.UpdateUserProfileMutation,
    Types.UpdateUserProfileMutationVariables,
    UpdateUserProfileProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.UpdateUserProfileMutation,
    Types.UpdateUserProfileMutationVariables,
    UpdateUserProfileProps<TChildProps, TDataName>
  >(UpdateUserProfileDocument, {
    alias: "updateUserProfile",
    ...operationOptions,
  });
}

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      gender: // value for 'gender'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateUserProfileMutation,
    Types.UpdateUserProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateUserProfileMutation,
    Types.UpdateUserProfileMutationVariables
  >(UpdateUserProfileDocument, options);
}
export type UpdateUserProfileMutationHookResult = ReturnType<
  typeof useUpdateUserProfileMutation
>;
export type UpdateUserProfileMutationResult =
  Apollo.MutationResult<Types.UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateUserProfileMutation,
  Types.UpdateUserProfileMutationVariables
>;
export const AcceptQuoteDocument = gql`
  mutation acceptQuote($id: Int!) {
    actionConfirmQuotation(saleOrderId: $id) {
      ok
    }
  }
`;
export type AcceptQuoteMutationFn = Apollo.MutationFunction<
  Types.AcceptQuoteMutation,
  Types.AcceptQuoteMutationVariables
>;
export type AcceptQuoteProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.AcceptQuoteMutation,
    Types.AcceptQuoteMutationVariables
  >;
} &
  TChildProps;
export function withAcceptQuote<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.AcceptQuoteMutation,
    Types.AcceptQuoteMutationVariables,
    AcceptQuoteProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.AcceptQuoteMutation,
    Types.AcceptQuoteMutationVariables,
    AcceptQuoteProps<TChildProps, TDataName>
  >(AcceptQuoteDocument, {
    alias: "acceptQuote",
    ...operationOptions,
  });
}

/**
 * __useAcceptQuoteMutation__
 *
 * To run a mutation, you first call `useAcceptQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptQuoteMutation, { data, loading, error }] = useAcceptQuoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptQuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AcceptQuoteMutation,
    Types.AcceptQuoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AcceptQuoteMutation,
    Types.AcceptQuoteMutationVariables
  >(AcceptQuoteDocument, options);
}
export type AcceptQuoteMutationHookResult = ReturnType<
  typeof useAcceptQuoteMutation
>;
export type AcceptQuoteMutationResult =
  Apollo.MutationResult<Types.AcceptQuoteMutation>;
export type AcceptQuoteMutationOptions = Apollo.BaseMutationOptions<
  Types.AcceptQuoteMutation,
  Types.AcceptQuoteMutationVariables
>;
export const DeclineQuoteDocument = gql`
  mutation declineQuote($id: Int!) {
    actionCancelQuotation(saleOrderId: $id) {
      ok
    }
  }
`;
export type DeclineQuoteMutationFn = Apollo.MutationFunction<
  Types.DeclineQuoteMutation,
  Types.DeclineQuoteMutationVariables
>;
export type DeclineQuoteProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.DeclineQuoteMutation,
    Types.DeclineQuoteMutationVariables
  >;
} &
  TChildProps;
export function withDeclineQuote<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.DeclineQuoteMutation,
    Types.DeclineQuoteMutationVariables,
    DeclineQuoteProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.DeclineQuoteMutation,
    Types.DeclineQuoteMutationVariables,
    DeclineQuoteProps<TChildProps, TDataName>
  >(DeclineQuoteDocument, {
    alias: "declineQuote",
    ...operationOptions,
  });
}

/**
 * __useDeclineQuoteMutation__
 *
 * To run a mutation, you first call `useDeclineQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineQuoteMutation, { data, loading, error }] = useDeclineQuoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeclineQuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeclineQuoteMutation,
    Types.DeclineQuoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeclineQuoteMutation,
    Types.DeclineQuoteMutationVariables
  >(DeclineQuoteDocument, options);
}
export type DeclineQuoteMutationHookResult = ReturnType<
  typeof useDeclineQuoteMutation
>;
export type DeclineQuoteMutationResult =
  Apollo.MutationResult<Types.DeclineQuoteMutation>;
export type DeclineQuoteMutationOptions = Apollo.BaseMutationOptions<
  Types.DeclineQuoteMutation,
  Types.DeclineQuoteMutationVariables
>;
export const CreateNewDivisionDocument = gql`
  mutation createNewDivision(
    $name: String!
    $vatNumber: String!
    $zipCode: String!
    $street: String!
    $street2: String
    $city: String!
    $parentId: Int!
    $countryId: Int!
  ) {
    createResPartner(
      name: $name
      vat: $vatNumber
      zip: $zipCode
      street: $street
      street2: $street2
      city: $city
      mainCompanyId: $parentId
      countryId: $countryId
      role: "division"
      isCompany: true
    ) {
      created {
        id
      }
    }
  }
`;
export type CreateNewDivisionMutationFn = Apollo.MutationFunction<
  Types.CreateNewDivisionMutation,
  Types.CreateNewDivisionMutationVariables
>;
export type CreateNewDivisionProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.CreateNewDivisionMutation,
    Types.CreateNewDivisionMutationVariables
  >;
} &
  TChildProps;
export function withCreateNewDivision<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CreateNewDivisionMutation,
    Types.CreateNewDivisionMutationVariables,
    CreateNewDivisionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.CreateNewDivisionMutation,
    Types.CreateNewDivisionMutationVariables,
    CreateNewDivisionProps<TChildProps, TDataName>
  >(CreateNewDivisionDocument, {
    alias: "createNewDivision",
    ...operationOptions,
  });
}

/**
 * __useCreateNewDivisionMutation__
 *
 * To run a mutation, you first call `useCreateNewDivisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewDivisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewDivisionMutation, { data, loading, error }] = useCreateNewDivisionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      vatNumber: // value for 'vatNumber'
 *      zipCode: // value for 'zipCode'
 *      street: // value for 'street'
 *      street2: // value for 'street2'
 *      city: // value for 'city'
 *      parentId: // value for 'parentId'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useCreateNewDivisionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateNewDivisionMutation,
    Types.CreateNewDivisionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateNewDivisionMutation,
    Types.CreateNewDivisionMutationVariables
  >(CreateNewDivisionDocument, options);
}
export type CreateNewDivisionMutationHookResult = ReturnType<
  typeof useCreateNewDivisionMutation
>;
export type CreateNewDivisionMutationResult =
  Apollo.MutationResult<Types.CreateNewDivisionMutation>;
export type CreateNewDivisionMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateNewDivisionMutation,
  Types.CreateNewDivisionMutationVariables
>;
export const UpdateIbInformationsDocument = gql`
  mutation updateIBInformations(
    $id: Int!
    $password: String!
    $firstName: String!
    $lastName: String!
    $gender: Int!
    $phoneNumber: String!
    $divisionId: Int!
  ) {
    updateResUsers(
      id: $id
      password: $password
      firstname: $firstName
      name: $lastName
      phone: $phoneNumber
      divisionId: $divisionId
      title: $gender
    ) {
      updated {
        id
      }
    }
  }
`;
export type UpdateIbInformationsMutationFn = Apollo.MutationFunction<
  Types.UpdateIbInformationsMutation,
  Types.UpdateIbInformationsMutationVariables
>;
export type UpdateIbInformationsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.UpdateIbInformationsMutation,
    Types.UpdateIbInformationsMutationVariables
  >;
} &
  TChildProps;
export function withUpdateIbInformations<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.UpdateIbInformationsMutation,
    Types.UpdateIbInformationsMutationVariables,
    UpdateIbInformationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.UpdateIbInformationsMutation,
    Types.UpdateIbInformationsMutationVariables,
    UpdateIbInformationsProps<TChildProps, TDataName>
  >(UpdateIbInformationsDocument, {
    alias: "updateIbInformations",
    ...operationOptions,
  });
}

/**
 * __useUpdateIbInformationsMutation__
 *
 * To run a mutation, you first call `useUpdateIbInformationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIbInformationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIbInformationsMutation, { data, loading, error }] = useUpdateIbInformationsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      gender: // value for 'gender'
 *      phoneNumber: // value for 'phoneNumber'
 *      divisionId: // value for 'divisionId'
 *   },
 * });
 */
export function useUpdateIbInformationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateIbInformationsMutation,
    Types.UpdateIbInformationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateIbInformationsMutation,
    Types.UpdateIbInformationsMutationVariables
  >(UpdateIbInformationsDocument, options);
}
export type UpdateIbInformationsMutationHookResult = ReturnType<
  typeof useUpdateIbInformationsMutation
>;
export type UpdateIbInformationsMutationResult =
  Apollo.MutationResult<Types.UpdateIbInformationsMutation>;
export type UpdateIbInformationsMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateIbInformationsMutation,
  Types.UpdateIbInformationsMutationVariables
>;
export const UpdateAirportLineDocument = gql`
  mutation UpdateAirportLine(
    $id: Int!
    $startDate: String
    $endDate: String
    $passengerId: Int!
    $vehicle: Int!
    $template: Int!
    $transportCode: String
    $transportNumber: String
    $transportOrigin: String
    $transportDestination: String
    $externalTransportType: String!
  ) {
    updateCommunicorAirport(
      id: $id
      startDate: $startDate
      endDate: $endDate
      passengerId: $passengerId
      vehicleTypeId: $vehicle
      templateId: $template
      transportCode: $transportCode
      transportNumber: $transportNumber
      transportOrigin: $transportOrigin
      transportDestination: $transportDestination
      externalTransportType: $externalTransportType
    ) {
      updated {
        ...CommunicorAirportFields
      }
    }
  }
  ${CommunicorAirportFieldsFragmentDoc}
`;
export type UpdateAirportLineMutationFn = Apollo.MutationFunction<
  Types.UpdateAirportLineMutation,
  Types.UpdateAirportLineMutationVariables
>;
export type UpdateAirportLineProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.UpdateAirportLineMutation,
    Types.UpdateAirportLineMutationVariables
  >;
} &
  TChildProps;
export function withUpdateAirportLine<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.UpdateAirportLineMutation,
    Types.UpdateAirportLineMutationVariables,
    UpdateAirportLineProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.UpdateAirportLineMutation,
    Types.UpdateAirportLineMutationVariables,
    UpdateAirportLineProps<TChildProps, TDataName>
  >(UpdateAirportLineDocument, {
    alias: "updateAirportLine",
    ...operationOptions,
  });
}

/**
 * __useUpdateAirportLineMutation__
 *
 * To run a mutation, you first call `useUpdateAirportLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAirportLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAirportLineMutation, { data, loading, error }] = useUpdateAirportLineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      passengerId: // value for 'passengerId'
 *      vehicle: // value for 'vehicle'
 *      template: // value for 'template'
 *      transportCode: // value for 'transportCode'
 *      transportNumber: // value for 'transportNumber'
 *      transportOrigin: // value for 'transportOrigin'
 *      transportDestination: // value for 'transportDestination'
 *      externalTransportType: // value for 'externalTransportType'
 *   },
 * });
 */
export function useUpdateAirportLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateAirportLineMutation,
    Types.UpdateAirportLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateAirportLineMutation,
    Types.UpdateAirportLineMutationVariables
  >(UpdateAirportLineDocument, options);
}
export type UpdateAirportLineMutationHookResult = ReturnType<
  typeof useUpdateAirportLineMutation
>;
export type UpdateAirportLineMutationResult =
  Apollo.MutationResult<Types.UpdateAirportLineMutation>;
export type UpdateAirportLineMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateAirportLineMutation,
  Types.UpdateAirportLineMutationVariables
>;
export const UpdateCarPassLineDocument = gql`
  mutation UpdateCarPassLine($id: Int!, $quantity: Int!) {
    updateCommunicorAccreditation(id: $id, quantity: $quantity) {
      updated {
        ...CommunicorAccreditationFields
      }
    }
  }
  ${CommunicorAccreditationFieldsFragmentDoc}
`;
export type UpdateCarPassLineMutationFn = Apollo.MutationFunction<
  Types.UpdateCarPassLineMutation,
  Types.UpdateCarPassLineMutationVariables
>;
export type UpdateCarPassLineProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.UpdateCarPassLineMutation,
    Types.UpdateCarPassLineMutationVariables
  >;
} &
  TChildProps;
export function withUpdateCarPassLine<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.UpdateCarPassLineMutation,
    Types.UpdateCarPassLineMutationVariables,
    UpdateCarPassLineProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.UpdateCarPassLineMutation,
    Types.UpdateCarPassLineMutationVariables,
    UpdateCarPassLineProps<TChildProps, TDataName>
  >(UpdateCarPassLineDocument, {
    alias: "updateCarPassLine",
    ...operationOptions,
  });
}

/**
 * __useUpdateCarPassLineMutation__
 *
 * To run a mutation, you first call `useUpdateCarPassLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarPassLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarPassLineMutation, { data, loading, error }] = useUpdateCarPassLineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useUpdateCarPassLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateCarPassLineMutation,
    Types.UpdateCarPassLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateCarPassLineMutation,
    Types.UpdateCarPassLineMutationVariables
  >(UpdateCarPassLineDocument, options);
}
export type UpdateCarPassLineMutationHookResult = ReturnType<
  typeof useUpdateCarPassLineMutation
>;
export type UpdateCarPassLineMutationResult =
  Apollo.MutationResult<Types.UpdateCarPassLineMutation>;
export type UpdateCarPassLineMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateCarPassLineMutation,
  Types.UpdateCarPassLineMutationVariables
>;
export const UpdateDailyLineDocument = gql`
  mutation UpdateDailyLine(
    $id: Int!
    $startDate: String!
    $endDate: String!
    $vehicle: Int!
    $quantity: Int!
    $template: Int!
    $passengerId: Int!
  ) {
    updateCommunicorDaily(
      id: $id
      startDate: $startDate
      endDate: $endDate
      vehicleTypeId: $vehicle
      quantity: $quantity
      templateId: $template
      passengerId: $passengerId
    ) {
      updated {
        ...CommunicorDailyFields
      }
    }
  }
  ${CommunicorDailyFieldsFragmentDoc}
`;
export type UpdateDailyLineMutationFn = Apollo.MutationFunction<
  Types.UpdateDailyLineMutation,
  Types.UpdateDailyLineMutationVariables
>;
export type UpdateDailyLineProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.UpdateDailyLineMutation,
    Types.UpdateDailyLineMutationVariables
  >;
} &
  TChildProps;
export function withUpdateDailyLine<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.UpdateDailyLineMutation,
    Types.UpdateDailyLineMutationVariables,
    UpdateDailyLineProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.UpdateDailyLineMutation,
    Types.UpdateDailyLineMutationVariables,
    UpdateDailyLineProps<TChildProps, TDataName>
  >(UpdateDailyLineDocument, {
    alias: "updateDailyLine",
    ...operationOptions,
  });
}

/**
 * __useUpdateDailyLineMutation__
 *
 * To run a mutation, you first call `useUpdateDailyLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDailyLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDailyLineMutation, { data, loading, error }] = useUpdateDailyLineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      vehicle: // value for 'vehicle'
 *      quantity: // value for 'quantity'
 *      template: // value for 'template'
 *      passengerId: // value for 'passengerId'
 *   },
 * });
 */
export function useUpdateDailyLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateDailyLineMutation,
    Types.UpdateDailyLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateDailyLineMutation,
    Types.UpdateDailyLineMutationVariables
  >(UpdateDailyLineDocument, options);
}
export type UpdateDailyLineMutationHookResult = ReturnType<
  typeof useUpdateDailyLineMutation
>;
export type UpdateDailyLineMutationResult =
  Apollo.MutationResult<Types.UpdateDailyLineMutation>;
export type UpdateDailyLineMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateDailyLineMutation,
  Types.UpdateDailyLineMutationVariables
>;
export const UpdatePasswordDocument = gql`
  mutation updatePassword($id: Int!, $password: String!) {
    updateResUsers(id: $id, password: $password) {
      updated {
        id
      }
    }
  }
`;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<
  Types.UpdatePasswordMutation,
  Types.UpdatePasswordMutationVariables
>;
export type UpdatePasswordProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.UpdatePasswordMutation,
    Types.UpdatePasswordMutationVariables
  >;
} &
  TChildProps;
export function withUpdatePassword<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.UpdatePasswordMutation,
    Types.UpdatePasswordMutationVariables,
    UpdatePasswordProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.UpdatePasswordMutation,
    Types.UpdatePasswordMutationVariables,
    UpdatePasswordProps<TChildProps, TDataName>
  >(UpdatePasswordDocument, {
    alias: "updatePassword",
    ...operationOptions,
  });
}

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdatePasswordMutation,
    Types.UpdatePasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdatePasswordMutation,
    Types.UpdatePasswordMutationVariables
  >(UpdatePasswordDocument, options);
}
export type UpdatePasswordMutationHookResult = ReturnType<
  typeof useUpdatePasswordMutation
>;
export type UpdatePasswordMutationResult =
  Apollo.MutationResult<Types.UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdatePasswordMutation,
  Types.UpdatePasswordMutationVariables
>;
export const UpdateTechnicalLineDocument = gql`
  mutation UpdateTechnicalLine($id: Int!, $quantity: Int!) {
    updateCommunicorTechnical(id: $id, quantity: $quantity) {
      updated {
        ...CommunicorTechnicalFields
      }
    }
  }
  ${CommunicorTechnicalFieldsFragmentDoc}
`;
export type UpdateTechnicalLineMutationFn = Apollo.MutationFunction<
  Types.UpdateTechnicalLineMutation,
  Types.UpdateTechnicalLineMutationVariables
>;
export type UpdateTechnicalLineProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.UpdateTechnicalLineMutation,
    Types.UpdateTechnicalLineMutationVariables
  >;
} &
  TChildProps;
export function withUpdateTechnicalLine<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.UpdateTechnicalLineMutation,
    Types.UpdateTechnicalLineMutationVariables,
    UpdateTechnicalLineProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.UpdateTechnicalLineMutation,
    Types.UpdateTechnicalLineMutationVariables,
    UpdateTechnicalLineProps<TChildProps, TDataName>
  >(UpdateTechnicalLineDocument, {
    alias: "updateTechnicalLine",
    ...operationOptions,
  });
}

/**
 * __useUpdateTechnicalLineMutation__
 *
 * To run a mutation, you first call `useUpdateTechnicalLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTechnicalLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTechnicalLineMutation, { data, loading, error }] = useUpdateTechnicalLineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useUpdateTechnicalLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateTechnicalLineMutation,
    Types.UpdateTechnicalLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateTechnicalLineMutation,
    Types.UpdateTechnicalLineMutationVariables
  >(UpdateTechnicalLineDocument, options);
}
export type UpdateTechnicalLineMutationHookResult = ReturnType<
  typeof useUpdateTechnicalLineMutation
>;
export type UpdateTechnicalLineMutationResult =
  Apollo.MutationResult<Types.UpdateTechnicalLineMutation>;
export type UpdateTechnicalLineMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateTechnicalLineMutation,
  Types.UpdateTechnicalLineMutationVariables
>;
export const ValidateEventCartDocument = gql`
  mutation ValidateEventCart($id: Int!, $isPurchaseOrder: Boolean!) {
    updateCommunicorBasket(
      id: $id
      complete: true
      isPurchaseOrder: $isPurchaseOrder
    ) {
      updated {
        id
      }
    }
  }
`;
export type ValidateEventCartMutationFn = Apollo.MutationFunction<
  Types.ValidateEventCartMutation,
  Types.ValidateEventCartMutationVariables
>;
export type ValidateEventCartProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.ValidateEventCartMutation,
    Types.ValidateEventCartMutationVariables
  >;
} &
  TChildProps;
export function withValidateEventCart<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.ValidateEventCartMutation,
    Types.ValidateEventCartMutationVariables,
    ValidateEventCartProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.ValidateEventCartMutation,
    Types.ValidateEventCartMutationVariables,
    ValidateEventCartProps<TChildProps, TDataName>
  >(ValidateEventCartDocument, {
    alias: "validateEventCart",
    ...operationOptions,
  });
}

/**
 * __useValidateEventCartMutation__
 *
 * To run a mutation, you first call `useValidateEventCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateEventCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateEventCartMutation, { data, loading, error }] = useValidateEventCartMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isPurchaseOrder: // value for 'isPurchaseOrder'
 *   },
 * });
 */
export function useValidateEventCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ValidateEventCartMutation,
    Types.ValidateEventCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ValidateEventCartMutation,
    Types.ValidateEventCartMutationVariables
  >(ValidateEventCartDocument, options);
}
export type ValidateEventCartMutationHookResult = ReturnType<
  typeof useValidateEventCartMutation
>;
export type ValidateEventCartMutationResult =
  Apollo.MutationResult<Types.ValidateEventCartMutation>;
export type ValidateEventCartMutationOptions = Apollo.BaseMutationOptions<
  Types.ValidateEventCartMutation,
  Types.ValidateEventCartMutationVariables
>;
