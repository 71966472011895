import config from 'config/config.json';

const odooRequest = (route: string, body: object) => {
  return fetch(`${config.ODOO_HOST}${route}`, {
    method: 'POST',
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    });
};

export const getInvitation = ({ email, hash }: { email: string, hash: string }) => {
  return odooRequest('/getInvitationData', {    
    hash,
    email
  },
  )
    .then((body) => {
      if (body.error) {
        throw body.error;
      } else {
        return body;
      }
    });
};