import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import { CommunicorTechnicalTemplate } from 'apollo/types';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { formatPrice } from 'utils/formatPrice';

interface EditTechnicalLineModalProps {
  quantity: number;
  setQuantity: (quantity: number) => void;
  onValidate: () => Promise<void>;
  priceline: Omit<CommunicorTechnicalTemplate, 'pricelistId'> | undefined;
}

const EditTechnicalLineModal = forwardRef((props: EditTechnicalLineModalProps, ref) => {

  const [ opened, setOpened ] = useState<boolean>(false);

  const handleValidate = async () => {
    try {
      await props.onValidate();
      setOpened(false);
    } catch (err) {
      console.log(err);
    }
  };

  const open = () => {
    setOpened(true);
  };

  useImperativeHandle(ref, () => ({
    open
  }));

  const updateQuantity = (value: number) => {
    if (value >= 0) {
      props.setQuantity(value);
    } else {
      props.setQuantity(0);
    }
  };

  const price = (props.priceline?.pricePerUnit || 0) * props.quantity;

  return (
    <Dialog
      open={opened}
      onClose={() => setOpened(false)}
    >
      <DialogTitle> Editing Technical Support </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="quantity"
              label="Quantity"
              type="number"
              value={props.quantity.toString().replace(/^0+/, '') || '0'}
              onChange={(event) => updateQuantity(parseInt(event.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          
        </Grid>
        <DialogActions>
          <Typography>
            { `Price: ${formatPrice(price, props.priceline?.currency.symbol || '£')}` }
          </Typography>
          <Button 
            onClick={() => handleValidate()} 
            color="primary" 
            autoFocus
            variant="outlined" 
            style={{ marginLeft: '30px' }}
          >
            Validate
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
});

export default EditTechnicalLineModal;