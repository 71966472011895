import { Dialog, TableCell, withStyles } from '@material-ui/core';

export const StyledTableCell = withStyles((theme) => ({
  body: {
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  head: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  root: {
    borderColor: '#41474D'
  }
}))(TableCell);

export const SectionStyledTableCell = withStyles((theme) => ({
  body: {
    borderTop: '1px solid #41474D'
  },
}))(StyledTableCell);

export const IndentedStyledTableCell = withStyles((theme) => ({
  root: {
    textIndent: '10px',
    border: 'none'
  }
}))(StyledTableCell);

export const SizedDialog = withStyles({
  paper: {
    backgroundColor: 'black',
  },
  paperWidthSm: {
    width: '517px',
  }
})(Dialog);