import config from 'config/config.json';

const odooRequest = (route: string, body: object, authRequired: boolean = true) => {
  const token = localStorage.getItem('session_id');
  
  const customHeader: HeadersInit = (token !== null && token !== undefined && authRequired) ?
    {
      'X-Openerp-Session-Id': token,
    } :
    {};

  return fetch(`${config.ODOO_HOST}${route}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...customHeader,
    },
  })
    .then((res) => {
      return res.json();
    });
};

export const logout = () => {
  
  const request = odooRequest('/web/session/signout', {})
    .then(
      (body) => {
        if (body.error) {
          throw body.error;
        } else {
          document.cookie = 'session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        }
      });
  localStorage.removeItem('session_id');
  return request;
};

export const login = ({ email, password }: { email: string, password: string }) => {
  return odooRequest('/web/session/authenticate', {
    params: {
      login: email,
      password,
      db: config.ODOO_DATABASE,
    },
  })
    .then((body) => {
      if (body.error) {
        throw body.error;
      }

      if (body?.result?.session_id) {
        localStorage.setItem('session_id', body.result.session_id);
      }
    });
};