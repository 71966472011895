interface DownloadFileProps {
  filePath: string;
  fileName: string;
}

export const downloadFile = async ({ fileName, filePath } : DownloadFileProps) : Promise<void> => {
  const token = localStorage.getItem('session_id');
  try {
    const fetchResult = await fetch(filePath, {
      method: 'GET',
      headers: {
        Accept: '*/*',
        'X-Openerp-Session-Id': token as string
      },
    });
    const blobFile = await fetchResult.blob();

    const element = document.createElement('a');
    const url = window.URL.createObjectURL(blobFile);
    element.href = url;
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);

  } catch (err) {
    console.error(err);
  }
};