import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  itemsTitle: {
    float: 'left',
    fontSize: '24px',
    fontWeight: 600,
    fontFamily: 'Karla',
    marginTop: '25px',
    marginBottom: '5px'
  },
  addItemButton: {
    float: 'right',
    marginRight: '0px'
  },
  title: {
    borderBottom: '1px solid #6C757D',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
  label: {
    fontWeight: 300,
    fontFamily: 'Montserrat',
    fontSize: '18px'
  },
  value: {
    fontWeight: 600,
    fontSize: '20px',
    fontFamily: 'Montserrat',
    textAlign: 'right'
  },
  eventName: {
    fontWeight: 500,
    fontSize: '40px',
    fontFamily: 'Karla',
  },
  stepLabel: {
    fontWeight: 300,
    fontSize: '22px',
    fontFamily: 'Montserrat',
  },
  step: {
    fontWeight: 500,
    fontSize: '22px',
    fontFamily: 'Montserrat',
  },
  eventDivider: {
    width: '250px',
    float: 'left',
    marginTop: '15px'
  },
  eventTitle: {
    paddingBottom: '0px !important'
  },
  items: {
    marginBottom: '10px'
  }
});

export default useStyles;