import { makeStyles } from '@material-ui/core';

const useAddPassengerModalStyles = makeStyles({
  button: {
    '&.MuiButton-root.Mui-disabled': {
      color: '#777777',

      '&:hover': {
        cursor: 'default',
      }
    }
  }
});

export default useAddPassengerModalStyles;