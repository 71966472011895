import { makeStyles } from '@material-ui/core';

const useMyPassengersStyles = makeStyles({
  card: {
    width: '100%',
    padding: '2px',
    minHeight: '440px',
  },
  passengerName: {
    fontWeight: 700,
    fontFamily: 'Karla',
    fontSize: '20px'
  },
  noPadding: {
    padding: 0,
    margin: '10px 0',
  },
  title: {
    alignSelf: 'center',
    borderBottom: '1px solid #CED4DA',
    padding: '0 !important',
    paddingBottom: '20px !important'
  },
  disabledInput: {
    color: 'lightgrey !important',
  },
  deleteModale: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modaleBody: {
    backgroundColor: 'black',
    color: 'white',
    padding: 15,
    height: 300,
    width: 400,
  },
  cardButton: {
    '&:hover':{
      cursor: 'pointer',
    }
  },
  'cardButton-disabled': {
  },
  inputRoot: {
    '&$disabled': {
      color:'white'
    },
  }
});

export default useMyPassengersStyles;