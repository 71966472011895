import {
  Button,
  Grid,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TextField,
} from '@material-ui/core';
import { useGetTechnicalCartQuery } from 'apollo/queries';
import { CommunicorTechnicalTemplate } from 'apollo/types';
import RequestHandling from 'components/RequestHandling';
import PageLayout from 'layout/PageLayout/PageLayout';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';
import { CartLine } from './TechnicalSupportTypes';
import {
  StyledTableCell
} from './TechnicalSupportsStyledComponents';
import { useCreateTechnicalLineMutation } from 'apollo/mutations';
import useTechnicalSupportsStyles from './TechnicalSupportsStyles';
import GridContainerWithPadding from 'components/GridContainerWithPadding/GridContainerWithPadding';
import { formatPrice } from 'utils/formatPrice';

const TechnicalSupports: React.FC = () => {

  const classes = useTechnicalSupportsStyles();
  const history = useHistory();

  const { basketId } = useParams<{ eventId: string, basketId: string }>();
  const [ serviceQuantities, setServicesQuantities ] = useState<{[id: number]: number}>({});
  const [ mgQuantities, setMGQuantities ] = useState<{[id: number]: number}>({});
  const [ totalServicesPrice, setTotalServicePrice ] = useState<number>(0);
  const [ totalMGPrice, setTotalMGPrice ] = useState<number>(0);
  const [ meetAndGreets, setMeetAndGreets ] = useState<Omit<CommunicorTechnicalTemplate, 'pricelistId'>[]>([]);
  const [ services, setServices ] = useState<Omit<CommunicorTechnicalTemplate, 'pricelistId'>[]>([]);

  const getTechnicalCartQueryResults = useGetTechnicalCartQuery({
    variables: {
      id: parseInt(basketId),
    },
    skip: basketId === undefined,
  });

  const computeServiceCartLine = (): CartLine[] => {
    let total = 0;
    const lines: CartLine[] = Object.entries(serviceQuantities).reduce((acc: CartLine[], value) => {
      const id = parseInt(value[0]);
      const quantity = value[1];
      if (quantity > 0) {
        const priceLine = services.find((service) => service.id === id);
        if (priceLine) {
          const price = priceLine.pricePerUnit * quantity;
          total += price;
          acc.push({
            id,
            description: priceLine.name,
            quantity: quantity,
            price: price
          });
        }
      }
      return acc;
    }, []);
    if (total !== totalServicesPrice) {
      setTotalServicePrice(total);
    }
    return lines;
  };

  const computeMGCartLine = () => {
    let total = 0;
    const lines: CartLine[] =  Object.entries(mgQuantities).reduce((acc: CartLine[], value) => {
      const id = parseInt(value[0]);
      const quantity = value[1];
      if (quantity > 0) {
        const priceLine = meetAndGreets.find((mg) => mg.id === id);
        if (priceLine) {
          const price = priceLine.pricePerUnit * quantity;
          total += price;
          acc.push({
            id,
            description: priceLine.mgType?.name || '',
            quantity: quantity,
            price: price
          });
        }
      }
      return acc;
    }, []);
    if (total !== totalMGPrice) {
      setTotalMGPrice(total);
    }
    return lines;
  };

  useEffect(() => {
    if (!getTechnicalCartQueryResults.loading && !getTechnicalCartQueryResults.error) {
      const mgs = getTechnicalCartQueryResults.data?.getCommunicorBasket?.pricelistId?.technicalLineIds.filter(line => line.technicalTypeId?.isMg);
      setMeetAndGreets(mgs || []);
      if (mgs) {
        setMGQuantities(mgs.reduce((accr, value) => {
          return {
            ...accr,
            [value.id]: 0
          };
        }, {}));
      }

      const mgServices = getTechnicalCartQueryResults.data?.getCommunicorBasket?.pricelistId?.technicalLineIds.filter(line => !line.technicalTypeId?.isMg);
      setServices(mgServices || []);
      if (mgServices) {
        setServicesQuantities(mgServices.reduce((acc, value) => {
          return {
            ...acc,
            [value.id]: 0
          };
        }, {}));
      }
    }
  }, [
    getTechnicalCartQueryResults.data?.getCommunicorBasket?.pricelistId?.technicalLineIds, 
    getTechnicalCartQueryResults.error, 
    getTechnicalCartQueryResults.loading
  ]);

  const [createTechnicalLineMutation] = useCreateTechnicalLineMutation();

  const validateCart = async () => {
    try {
      await Promise.all(Object.entries(mgQuantities).map(async (value) => {
        const id = parseInt(value[0]);
        const quantity = value[1];
        if (quantity > 0) {
          const priceLine = meetAndGreets.find((mg) => mg.id === id);
          if (priceLine) {
            await createTechnicalLineMutation({
              variables: {
                templateId: id,
                basketId: parseInt(basketId),
                quantity: quantity
              }
            });
          }
        }
      }));

      await Promise.all(Object.entries(serviceQuantities).map(async (value) => {
        const id = parseInt(value[0]);
        const quantity = value[1];
        if (quantity > 0) {
          const priceLine = services.find((service) => service.id === id);
          if (priceLine) {
            await createTechnicalLineMutation({
              variables: {
                templateId: id,
                basketId: parseInt(basketId),
                quantity: quantity
              }
            });
          }
        }
      }));

      history.push(`/eventBasket/${basketId}`);
    } catch (err) {
      console.error(err);
    }
    
  };

  const updateMG = (id: number, value: number) => {
    if (value >= 0) {
      setMGQuantities({ ...mgQuantities, [id]: value });
    } else {
      setMGQuantities({ ...mgQuantities, [id]: 0 });
    }
  };

  const updateService = (id: number, value: number) => {
    if (value >= 0) {
      setServicesQuantities({ ...serviceQuantities, [id]: value });
    } else {
      setServicesQuantities({ ...serviceQuantities, [id]: 0 });
    }
  };

  const mgCartLines = computeMGCartLine();
  const serviceCartLines = computeServiceCartLine();

  return (
    <PageLayout
      navigation
      title={'Technical Support'}
      backButton
    >
      <RequestHandling {...getTechnicalCartQueryResults}>
        {
          cart =>
            <GridContainerWithPadding container spacing={3}>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <Typography variant="h1">
                    {'Technical Support'}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.space}>
                  <Typography variant="h2">
                    {'Meet & Greets'}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.mg}>
                  {
                    meetAndGreets.map((mg) => {
                      return (
                        <Grid item xs={12} key={mg.id} className={classes.type}>
                          <Grid container spacing={1}>
                            <Grid item xs={8} className={classes.verticalAlign}>
                              <span className={classes.typeName}>
                                {mg?.mgType?.name}:
                              </span>
                            </Grid>
                            <Grid item xs={4} className={classes.verticalAlign}>
                              <TextField
                                value={mgQuantities[mg.id].toString().replace(/^0+/, '') || '0'}
                                variant="outlined"
                                type="number"
                                className={classes.typeQuantity}
                                fullWidth
                                onChange={(event) => updateMG(mg.id, parseInt(event.target.value))}
                                InputProps={{
                                  className: classes.typeQuantity,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })
                  }
                </Grid>
                {
                  services.length > 0 && (
                    <>
                      <Grid item xs={12} className={classes.space}>
                        <Typography variant="h2">
                          {'Other services'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.mg}>
                        {
                          services.map((service) => {
                            return (
                              <Grid item xs={12} key={service.id} className={classes.type}>
                                <Grid container spacing={1}>
                                  <Grid item xs={8} className={classes.verticalAlign}>
                                    <span className={classes.typeName}>
                                      {service.name}:
                                    </span>
                                  </Grid>
                                  <Grid item xs={4} className={classes.verticalAlign}>
                                    <TextField
                                      value={serviceQuantities[service.id].toString().replace(/^0+/, '') || '0'}
                                      variant="outlined"
                                      type="number"
                                      className={classes.typeQuantity}
                                      fullWidth
                                      onChange={(event) => updateService(service.id, parseInt(event.target.value))}
                                      InputProps={{
                                        className: classes.typeQuantity,
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            );
                          })
                        }
                      </Grid>
                    </>
                  )
                }
              </Grid>
              {
                (mgCartLines.length > 0 || serviceCartLines.length > 0) && (
                  <Grid item xs={6} className={classes.stickyColumn}>
                    <Grid item xs={12}>
                    
                      <div className={classes.summary}>
                        {
                          mgCartLines.length > 0 && (
                            <>
                              <Typography variant="h3">{'Meet & Greets'}</Typography>
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell>Description</StyledTableCell>
                                      <StyledTableCell align="right" style={{ width: '20%' }}>Qty.</StyledTableCell>
                                      <StyledTableCell align="right" style={{ width: '20%' }}>Price</StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {
                                      mgCartLines.map((mg) => {
                                        return (
                                          <React.Fragment key={mg.id}>
                                            <TableRow>
                                              <StyledTableCell>{mg.description}</StyledTableCell>
                                              <StyledTableCell align="right" style={{ width: '20%' }}>{mg.quantity}</StyledTableCell>
                                              <StyledTableCell align="right" style={{ width: '25%' }}>{formatPrice(mg.price, cart.getCommunicorBasket?.pricelistId?.currency?.symbol || '€')}</StyledTableCell>
                                            </TableRow>
                                          </React.Fragment>
                                        );
                                      })
                                    }
                                    <TableRow>
                                      <StyledTableCell align="right" style={{ border: 'none' }}></StyledTableCell>
                                      <StyledTableCell align="right" style={{ width: '20%', border: 'none' }}>Sub-Total</StyledTableCell>
                                      <StyledTableCell align="right" style={{ width: '25%', border: 'none' }}>
                                        {formatPrice(totalMGPrice, cart.getCommunicorBasket?.pricelistId?.currency?.symbol || '€')}
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </>
                          )
                        }

                        {
                          serviceCartLines.length > 0 && (
                            <>
                              <Typography variant="h3">Additional services</Typography>
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell>Description</StyledTableCell>
                                      <StyledTableCell align="right" style={{ width: '20%' }}>Qty.</StyledTableCell>
                                      <StyledTableCell align="right" style={{ width: '20%' }}>Price</StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {
                                      serviceCartLines.map((service) => {
                                        return (
                                          <React.Fragment key={service.id}>
                                            <TableRow>
                                              <StyledTableCell>{service.description}</StyledTableCell>
                                              <StyledTableCell align="right" style={{ width: '20%' }}>{service.quantity}</StyledTableCell>
                                              <StyledTableCell align="right" style={{ width: '25%' }}>{formatPrice(service.price, cart.getCommunicorBasket?.pricelistId?.currency?.symbol || '€')}</StyledTableCell>
                                            </TableRow>
                                          </React.Fragment>
                                        );
                                      })
                                    }
                                    <TableRow>
                                      <StyledTableCell align="right" style={{ border: 'none' }}></StyledTableCell>
                                      <StyledTableCell align="right" style={{ width: '20%', border: 'none' }}>Sub-Total</StyledTableCell>
                                      <StyledTableCell align="right" style={{ width: '25%', border: 'none' }}>
                                        {formatPrice(totalServicesPrice, cart.getCommunicorBasket?.pricelistId?.currency?.symbol || '€')}
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </>
                          )
                        }
                        <Grid item xs={12} style={{ textAlign: 'end' }}>
                          <PriceDisplay
                            price={totalServicesPrice + totalMGPrice}
                            pricelist={cart.getCommunicorBasket?.pricelistId}
                          />
                        </Grid>
                      </div>
                      
                    </Grid>
                    <Grid item xs={12} className={classes.validateCart}>
                      <Button onClick={() => validateCart()} variant="contained" size="large">
                        Validate
                      </Button>
                    </Grid>
                  </Grid>
                )
              }
            </GridContainerWithPadding>
        }
      </RequestHandling>
    </PageLayout>
  );
};

export default TechnicalSupports;