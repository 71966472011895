import { makeStyles } from '@material-ui/core';

const usePMEventListStyles = makeStyles({
  table: {
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  firstLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  container: {
    paddingLeft: '100px',
    paddingRight: '100px'
  }
});

export default usePMEventListStyles;