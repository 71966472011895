import PageLayout from 'layout/PageLayout/PageLayout';
import { useEffect, useState } from 'react';
import { getStaticContents } from 'utils/getStaticContents';
import useStaticContentStyles from './StaticContentsStyles';

const PrivacyPolicy: React.FC = () => {

  const classes = useStaticContentStyles();

  const [textToDisplay, setTextToDisplay] = useState();

  useEffect(() => {
    getStaticContents().then(body => {
      if (body.result.privacy !== undefined) {
        if (body.result.privacy === false) {
          //@ts-ignore
          setTextToDisplay('<div>The Privacy Policy has not yet been initialized</div>');
        } else {
          setTextToDisplay(body.result.privacy);
        }
      }
    });
  }, []);

  return (
    <PageLayout
      backButton
      navigation
      title="Privacy Policy"
    >

      <div
        className={classes.text}
        dangerouslySetInnerHTML={{
          //@ts-ignore
          __html: textToDisplay
        }}
      />

    </PageLayout>
  );
};

export default PrivacyPolicy;