/* Framework imports ----------------------------------- */
import { Grid, TextField } from '@material-ui/core';
import CustomRadio from 'pages/MyPassengers/PassengerDetails/CustomRadio/CustomRadio';
import React from 'react';
import { Passenger } from 'type/Passenger';

/* Module imports -------------------------------------- */

/* Component imports ----------------------------------- */

/* AddPassengerForm component prop types -------------------- */
interface AddPassengerFormProps {
  formValues: Passenger;
  setFormValues: (values: Passenger) => void;
  fullWidth?: boolean;
}

/* AddPassengerForm component ------------------------------- */
const AddPassengerForm: React.FC<AddPassengerFormProps> = ({ formValues, setFormValues, fullWidth = false }) => {
  return (
    <Grid container spacing={2}>
      <CustomRadio
        editable={true}
        onChange={(value) => setFormValues({ ...formValues, isGroup: value === 'individual' ? false : true })}
        value={formValues.isGroup ? 'group' : 'individual'}
        label="Group type"
      />
      {formValues.isGroup &&
        <>
          <TextField
            id="groupName"
            label="Group name"
            value={formValues.groupName}
            onChange={(event) => setFormValues({ ...formValues, groupName: event.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            id="number"
            label="Passengers number"
            value={formValues.groupSize}
            onChange={(event) => setFormValues({ ...formValues, groupSize: parseInt(event.target.value) })}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: 2
              }
            }}
            variant="outlined"
            fullWidth
            type="number"
            required
          />
        </>
      }
      <TextField
        id="name"
        label={`${formValues.isGroup ? 'Point of contact\'s last name' : 'Passenger\'s last name'}`}
        value={formValues.name}
        onChange={(event) => setFormValues({ ...formValues, name: event.target.value })}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        fullWidth
        required
      />
      <TextField
        id="firstName"
        label={`${formValues.isGroup ? 'Point of contact\'s first name' : 'Passenger\'s first name'}`}
        value={formValues.firstName}
        onChange={(event) => setFormValues({ ...formValues, firstName: event.target.value })}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        fullWidth
        required
      />
      <TextField
        id="mail"
        label={`${formValues.isGroup ? 'Point of contact\'s mail' : 'Passenger\'s mail'}`}
        value={formValues.mail}
        onChange={(event) => setFormValues({ ...formValues, mail: event.target.value })}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        fullWidth
        required
      />
      <TextField
        id="phoneNumber"
        label={`${formValues.isGroup ? 'Point of contact\'s phone number' : 'Passenger\'s phone number'}`}
        value={formValues.phoneNumber}
        onChange={(event) => setFormValues({ ...formValues, phoneNumber: event.target.value })}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        fullWidth
        required
      />
    </Grid>
  );
};

/* Export AddPassengerForm component ------------------------ */
export default AddPassengerForm;
