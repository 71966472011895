import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { MenuItem } from '@material-ui/core';
import PageLayout from 'layout/PageLayout/PageLayout';
import useAddBookingStyles from './IBAddBookingStyles';
import RequestHandling from 'components/RequestHandling';
import { useCreateBasketMutation } from 'apollo/mutations';
import { useHistory } from 'react-router';
import useUserId from 'utils/userId';
import compile from 'utils/domain';
import * as Luq from 'luq';
import { useFindIbEventInvitationsQuery } from 'apollo/queries';
import usePricelist from 'hooks/usePriceList';

const IBAddBooking: React.FC = () => {

  const classes = useAddBookingStyles();
  const history = useHistory();
  const userId = useUserId();

  const [selectedEvent, setSelectedEvent] = useState<string>('');

  const eventListQueryResult = useFindIbEventInvitationsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: compile(
        <Luq.Eq field="individual_booker_id" value={userId}></Luq.Eq>
      )
    }
  });

  const [createBasketMutation] = useCreateBasketMutation();

  const pricelist = usePricelist(parseInt(selectedEvent));

  const handleValidate = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (!eventListQueryResult.loading && !eventListQueryResult.error && eventListQueryResult.data) {
      const eventInvitation = eventListQueryResult.data.searchCommunicorEventInvitation.find((e) => e.eventId.id === parseInt(selectedEvent));
      if (eventInvitation && userId && pricelist.data) {
        try {
          const basket = await createBasketMutation({
            variables: {
              eventId: eventInvitation.eventId.id,
              userId: userId,
              pricelist: pricelist.data.id
            }
          });
          if (basket.data?.createCommunicorBasket?.created?.id) {
            history.push(`/eventBasket/${basket.data.createCommunicorBasket.created.id}`);
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  return (
    <PageLayout
      title="Add a booking"
      navigation
      backButton
    >
      <RequestHandling {...eventListQueryResult}>
        {
          eventList => (
            <div className={classes.paper}>
              <Typography variant="h1" className={classes.margin}>
                Choose A Show Or Event
              </Typography>
              <form className={classes.form} noValidate>
                <TextField
                  required
                  id="event"
                  label="Show / Event"
                  value={selectedEvent}
                  onChange={(event) => setSelectedEvent(event.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  select
                  className={classes.margin}
                  fullWidth
                >
                  {
                    eventList.searchCommunicorEventInvitation.map((event) => {
                      return <MenuItem key={event.eventId.id} value={event.eventId.id}>{event.eventId.name}</MenuItem>;
                    })
                  }
                </TextField>
                {
                  selectedEvent !== '' && pricelist.loading === false && pricelist.data === null && (
                    <>
                      <Typography>
                      There is no pricelist for your division or company yet.
                      </Typography>
                      <Typography className={classes.margin}>
                      Please contact your master account or Communicor account manager.
                      </Typography>
                    </>
                    
                  )
                }
                <Button
                  onClick={handleValidate}
                  variant="contained"
                  size="large"
                  disabled={pricelist.data === null}
                >
                  Validate
                </Button>
              </form>
            </div>
          )
        }
      </RequestHandling>

    </PageLayout>
  );
};

export default IBAddBooking;
