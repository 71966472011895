import { ApolloError } from '@apollo/client';
import Error from 'components/Error.js';
import Loader from 'components/Loader/Loader';
import React, { PropsWithChildren } from 'react';

interface Props<T> {
  data: T;
  loading: Boolean;
  error?: ApolloError | null;
  children: (arg: NonNullable<T>) => JSX.Element;
}

const RequestHandling = <T,>({ data, loading, error, children }: PropsWithChildren<Props<T>>): React.ReactElement<any, any> | null => {
  if (loading) return <Loader />;
  if (error) return <Error />;

  if (data === null || data === undefined) {
    return <Error />;
  } else {
    return children(data as NonNullable<T>);
  }
};

export default RequestHandling;