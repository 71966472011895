import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import useFormControlStyles from '../FormControlStyles';

interface Props {
  password: string;
  setPassword: (value: string) => void;
  confirmPassword: string;
  setConfirmPassword: (value: string) => void;
};

const CreatePassword: React.FC<Props> = ({ password, setPassword, confirmPassword, setConfirmPassword }) => {

  const classes = useFormControlStyles();

  const [confirmPasswordWritten, setConfirmPasswordWritten] = useState<boolean>(false);

  useEffect(() => {
    let cancelled = false;

    setTimeout(() => {
      if (!cancelled && !confirmPasswordWritten) {
        if (confirmPassword !== '') {
          setConfirmPasswordWritten(true);
        }
      }
    }, 500);

    return () => {cancelled = true;};
  }, [confirmPasswordWritten, confirmPassword]);

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          required
          id="password"
          label="Password"
          defaultValue={password}
          onChange={(event) => setPassword(event.target.value)}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          type="password"
        />
      </FormControl>
      <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          required
          id="confirm-password"
          label="Confirm password"
          defaultValue={confirmPassword}
          onChange={(event) => setConfirmPassword(event.target.value)}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          type="password"
        />
        {confirmPasswordWritten && confirmPassword !== password && <FormHelperText color="secondary" id="component-error-text">Incorrect entry</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default CreatePassword;