import { AppBar, BottomNavigation, BottomNavigationAction, Button, Container, Grid, Hidden, Toolbar, Typography } from '@material-ui/core';
import { logout } from 'utils/authentication';
import usePageLayoutStyles from './PageLayoutStyle';
import setLoggedIn from 'store/loggedIn';
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/client';
import { Link } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
  title?: string;
  navigation?: boolean;
  backButton?: boolean | string;
  footer?: boolean;
}

const PageLayout: React.FC<Props> = ({ children, title, navigation, backButton, footer = true }) => {

  const classes = usePageLayoutStyles();
  const history = useHistory();
  const client = useApolloClient();

  const tryLogout = () => {
    logout()
      .then(() => {
        client.resetStore();
        setLoggedIn(false);
        history.push('/login');
      });
  };

  return (
    <Container component="main">
      <AppBar className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} sm={1} className={classes.alignItem}>
              {backButton !== undefined && (
                <Button variant="text" style={{ position: 'absolute', top: '15px', left: '5px' }} onClick={() => (typeof backButton === 'string') ? history.push(backButton) : history.goBack()}> Go Back </Button>
              )}
            </Grid>
            <Hidden xsDown>
              <Grid item sm={4} md={4} lg={3} className={classes.alignItem}>
                {title && (
                  <Typography className={classes.pageTitle}>{title}</Typography>
                )
                }
              </Grid>
            </Hidden>
           
            <Grid item xs={5} sm={3} className={classes.alignItem}>
              <Link to="/">
                <img src="/assets/logo/CommunicorLogoWB.png" alt="Communicor" className={classes.logo} />
              </Link>
            </Grid>
            <Grid item xs={5} sm={4} className={classes.alignItem}>
              {navigation && (
                <div className={classes.navigation}>
                  <div className={classes.navItem} onClick={() => history.push('/')}>Home</div>
                  <div className={classes.navSeparator}>-</div>
                  <div className={classes.navItem} onClick={() => history.push('/passengers')}>My Passengers</div>
                  <div className={classes.navSeparator}>-</div>
                  <div className={classes.navItem} onClick={() => history.push('/profile')}>Profile</div>
                  <div className={classes.navSeparator}>-</div>
                  <div className={classes.navItem} onClick={tryLogout}>Log out</div>
                </div>
              )}
            </Grid>
            <Hidden smUp>
              <Grid item xs={12} className={classes.alignItem}>
                {title && (
                  <Typography className={classes.pageTitle}>{title}</Typography>
                )
                }
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.mainContent}>
        {children}
      </div>
      {
        footer && 
        <BottomNavigation className={classes.footer} showLabels={true} >
          <BottomNavigationAction label="Privacy Policy" onClick={() => history.push('/privacyPolicy')} />
          <BottomNavigationAction label="Terms of Use" onClick={() => history.push('/termsOfUse')} />
          <BottomNavigationAction label="Legal" onClick={() => history.push('/legal')} />
        </BottomNavigation>
      }
    </Container>
  );
};

export default PageLayout;