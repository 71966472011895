import { makeStyles } from '@material-ui/core';

const usePMEventDetailsStyles = makeStyles({
  booking: {
    borderBottom: '1px solid black',
    marginBottom: '10px',
    textAlign: 'left',
    padding: '10px 0',
    width: '400px'
  },
  firstLine: {
    display: 'flex'
  },
  title: {
    alignSelf: 'center',
    borderBottom: '1px solid #CED4DA',
    padding: '0 !important',
    paddingBottom: '20px !important'
  },
  noPadding: {
    padding: '0px'
  },
  emptyText: {
    fontSize: '20px',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    color: '#CED4DA'
  },
  addButton: {
    marginTop: '20px'
  },
  eventDivider: {
    width: '460px',
    float: 'left',
    marginTop: '15px',
    borderColor: '#CED4DA',
    border: 'unset',
    borderBottom: '1px solid #CED4DA'
  },
  eventTitle: {
    padding: '30px !important',
  },
  eventName: {
    fontWeight: 500,
    fontSize: '40px',
    fontFamily: 'Karla',
  },
  stepLabel: {
    fontWeight: 200,
    fontSize: '22px',
    fontFamily: 'Montserrat',
  },
});

export default usePMEventDetailsStyles;