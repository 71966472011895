export const formatPrice = (n: number, curr: string): string => {
  if (n === 0) {
    return `0.00 ${curr}`;
  }

  let rest = n;
  let neg = n < 0;
  if (neg) {
    rest = -n;
  }

  let decimal = rest % 1;
  rest = rest - decimal;
  decimal = Math.round(decimal * 100);

  let byThousand = [];
  while (rest > 0) {
    byThousand.push(rest % 1000);
    rest = (rest - rest % 1000) / 1000;
  }
  byThousand = byThousand.reverse();

  return (neg ? '-' : '') + byThousand.map((n, i) => i === 0 ? n : ('00' + n).slice(-3)).join(',') + '.' + ('0' + decimal).slice(-2) + ' ' + curr;
};