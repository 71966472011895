import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useGetCountryInformationsQuery, useGetDivisionInformationsQuery, useGetMyEmailQuery } from 'apollo/queries';
import { CompanyState } from 'type/Company';
import { InvoiceAddress } from 'type/InvoiceAddress';
import { User } from 'type/User';

interface Props {
  password: string;
  user: User;
  company: CompanyState;
  invoiceAddress: InvoiceAddress;
};

const useStyles = makeStyles({
  container: {
    textAlign: 'left',
    paddingLeft: '50px'
  }
});

const SignUpSummary: React.FC<Props> = ({ password, user, company, invoiceAddress }) => {

  const classes = useStyles();

  const { data } = useGetMyEmailQuery();

  const { data : getCountryData } = useGetCountryInformationsQuery({
    variables: {
      id: parseInt(invoiceAddress.country)
    },
    skip: invoiceAddress.country === '',
  });

  const { data: getDivisionInformations } = useGetDivisionInformationsQuery({
    variables: {
      id: parseInt(company.division)
    },
    skip: company.division === '' || company.division === 'add'
  });

  return (
    <Grid container className={classes.container} spacing={1}>
      <Grid item xs={6}>
        <Typography variant="h2">
          Connexion information
        </Typography>
        <br />
        <Typography variant="h4">
          Email address
        </Typography>
        <Typography>
          {data?.getMyData?.email}
        </Typography>
        <br />
        <Typography variant="h2">
          Personal information
        </Typography>
        <br />
        <Typography variant="h4">
          First name
        </Typography>
        <Typography>
          {user.firstName}
        </Typography>
        <br />
        <Typography variant="h4">
          Surname
        </Typography>
        <Typography>
          {user.lastName}
        </Typography>
        <br />
        <Typography variant="h4">
          Gender
        </Typography>
        <Typography>
          {user.gender}
        </Typography>
        <br />
        <Typography variant="h4">
          Mobile phone
        </Typography>
        <Typography>
          {user.mobilePhone}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h2">
          Company information
        </Typography>
        <br />
        <Typography variant="h4">
          Company name
        </Typography>
        <Typography>
          {company.name}
        </Typography>
        <br />
        <Typography variant="h4">
          Invoice address
        </Typography>
        {company.division === 'add' ?
          <>
            <Typography>
              {invoiceAddress.address1}
            </Typography>
            <Typography>
              {invoiceAddress.address2}
            </Typography>
            <Typography>
              {invoiceAddress.address3}
            </Typography>
            <Typography>
              {invoiceAddress.address4}
            </Typography>
            <br />
            <Typography variant="h4">
              Post code
            </Typography>
            <Typography>
              {invoiceAddress.zipCode}
            </Typography>
            <br />
            <Typography variant="h4">
              City
            </Typography>
            <Typography>
              {invoiceAddress.city}
            </Typography>
            <br />
            <Typography variant="h4">
              Country
            </Typography>
            <Typography>
              {getCountryData?.getResCountry?.name}
            </Typography>
            <br />
          </>
          :
          <>
            <Typography>
              {getDivisionInformations?.getResPartner?.street}
            </Typography>
            <Typography>
              {getDivisionInformations?.getResPartner?.street2}
            </Typography>
            <br />
            <Typography variant="h4">
              Post code
            </Typography>
            <Typography>
              {getDivisionInformations?.getResPartner?.zip}
            </Typography>
            <br />
            <Typography variant="h4">
              City
            </Typography>
            <Typography>
              {getDivisionInformations?.getResPartner?.city}
            </Typography>
            <br />
            <Typography variant="h4">
              Country
            </Typography>
            <Typography>
              {getDivisionInformations?.getResPartner?.countryId?.name}
            </Typography>
            <br />
          </>
        }
      </Grid>
    </Grid>
  );
};

export default SignUpSummary;