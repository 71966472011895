import { FormControl, MenuItem, TextField } from '@material-ui/core';
import { User } from 'type/User';
import useFormControlStyles from '../FormControlStyles';
import { useFindUserTitleQuery } from 'apollo/queries';

interface Props {
  user: User;
  setUser: (user: User) => void;
  edit?: boolean
}

const PersonalInformation: React.FC<Props> = ({ user, setUser, edit }) => {

  const classes = useFormControlStyles();

  const { data } = useFindUserTitleQuery();

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          required
          id="firstname"
          label="First Name"
          defaultValue={edit ? null : user.firstName}
          value={edit ? user.firstName : null}
          onChange={(event) => setUser({ ...user, firstName: event.target.value })}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
      <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          required
          id="lastname"
          label="Last Name"
          defaultValue={edit ? null : user.lastName}
          value={edit ? user.lastName : null}
          onChange={(event) => setUser({ ...user, lastName: event.target.value })}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
      <FormControl component="fieldset" className={classes.formControl} style={{ textAlign: 'left' }}>
        <TextField
          required
          id="gender"
          label="Gender"
          value={user.gender ? parseInt(user.gender) : null}
          onChange={(event) => {
            setUser({ ...user, gender: event.target.value });
          }
          }
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          select
          fullWidth
        >
          {
            data?.searchResPartnerTitle.map((title) => {
              return <MenuItem key={title.id} value={title.id}>{title.name}</MenuItem>;
            })
          }
        </TextField>
      </FormControl>
      <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          required
          id="phone"
          label="Mobile phone (With Country Extension Number)"
          defaultValue={edit ? null : user.mobilePhone}
          value={edit ? user.mobilePhone : null}
          onChange={(event) => setUser({ ...user, mobilePhone: event.target.value })}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
    </div>
  );
};

export default PersonalInformation;