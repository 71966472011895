import { Card, CardContent, Grid, IconButton } from '@material-ui/core';
import { DeleteForever, Edit } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import useCartItemStyles from './CartItemStyle';

const DeleteIconButton = withStyles({
  root: {
    fontSize: '14px',
    backgroundColor: '#495057',
    borderRadius: '5px',
    height: '38px',
    width: '38px',
    color: '#CED4DA',
    '&:hover': {
      backgroundColor: '#3E444A',
    }
  }
})(IconButton);

const EditIconButton = withStyles({
  root: {
    fontSize: '14px',
    backgroundColor: '#CED4DA',
    borderRadius: '5px',
    height: '38px',
    width: '38px',
    color: '#212529',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#ADB2B9',
    }
  }
})(IconButton);

export interface CartItemProps {
  children: React.ReactNode;
  title: string;
  price: string;
  canDelete?: boolean;
  canEdit?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
}

const CartItem: React.FC<CartItemProps> = ({ 
  children, 
  title, 
  price, 
  canDelete = true, 
  canEdit = true, 
  onDelete,
  onEdit
}) => {

  const classes = useCartItemStyles();

  return (
    <Card>
      <CardContent>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Grid container className={classes.container}>
              <Grid item xs={9} className={classes.title}>
                { title }
              </Grid>
              <Grid item xs={3} className={classes.buttons}>
                {
                  canDelete && onDelete && (
                    <DeleteIconButton aria-label="delete" color="primary" onClick={() => onDelete()}>
                      <DeleteForever />
                    </DeleteIconButton>
                  )
                }
                {
                  canEdit && onEdit && (
                    <EditIconButton aria-label="delete" color="primary" onClick={() => onEdit()}>
                      <Edit />
                    </EditIconButton>
                  )
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.container}>
              <Grid item xs={9}>
                <Grid container className={classes.container}>
                  { children }
                </Grid>
              </Grid>
              <Grid item xs={3} className={classes.price}>
                { price }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CartItem;