import { createTheme } from '@material-ui/core';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#CED4DA',
    },
    background: {
      default: '#171A1D',
    },
    text: {
      primary: '#ffffff',
      secondary: '#CED4DA',
    },
  },
  typography: {
    fontFamily: [
      'Karla'
    ].join(', '),
    h1: {
      fontSize: '36px',
      fontFamily: 'Karla',
      fontWeight: 700
    },
    h2: {
      fontSize: '26px',
      fontFamily: 'Karla',
      fontWeight: 700,
      color: '#CED4DA'
    },
    h3: {
      fontSize: '20px',
      fontWeight: 400,
      color: '#CED4DA'
    },
    h4: {
      fontFamily: 'Montserrat',
      fontWeight: 300,
      color: '#CED4DA',
      fontSize: '16px'
    },
    h5: {
      fontSize: '24px',
      fontFamily: 'Karla',
      fontWeight: 700
    },
    h6: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      color: '#CED4DA',
      fontSize: '18px'
    },
  },
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#B7BCC2',
        opacity: 1,
        borderRadius: '10px',
        fontSize: '20px',
        padding: '0px',
      },
    },
    MuiInputLabel: {
      outlined: {
        fontSize: '18px',
        fontWeight: 500,
        fontFamily: 'Montserrat',
        transform: 'translate(6px, -6px) scale(0.75) !important'
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: '20px'
      }
    },
    MuiButton: {
      contained: {
        fontSize: '18px',
        fontWeight: 600,
        fontFamily: 'Montserrat',
        minWidth: '154px',
        minHeight: '60px',
        borderRadius: '10px',
        marginRight: '10px',
        '&.Mui-disabled': {
          backgroundColor: 'grey'
        }
      },
      root: {
        textTransform: 'none'
      }
    },
    MuiFormLabel: {
      root: {
        fontWeight: 400,
        fontSize: '18px',
        fontFamily: 'Montserrat'
      }
    },
    MuiPopover: {
      paper: {
        background: '#171A1D',
      }
    },
    MuiSelect: {
      select: {
        textAlign: 'left',
      },
      icon: {
        color: 'white'
      }
    },
    MuiTableCell: {
      head: {
        borderColor: 'rgba(108, 117, 125, 1)',
        fontSize: '18px',
        fontFamily: 'Montserrat',
        fontWeight: 700,
        color: 'rgba(206, 212, 218, 1)'
      },
      body: {
        borderColor: 'rgba(206, 212, 218, 0.2)'
      }
    },
    MuiDialog: {
      paper: {
        backgroundColor: 'black',
      },
    },
    MuiDataGrid: {
      root: {
        borderColor: '#41474D',
        overflowX: 'hidden'
      },
    },
    MuiCard: {
      root: {
        textAlign: 'left',
        padding: '10px',
        backgroundColor: '#212529',
        color: 'white',
        borderRadius: '10px'
      }
    },
    MuiMenu: {
      paper: {
        marginTop: '43px',
        borderRadius: '0px 0px 5px 5px',
        borderColor: '#ffffff30 white white',
        borderStyle: 'solid',
        borderWidth: '1px 2px 2px 2px',
      }
    },
    MuiInputAdornment: {
      root: {
        color: 'white'
      }
    }
  }
});