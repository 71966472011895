import { MenuItem, TextField, Grid } from '@material-ui/core';
import { DivisionInformationsFragment } from 'apollo/queries/types';
import { CompanyState } from 'type/Company';
import { DivisionState } from 'type/Division';

interface Props {
  company: CompanyState;
  setCompany: (company: CompanyState) => void;
  newDivision: DivisionState;
  setNewDivision: (division: DivisionState) => void;
  companyDivisions: DivisionInformationsFragment[];
};

const CompanyInformation: React.FC<Props> = ({ company, setCompany, newDivision, setNewDivision, companyDivisions }) => {

  return (
    <>
      <Grid item xs={12}>
        <TextField
          required
          id="company-name"
          label="Company Name"
          defaultValue={company.name}
          onChange={(event) => setCompany({ ...company, name: event.target.value })}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          InputProps={{ readOnly: true }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id="division"
          label="Division"
          value={company.division}
          onChange={(event) => {
            const division = companyDivisions.find((division) => division.id === parseInt(event.target.value));
            const vat = division?.vat;
            if (event.target.value === 'add') {
              setNewDivision({ name: '', vatNumber: '' });
            }
            setCompany({
              ...company,
              division: event.target.value,
              vatNumber: event.target.value !== 'add' ?
                vat ?
                  vat
                  : ''
                : ''
            });
          }
          }
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          select
          fullWidth
        >
          {
            companyDivisions.map((division) => {
              return <MenuItem key={division.id} value={division.id}>{division.name}</MenuItem>;
            })
          }
          <MenuItem value="add">Add division</MenuItem>
        </TextField>
      </Grid>
      {
        company.division === 'add' && (
          <Grid item xs={12}>
            <TextField
              required
              id="division-name"
              label="Division Name"
              value={newDivision.name}
              onChange={(event) => setNewDivision({ ...newDivision, name: event.target.value })}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
        )
      }
      <Grid item xs={12}>
        <TextField
          required
          id="vat"
          label="Vat Number"
          value={company.division === 'add' ? newDivision.vatNumber : company.vatNumber}
          onChange={(event) => {
            if (company.division === 'add') {
              setNewDivision({ ...newDivision, vatNumber: event.target.value });
            }
          }}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          InputProps={{ readOnly: company.division !== 'add' }}
          fullWidth
        />
      </Grid>
    </>
  );
};

export default CompanyInformation;