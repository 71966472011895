import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import PageLayout from 'layout/PageLayout/PageLayout';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles({
  alignCenter: {
    textAlign: 'center',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  bottomButton: {
    bottom: '70px',
    textAlign: 'center',
  },
  fullHeight: {
    minHeight: '100%',
  },
  container: {
    minHeight: 'calc(100vh - 210px)',
  },
  step: {
    paddingTop: '50px !important',
  },
  contentPadding: {
    padding: '15px'
  },
  stretchTop: {
    paddingTop: '75px !important'
  }
});
  
interface StepperLayoutProps {
  children: React.ReactNode;
  stepName: string;
  contentSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  currentStep?: number;
  maxStep?: number;
  align?: 'stretch' | 'center';
  canPrevious?: boolean;
  canNext?: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  isLastStep?: boolean;
  controlAdorned?: React.ReactNode;
  disabled?: boolean;
  errorChild?: React.ReactNode;
}

const StepperLayout: React.FC<StepperLayoutProps> = ({ 
  children, 
  stepName, 
  contentSize = 6, 
  currentStep, 
  maxStep, 
  align = 'center', 
  canPrevious = true, 
  canNext = true, 
  onNext, onPrevious,
  isLastStep = false,
  controlAdorned,
  disabled = false,
  errorChild,
}) => {
  
  const classes = useStyles();

  return (
    <PageLayout>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={3} className={classes.step}>
          <Typography variant="h1">
            { stepName }
          </Typography>
          {
            currentStep && maxStep && (
              <Typography variant="h3">
                Step: { currentStep }/{ maxStep }
              </Typography>
            )
          }
        </Grid>
        <Grid item xs={contentSize} alignItems={align} className={clsx(classes.fullHeight, align === 'center' ? classes.alignCenter : '', align === 'stretch' ? classes.stretchTop : '')}>
          <div>
            <Grid container spacing={2} className={classes.contentPadding}>
              { children }
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.bottomButton} alignItems="center" alignContent="center">
        <Grid item xs={12} alignContent="center">
          { canPrevious && onPrevious && <Button onClick={() => onPrevious()} variant="contained" size="large">Previous</Button> }
          { canNext && onNext && <Button onClick={() => onNext()} variant="contained" size="large" disabled={disabled}>{ isLastStep ? 'Validate' : 'Next' }</Button> }
          { errorChild }
          { controlAdorned !== undefined && controlAdorned }
        </Grid>
      </Grid>
    </PageLayout>
  );
};


export default StepperLayout;