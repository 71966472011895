import { makeStyles } from '@material-ui/core';

const useInvitationViewStyles = makeStyles({
  button: {
    backgroundColor: 'white',
    color: 'black'
  },
  container: {
    placeContent: 'center',
    textAlign: 'center',
    paddingTop: '80px'
  },
  margin: {
    marginBottom: '30px'
  },
  caption: {
    fontSize: '18px',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    color: '#CED4DA'
  },
  option: {
    height: '90px'
  }
});


export default useInvitationViewStyles;