import { Grid, Typography } from '@material-ui/core';
import { useFindIbReservationsQuery, useGetIbInformationsQuery } from 'apollo/queries';
import { BasketFieldsLightFragment } from 'apollo/queries/types';
import BookingCard from 'components/BookingCard/BookingCard';
import GridContainerWithPadding from 'components/GridContainerWithPadding/GridContainerWithPadding';
import RequestHandling from 'components/RequestHandling';
import PageLayout from 'layout/PageLayout/PageLayout';
import { useParams } from 'react-router-dom';
import compile from 'utils/domain';
import * as Luq from '../../luq';
import usePMIBReservationsStyles from './PMIBReservationsStyles';

const PMIBReservations: React.FC = () => {

  const classes = usePMIBReservationsStyles();

  const { individualId, eventId } = useParams<{ individualId: string, eventId: string }>();

  const getIbInformationQueryResults = useGetIbInformationsQuery({
    variables: {
      id: parseInt(individualId)
    },
    skip: individualId === undefined
  });

  const findIbReservationQueryResults = useFindIbReservationsQuery({
    variables: {
      filter: compile(
        <Luq.And>
          <Luq.Eq field="eventId" value={parseInt(eventId)} />
          <Luq.Eq field="userId" value={parseInt(individualId)} />
        </Luq.And>
      )
    },
    skip: individualId === undefined || eventId === undefined
  });

  return (
    <PageLayout
      navigation
      title={getIbInformationQueryResults.data?.getResUsers?.name ? getIbInformationQueryResults.data?.getResUsers.name : ''}
      backButton
    >
      <RequestHandling {...getIbInformationQueryResults}>
        {
          individual => (
            <GridContainerWithPadding container spacing={9}>
              <Grid item xs={12} className={classes.pageTitle}>
                <Typography className={classes.individualName}>
                  {individual.getResUsers?.name}
                </Typography>
                <hr className={classes.individualDivider} />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.title}>
                    <Typography variant="h2">
                      Booking
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3} className={classes.noPadding}>
                      <RequestHandling {...findIbReservationQueryResults}>
                        {
                          baskets => (
                            <>
                              { baskets.searchCommunicorBasket.map((basket: BasketFieldsLightFragment) => {
                                return (
                                  <Grid item xs={6} sm={6} md={6} lg={4} key={basket.id}>
                                    <BookingCard key={basket.id} booking={basket} isMasterAccount={true} />
                                  </Grid>
                                );
                              })}
                            </> 
                          )
                        }
                      </RequestHandling>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </GridContainerWithPadding>
          )
        }
      </RequestHandling>
    </PageLayout>
  );
};


export default PMIBReservations;