import { Button, Grid, Typography } from '@material-ui/core';
import { useGetEventNameQuery } from 'apollo/queries';
import GridContainerWithPadding from 'components/GridContainerWithPadding/GridContainerWithPadding';
import PageLayout from 'layout/PageLayout/PageLayout';
import { useHistory, useParams } from 'react-router';
import clsx from 'clsx';
import useInvoiceConfirmedStyles from './InvoiceConfirmedStyles';

const InvoiceConfirmed: React.FC = () => {
  const history = useHistory();
  const { eventId } = useParams<{ eventId: string }>();

  const classes = useInvoiceConfirmedStyles();

  const { data } = useGetEventNameQuery({
    variables: {
      id: parseInt(eventId),
    },
    skip: eventId === null
  });

  return (
    <PageLayout
      title={data?.getCommunicorEvent?.name}
      navigation
    >
      <GridContainerWithPadding container className={classes.container}>
        <Grid item xs={11} sm={10} md={9} lg={8}>
          <GridContainerWithPadding container spacing={2}>
            <Grid item xs={12} className={classes.margin}>
              <Typography variant="h1">Your invoice has been approved !</Typography>
            </Grid>
            <Grid item xs={12} className={clsx(classes.margin, classes.option)}>
              <span className={classes.caption}>Payment will be processed in a few days.</span>
            </Grid>
            <Grid item xs={12} className={clsx(classes.margin, classes.option)}>
              <Button variant="contained" size="large" onClick={() => history.push('/')}>Return to my bookings</Button>
            </Grid>
          </GridContainerWithPadding>
        </Grid>
      </GridContainerWithPadding>
    </PageLayout>
  );
};

export default InvoiceConfirmed;