import { makeStyles } from '@material-ui/core/styles';

const usePageLayoutStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: '#171A1D',
    height: 'fit-content',
  },
  navigation: {
    display: 'inline-flex',
  },
  mainContent: {
    marginTop: '100px',
    textAlign: 'left',
    marginBottom: '56px',
    height: '100%'
  },
  footer: {
    backgroundColor: '#212529',
    position: 'fixed',
    bottom: '0',
    width: '100vw',
    left: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white'
  },
  appbar: {
    boxShadow: 'none',
    transition: 'none',
    color: 'white'
  },
  logo: {
    width: '250px',
    height: '60px',
    marginTop: '10px',
  },
  navItem:{
    fontSize: '18px',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    color: 'white',
    marginLeft: '15px',
    marginRight: '15px',
    '&:hover': {
      cursor: 'pointer',
      color: '#CED4DA'
    }
  },
  navSeparator: {
    fontSize: '18px',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    color: '#8B8C8E'
  },
  alignItem: {
    textAlign: 'center'
  },
  pageTitle: {
    fontSize: '28px',
    fontFamily: 'Karla',
    fontWeight: 600,
    color: 'white',
    borderBottom: '1px solid white',
    width: 'fit-content',
    marginLeft: '20%'
  }
});

export default usePageLayoutStyles;