import { makeStyles } from '@material-ui/core';

const useIBEventSummaryStyles = makeStyles({
  itemsTitle: {
    float: 'left',
    fontSize: '24px',
    fontWeight: 600,
    fontFamily: 'Karla',
    marginTop: '25px',
    marginBottom: '5px'
  },
  addItemButton: {
    float: 'right',
    marginRight: '0px'
  },
  title: {
    borderBottom: '1px solid #6C757D',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
  label: {
    fontWeight: 300,
    fontFamily: 'Montserrat',
    fontSize: '18px'
  },
  value: {
    fontWeight: 600,
    fontSize: '20px',
    fontFamily: 'Montserrat',
    textAlign: 'right',
    minWidth: '200px'
  },
  eventName: {
    fontWeight: 500,
    fontSize: '40px',
    fontFamily: 'Karla',
  },
  stepLabel: {
    fontWeight: 300,
    fontSize: '22px',
    fontFamily: 'Montserrat',
  },
  step: {
    fontWeight: 500,
    fontSize: '22px',
    fontFamily: 'Montserrat',
  },
  eventDivider: {
    width: '312px',
    float: 'left',
    marginTop: '15px'
  },
  eventTitle: {
    paddingBottom: '0px !important'
  },
  items: {
    marginBottom: '10px'
  },
  itemName: {
    textAlign: 'left',
    fontSize: '22px',
    fontFamily: 'Karla',
    fontWeight: 700,
    alignSelf: 'center',
    padding: '4px'
  },
  itemLabel: {
    fontWeight: 300,
    fontFamily: 'Montserrat',
    fontSize: '16px',
    padding: '4px',
  },
  itemValue: {
    fontWeight: 600,
    fontSize: '18px',
    fontFamily: 'Montserrat',
    color: '#CED4DA',
    padding: '4px'
  },
  itemPrice: {
    fontWeight: 600,
    fontSize: '20px',
    fontFamily: 'Montserrat',
    textAlign: 'right',
    padding: '4px'
  },
  itemDivider: {
    border: 'unset',
    borderBottom: '1px solid rgba(108, 117, 125, 0.30)',
  },
  itemDividerRow: {
    padding: '2px'
  },
  bookingButtonRow: {
    padding: '5px',
    textAlign: 'right',
  },
  bookingButton: {
    width: '207px'
  },
  paiementInfo: {
    fontSize: '12px'
  }
});

export default useIBEventSummaryStyles;