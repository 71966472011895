import { Card, CardContent, Grid } from '@material-ui/core';
import { IbFieldsFragment } from 'apollo/queries/types';
import { useHistory } from 'react-router-dom';
import useIndividualCardStyles from './IndividualCardStyles';


interface Props {
  individual: IbFieldsFragment;
  eventId: string;
}

const IndividualCard: React.FC<Props> = ({ individual, eventId }) => {

  const history = useHistory();
  const classes = useIndividualCardStyles();

  const quantities: { quote: number, invoice: number} = individual.basketIds?.reduce((acc: { quote: number, invoice: number}, current: { id: number, saleOrderId?: { id: number, invoiceCount: number} }) => {
    return {
      quote: acc.quote + (current.saleOrderId ? 1 : 0),
      invoice: acc.invoice + (current.saleOrderId?.invoiceCount || 0)
    };
  }, { quote: 0, invoice: 0 });

  return (
    <Card onClick={() => history.push(`/IBReservations/${individual.id}/${eventId}`)} className={classes.card}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid xs={12}>
            <span className={classes.individual}>
              { individual.displayName }
            </span>
          </Grid>
          <Grid className={classes.quantityBlock}>
            <div className={classes.label}>Booking: <span className={classes.value}> { individual.basketIds.filter((basket) => basket?.eventId?.id !== undefined && basket.eventId.id === parseInt(eventId)).length }</span></div>
            <div className={classes.label}>Quote: <span className={classes.value}> { quantities.quote }</span></div>
            <div className={classes.label}>Invoice: <span className={classes.value}> { quantities.invoice }</span></div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

};

export default IndividualCard;