import { Grid, Typography, TextField, Button } from '@material-ui/core';
import { useGetCarPassCartQuery } from 'apollo/queries';
import { useCreateAccreditationLineMutation } from 'apollo/mutations';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';
import RequestHandling from 'components/RequestHandling';
import PageLayout from 'layout/PageLayout/PageLayout';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useCarPassStyles from './CarPassStyle';
import { CommunicorAccreditationTemplate } from 'apollo/types';
import { Info } from '@material-ui/icons';

interface ItemQuantity {
  [name: string]: number;
}

const CarPass: React.FC = () => {

  const classes = useCarPassStyles();
  const history = useHistory();
  const { basketId } = useParams<{ eventId: string, basketId: string }>();

  const [carPassQuantities, setCarPassQuantities] = useState<ItemQuantity>({});
  const [servicesQuantities, setServicesQuantities] = useState<ItemQuantity>({});
  const [accreditations, setAccreditations] = useState<Omit<CommunicorAccreditationTemplate, 'pricelistId'>[]>([]);
  const [services, setServices] = useState<Omit<CommunicorAccreditationTemplate, 'pricelistId'>[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalImage, setModalImage] = useState<string | null>(null);

  const getCarPassCartQueryResult = useGetCarPassCartQuery({
    variables: {
      id: parseInt(basketId),
    },
    skip: basketId === undefined,
  });

  useEffect(() => {
    if (!getCarPassCartQueryResult.loading && !getCarPassCartQueryResult.error) {
      const types = getCarPassCartQueryResult.data?.getCommunicorBasket?.pricelistId?.accreditationLineIds.filter((acc) => acc.accreditationTypeId.isCarpass);
      setAccreditations(types || []);
      if (types) {
        setCarPassQuantities(types.reduce((accr, value) => {
          return {
            ...accr,
            [value.name]: 0
          };
        }, {}));
      }

      const accrServices = getCarPassCartQueryResult.data?.getCommunicorBasket?.pricelistId?.accreditationLineIds.filter((acc) => !acc.accreditationTypeId.isCarpass);
      setServices(accrServices || []);
      if (accrServices) {
        setServicesQuantities(accrServices.reduce((accr, value) => {
          return {
            ...accr,
            [value.name]: 0
          };
        }, {}));
      }
    }
  }, [
    getCarPassCartQueryResult.loading,
    getCarPassCartQueryResult.error,
    getCarPassCartQueryResult.data?.getCommunicorBasket?.pricelistId?.accreditationLineIds
  ]);

  const totalQuantity = Object.values(carPassQuantities).reduce((acc, value) => acc + value, 0) + Object.values(servicesQuantities).reduce((acc, value) => acc + value, 0);

  const computePrice = () => {
    if (getCarPassCartQueryResult.error || getCarPassCartQueryResult.loading) {
      return 0;
    }

    const carPassPrice = Object.entries(carPassQuantities).reduce((acc, carPass) => {
      const priceLine = accreditations.find(line => line.name === carPass[0]);
      return acc + ((priceLine?.pricePerUnit || 0) * carPass[1]);
    }, 0);

    const servicesPrice = Object.entries(servicesQuantities).reduce((acc, service) => {
      const priceLine = services.find(line => line.name === service[0]);
      return acc + ((priceLine?.pricePerUnit || 0) * service[1]);
    }, 0);

    return carPassPrice + servicesPrice;
  };

  const [createAccreditationLienMutation] = useCreateAccreditationLineMutation();

  const onValidate = async () => {
    try {
      await Promise.all(Object.entries(carPassQuantities).map(async (carPass) => {
        if (carPass[1] > 0) {
          const priceLine = accreditations.find(line => line.name === carPass[0]);
          if (priceLine?.id) {
            await createAccreditationLienMutation({
              variables: {
                basketId: parseInt(basketId),
                quantity: carPass[1],
                templateId: priceLine?.id,
              }
            });
          }
        }
      }));

      await Promise.all(Object.entries(servicesQuantities).map(async (service) => {
        if (service[1] > 0) {
          const priceLine = services.find(line => line.name === service[0]);
          if (priceLine?.id) {
            await createAccreditationLienMutation({
              variables: {
                basketId: parseInt(basketId),
                quantity: service[1],
                templateId: priceLine?.id,
              }
            });
          }
        }
      }));
      history.push(`/eventBasket/${basketId}`);
    } catch (err) {
      console.log(err);
    }
  };

  const updateCarPass = (name: string, value: number) => {
    if (value >= 0) {
      setCarPassQuantities({ ...carPassQuantities, [name]: value });
    }
  };

  const updateService = (name: string, value: number) => {
    if (value >= 0) {
      setServicesQuantities({ ...servicesQuantities, [name]: value });
    }
  };
  
  return (
    <PageLayout
      navigation
      title="Accreditations"
      backButton
    >
      <RequestHandling {...getCarPassCartQueryResult}>
        {
          cart =>
            <Grid container spacing={2}>
              <Grid item xs={4} lg={3}>
                <Grid item xs={12}>
                  <Typography variant="h1">
                    {'Accreditations'}
                  </Typography>
                </Grid>
                {
                  accreditations.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.sectionTitle}>
                          {'Type of accreditation:'}
                        </Typography>
                      </Grid>
                      {
                        accreditations.map((type) => {
                          return (
                            <Grid item xs={12} key={type.id} className={classes.type}>
                              <Grid container spacing={1}>
                                <Grid item xs={8} className={classes.verticalAlign}>
                                  <span className={classes.typeName}>
                                    {type.carpassType?.name}:
                                  </span>
                                  {
                                    cart.getCommunicorBasket?.eventId.carpassEntryIds.find((entry) => entry.carpassTypeId?.id === type.carpassType?.id)?.image && (
                                      <Info
                                        className={classes.info}
                                        onClick={() => {
                                          setIsOpen(!isOpen);
                                          setModalImage(cart.getCommunicorBasket?.eventId.carpassEntryIds.find((entry) => entry.carpassTypeId?.id === type.carpassType?.id)?.image || null);
                                        }}
                                      />
                                    )
                                  }
                                </Grid>
                                <Grid item xs={4} className={classes.verticalAlign}>
                                  <TextField
                                    value={carPassQuantities[type.name] || 0}
                                    variant="outlined"
                                    type="number"
                                    className={classes.typeQuantity}
                                    fullWidth
                                    onChange={(event) => updateCarPass(type.name, parseInt(event.target.value))}
                                    InputProps={{
                                      className: classes.typeQuantity,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        })
                      }
                    </>
                  )
                }
                {
                  services.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.sectionTitle}>
                          {'Services:'}
                        </Typography>
                      </Grid>
                      {
                        services.map((service) => {
                          return (
                            <Grid item xs={12} key={service.id} className={classes.type}>
                              <Grid container spacing={1}>
                                <Grid item xs={8} className={classes.verticalAlign}>
                                  <span className={classes.typeName}>
                                    {service.name}:
                                  </span>
                                </Grid>
                                <Grid item xs={4} className={classes.verticalAlign}>
                                  <TextField
                                    value={servicesQuantities[service.name] || 0}
                                    variant="outlined"
                                    type="number"
                                    className={classes.typeQuantity}
                                    fullWidth
                                    onChange={(event) => updateService(service.name, parseInt(event.target.value))}
                                    InputProps={{
                                      className: classes.typeQuantity
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        })
                      }
                    </>
                  )
                }
                {
                  totalQuantity > 0 && (
                    <>
                      <Grid item xs={12} className={classes.price}>
                        <PriceDisplay
                          pricelist={cart.getCommunicorBasket?.pricelistId}
                          price={computePrice()}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button variant="contained" size="large" fullWidth onClick={() => onValidate()}>Validate</Button>
                      </Grid>
                    </>
                  )
                }
              </Grid>
              <Grid item xs={8} lg={9} className={classes.mapContainer}>
                {
                  accreditations.length > 0 && cart.getCommunicorBasket?.eventId.carpassMap && (
                    <img src={`data:image/png;base64, ${isOpen ? modalImage : cart.getCommunicorBasket?.eventId.carpassMap}`} className={classes.map} alt="Map"></img>
                  )
                }
              </Grid>
            </Grid>
        }
      </RequestHandling>
    </PageLayout>
  );
};

export default CarPass;