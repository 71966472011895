import { makeStyles } from '@material-ui/core/styles';

const useBookingConfirmedStyles = makeStyles((theme) => ({
  container: {
    placeContent: 'center',
    textAlign: 'center',
    paddingTop: '150px'
  },
  margin: {
    marginBottom: '30px'
  },
  caption: {
    fontSize: '18px',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    color: '#CED4DA'
  },
  option: {
    height: '90px'
  }
}));

export default useBookingConfirmedStyles;