import { Button, CircularProgress, Grid, IconButton, Snackbar, TextField, Typography } from '@material-ui/core';
import PageLayout from 'layout/PageLayout/PageLayout';
import React, { useState } from 'react';
import { Close, CloudUpload, Settings } from '@material-ui/icons';
import * as XLSX from 'xlsx';
import { useAddIndividualsMutation } from 'apollo/mutations';
import { useHistory, useParams } from 'react-router-dom';
import usePMAddIndividualStyles from './PMAddIndividualStyles';
import GridContainerWithPadding from 'components/GridContainerWithPadding/GridContainerWithPadding';
import csvFormats from 'utils/csvFormats';
import { Alert } from '@material-ui/lab';
import RegExLibrary from 'utils/RegExLibrary';

const PMAddIndividual: React.FC = () => {

  const classes = usePMAddIndividualStyles();
  const history = useHistory();

  const { eventId } = useParams<{ eventId: string }>();

  // Id is needed to have a consistent key during render
  const [individualEmails, setIndividualEmails] = useState<{ id: number, mail: string }[]>([{ id: 1, mail: '' }]);
  const [failedEmails, setFailedEmails] = useState<string[]>([]);
  const [failedImportedEmails, setFailedImportedEmails] = useState<string[]>([]);

  const [openImportFile, setOpenImportFile] = useState(false);
  const [processingFile, setProcessingFile] = useState<boolean>(false);
  const [processingInvitation, setProcessingInvitation] = useState<boolean>(false);

  const processData = (dataString: string) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length === headers.length) {
        const obj: { [key: string]: string } = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"')
              d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"')
              d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
    }
    const newArray = [...individualEmails];
    const failedImportArray : string[] = [];
    list.forEach((mailObject) => {
      const newEmail = Object.values(mailObject)[0];
      if (RegExLibrary.email.test(newEmail)) {
        if (newArray.length > 0 && newArray[newArray.length - 1].mail === '') {
          newArray.pop();
        }
        newArray.push({ id: (newArray[newArray.length - 1]?.id + 1) || 1, mail: newEmail });
      } else {
        failedImportArray.push(newEmail);
      }
    });
    setFailedImportedEmails(failedImportArray);
    setIndividualEmails(newArray);
    setOpenImportFile(false);
    setProcessingFile(false);
  };

  // handle file upload
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e !== null && e.target !== null && e.target.files !== null) {
      const file = e.target.files[0];
      if (csvFormats.includes(file.type)) {
        setProcessingFile(true);
        processFile(file);
      }
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e !== null && e.dataTransfer !== null) {
      const file = e.dataTransfer.files[0];
      if (csvFormats.includes(file.type)) {
        setProcessingFile(true);
        processFile(file);
      }
    }
  };

  const processFile = async (file: File) => {
    // Delay to give a feedback to user that we are currently processing file
    setTimeout(() => {
      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt?.target?.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws);
        processData(data);
      };
      reader.readAsBinaryString(file);
    }, 1500);
  };

  const [addIndividualsMutation] = useAddIndividualsMutation();

  const addIndividuals = async () => {
    setProcessingInvitation(true);
    try {
      const uniqueIndividuals: { [key: string]: boolean } = {};
      const individualList = individualEmails.reduce((acc: string[], current: { id: number, mail: string }, index: number) => {
        if (current.mail !== '' && RegExLibrary.email.test(current.mail) && !uniqueIndividuals[current.mail]) {
          acc.push(current.mail);
          uniqueIndividuals[current.mail] = true;
        }
        return acc;
      }, []);
      const { data } = await addIndividualsMutation(
        {
          variables: {
            eventId: parseInt(eventId),
            individuals: individualList
          },
          refetchQueries: ['findInvitedIB']
        }
      );
      if (data?.actionInviteBooker?.rejected?.length) {
        setFailedEmails(data.actionInviteBooker.rejected.map((email) => email?.split(':')[0]) as string[]);
        setIndividualEmails([{ id: 1, mail: ' ' }]);
        setProcessingInvitation(false);
      } else {
        setProcessingInvitation(false);
        history.push('/eventsList');
      }
    } catch (err) {
      console.log(err);
    }

  };

  const addEntry = () => {
    const newArray = [...individualEmails];
    newArray.push({ id: newArray[newArray.length - 1].id + 1, mail: '' });
    setIndividualEmails(newArray);
  };

  const updateEntry = (index: number, value: string) => {
    const newArray = [...individualEmails];
    newArray[index].mail = value;
    setIndividualEmails(newArray);
  };

  const removeEntry = (index: number) => {
    const newArray = [...individualEmails];
    newArray.splice(index, 1);
    setIndividualEmails(newArray);
  };
  return (
    <PageLayout
      navigation
      title="Add individual"
      backButton
    >
      {
        !openImportFile
          ? (
            <>
              <Grid container spacing={3} className={classes.container}>
                <Grid item xs={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h1">
                        Add Individual{individualEmails.length > 1 && 's'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.information}>
                        Individual{individualEmails.length > 1 && 's'} which {individualEmails.length > 1 ? 'were' : 'was'} add will received a link on their mailbox to register. After, {individualEmails.length > 1 ? 'they' : 'he/she'} will be able to booking a transport service.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => addIndividuals()}
                        disabled={
                          individualEmails[0].mail === ''
                          || !RegExLibrary.email.test(individualEmails[0].mail)
                          || processingInvitation
                        }
                      >
                        {processingInvitation ?
                          <CircularProgress />
                          :
                          `Add individual${individualEmails.length > 1 ? 's' : ''}`
                        }
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <GridContainerWithPadding container spacing={3} className={classes.mailContainer}>
                    {
                      individualEmails.map((individual, index) => (
                        <Grid item xs={12} key={individual.id}>
                          <Grid container>
                            <Grid item xs={10}>
                              <TextField
                                id={`mail-${individual.id}`}
                                label="Email Address"
                                value={individual.mail}
                                type="email"
                                onChange={(event) => updateEntry(index, event.target.value)}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                              />
                            </Grid>
                            {
                              index > 0 && (
                                <Grid item xs={2}>
                                  <IconButton onClick={() => removeEntry(index)} className={classes.close}>
                                    <Close />
                                  </IconButton>
                                </Grid>
                              )
                            }
                          </Grid>
                        </Grid>
                      ))
                    }
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={5}>
                          <Button variant="contained" size="large" fullWidth onClick={() => setOpenImportFile(true)}>Import individual</Button>
                        </Grid>
                        <Grid item xs={5}>
                          <Button
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={() => addEntry()} disabled={individualEmails[individualEmails.length - 1].mail === '' || !RegExLibrary.email.test(individualEmails[individualEmails.length - 1].mail)}
                          >
                            Add a new field
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </GridContainerWithPadding>
                </Grid>
              </Grid>
              <Snackbar open={failedEmails.length > 0} autoHideDuration={6000} onClose={() => setFailedEmails([])}>
                <Alert onClose={() => setFailedEmails([])} variant="filled" severity="error">
                  {failedEmails.map((email, index) => (
                    <span key={index}>
                      {`${email}${(index + 1 === failedEmails.length) ? ' ' : (index === failedEmails.length - 2) ? ' and ' : ', '}`}
                    </span>
                  )
                  )} couldn't be invited.
                </Alert>
              </Snackbar>
              <Snackbar open={failedImportedEmails.length > 0} autoHideDuration={6000} onClose={() => setFailedImportedEmails([])}>
                <Alert onClose={() => setFailedImportedEmails([])} variant="filled" severity="error">
                  {failedImportedEmails.map((email, index) => (
                    <span key={index}>
                      {`${email}${(index + 1 === failedImportedEmails.length) ? ' ' : (index === failedImportedEmails.length - 2) ? ' and ' : ', '}`}
                    </span>
                  )
                  )} couldn't be imported.
                </Alert>
              </Snackbar>
            </>
          )
          : (
            <Grid container spacing={3} className={classes.container}>
              <Grid item xs={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h1">
                      Import an Individuals list
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <hr />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" size="large" onClick={() => setOpenImportFile(false)}>Cancel&nbsp;&nbsp;<Close /></Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <GridContainerWithPadding container spacing={3} className={classes.mailContainer}>
                  {
                    !processingFile
                      ? (
                        <div onDragOver={e => e.preventDefault()} onDrop={e => handleDrop(e)}>
                          <Grid item xs={12} className={classes.importBlock}>
                            <Grid container spacing={2} className={classes.importContainer}>
                              <Grid item xs={12}>
                                <CloudUpload className={classes.importIcon} />
                              </Grid>
                              <Grid item xs={12} className={classes.importTitle}>
                                Upload a .xls field
                              </Grid>
                              <Grid item xs={12} className={classes.importInformation}>
                                {'You can drag & drop your file here or click on the button below for import the field.'}
                              </Grid>
                              <Grid item xs={12}>
                                <input accept={csvFormats.join(',')} id="icon-button-file" type="file" className={classes.importInput} onChange={(event) => handleFileUpload(event as React.ChangeEvent<HTMLInputElement>)} />
                                <label htmlFor="icon-button-file">
                                  <IconButton color="primary" aria-label="upload picture" component="span" className={classes.importButton} onDrop={(event: React.DragEvent) => handleDrop(event)}>
                                    Upload
                                  </IconButton>
                                </label>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      )
                      : (
                        <Grid item xs={12} className={classes.importBlock}>
                          <Grid container spacing={2} className={classes.importContainer}>
                            <Grid item xs={12}>
                              <Settings className={classes.importIconProcessing} />
                            </Grid>
                            <Grid item xs={12} className={classes.importTitle}>
                              Processing your file
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                  }
                  <Grid item xs={12}>
                    <Typography className={classes.information}>
                      Only Excel fields are accepted. We will add only a column with the header named "Email".
                    </Typography>
                  </Grid>
                </GridContainerWithPadding>
              </Grid>
            </Grid>
          )
      }
    </PageLayout >
  );
};

export default PMAddIndividual;