import Loader from 'components/Loader/Loader';
import { useEffect } from 'react';
import { Redirect, Route, useHistory, Switch } from 'react-router-dom';
import SignIn from './pages/SignIn/SignIn';

import setLoggedIn from 'store/loggedIn';
import { useCurrentUserQuery, useLoggedInQuery } from 'apollo/queries';
import { individualRoutes, masterRoute, publicRoutes } from './routes';

const App = () => {
  const history = useHistory();

  const { data: loggedInData } = useLoggedInQuery();
  const { data, loading, error } = useCurrentUserQuery({
    skip: !loggedInData?.isLoggedIn
  });

  useEffect(() => {
    if (loggedInData?.isLoggedIn && error !== undefined) {
      setLoggedIn(false);
      localStorage.removeItem('session_id');
      window.location.reload();
    }
  }, [loggedInData?.isLoggedIn, error]);

  return loading ?
    <Loader /> :
    <>
      {
        !loggedInData?.isLoggedIn ?
          <Switch>
            {publicRoutes.map((route) => <Route key={route.path} exact {...route} />)}
            <Route
              exact
              path="/login"
              render={
                () => <SignIn afterLogin={
                  () => {
                    setLoggedIn(true);
                    history.push('/');
                  }
                } />
              }
            />
            <Redirect to="/login" />
          </Switch>
          :
          data?.getMyData ?
            data.getMyData.role.key === 'individual_booking' ?
              <Switch>
                {individualRoutes.map((route) => <Route key={route.path} exact path={route.path} component={route.component} />)}
                <Redirect to="/" />
              </Switch>
              :
              data.getMyData.role.key === 'master_account' ?
                <Switch>
                  {masterRoute.map((route) => <Route key={route.path} exact path={route.path} component={route.component} />)}
                  <Redirect to="/" />
                </Switch>
                :
                <Redirect to="/" />
            :
            <Redirect to="/" />
      }
    </>;
};

export default App;
