import { makeStyles } from '@material-ui/core';
import { VehicleFieldsFragment } from 'apollo/queries/types';
import SelectableCard from 'components/SelectableCard/SelectableCard';
import { useMemo } from 'react';

const useStyle = makeStyles({
  vehicleGrid: {
    marginTop: '10px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '10px',
  },
  vehicleCard:{
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '12px',
    padding: '15px',
    backgroundColor: '#171A1D',
    border: '2px solid #CED4DA',
    borderRadius: '10px',
    color: '#FFFFFF'
  },
  vehicleName: {
    fontWeight: 700,
    fontFamily: 'Montserrat',
    fontSize: '17px'
  },
  selected: {
    backgroundColor: '#CED4DA',
    color: '#212529',
  },
  vehicleDescription: {
    fontWeight: 300,
    fontSize: '14px',
    fontFamily: 'Montserrat',
    color: '#CED4DA'
  },
  selectedDescription: {
    color: '#495057'
  },
  alignLeft: {
    textAlign: 'left',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    color: '#CED4DA',
    fontSize: '18px'
  }
});

interface Props {
  options: VehicleFieldsFragment[];
  selectedVehicle: number | null;
  setSelectedVehicle: (value: number) => void;
};

const VehicleSelector: React.FC<Props> = ({ options, selectedVehicle, setSelectedVehicle }) => {
  
  const classes = useStyle();
  const sortedVehicles = useMemo(() => {
    return options.sort((vehicleA, vehicleB) => {
      if (vehicleA.luxury === undefined || vehicleA.luxury === null) {
        return vehicleB !== undefined && vehicleB !== null ? -1 : 0;
      } else if (vehicleB.luxury === undefined || vehicleB.luxury === null) {
        return vehicleA !== undefined && vehicleA !== null ? 1 : 0;
      }
      return vehicleB.luxury - vehicleA.luxury;
    });
  }, [options]);
  return (
    <div className={classes.alignLeft}>
      Vehicle Type
      <div className={classes.vehicleGrid}>
        {sortedVehicles.map((vehicle) => {
          return (
            <SelectableCard 
              key={vehicle.id} 
              selected={selectedVehicle === vehicle.id}
              onSelect={() => setSelectedVehicle(vehicle.id)}
              title={vehicle.name}
              subtitle={vehicle.description}
            />
          );
        })}
      </div>
    </div>
  );
};

export default VehicleSelector;