import { makeStyles } from '@material-ui/core';

const usePMAddIndividualStyles = makeStyles({
  firstLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  container: {
    marginTop: '160px'
  },
  information: {
    color: '#CED4DA',
    fontSize: '18px',
    fontFamily: 'Montserrat',
    fontWeight: 500
  },
  close: {
    color: '#CED4DA',
    marginTop: '3px'
  },
  mailContainer: {
    paddingTop: '50px',
    marginBottom: '20px'
  },
  importBlock: {
    border: '2px solid white',
    borderRadius: '10px'
  },
  importContainer: {
    padding: '20px',
    textAlign: 'center'
  },
  importIcon: {
    color: '#CED4DA',
    fontSize: '64px'
  },
  importIconProcessing: {
    color: '#CED4DA',
    fontSize: '64px',
    animation: 'spin 2s linear infinite'
  },
  importTitle: {
    fontSize: '24px',
    fontWeight: 600,
    fontFamily: 'Karla'
  },
  importInformation: {
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    color: '#CED4DA'
  },
  importInput: {
    display: 'none'
  },
  importButton: {
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 3px 1px -2px ,gb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    minHeight: '60px',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    marginRight: '10px',
    borderRadius: '10px',
    backgroundColor: '#e0e0e0',
    fontSize: '0.9375rem',
    minWidth: '154px',
    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
      backgroundColor: '#d5d5d5',
    }
  }
});

export default usePMAddIndividualStyles;