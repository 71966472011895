import * as Types from "./types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
const defaultOptions = {};
export const EventFieldsFragmentDoc = gql`
  fragment EventFields on CommunicorEvent {
    id
    name
    startDate
    endDate
    website
    tz {
      key
      value
    }
  }
`;
export const PassengerFragmentDoc = gql`
  fragment Passenger on ResUsers {
    id
    name
    firstname
    email
    phone
    isPassengersGroup
    groupName
    groupSize
  }
`;
export const CommunicorDailyFieldsFragmentDoc = gql`
  fragment CommunicorDailyFields on CommunicorDaily {
    id
    vehicleTypeId {
      id
      name
    }
    passengerId {
      ...Passenger
    }
    startDate
    endDate
    totalPrice
    tvaId {
      id
      amount
    }
  }
  ${PassengerFragmentDoc}
`;
export const CommunicorAirportFieldsFragmentDoc = gql`
  fragment CommunicorAirportFields on CommunicorAirport {
    id
    vehicleTypeId {
      id
      name
    }
    passengerId {
      ...Passenger
    }
    startDate
    endDate
    totalPrice
    quantity
    pricePerUnit
    externalTransportType {
      key
      value
    }
    transportCode
    transportNumber
    transportOrigin
    transportDestination
    tvaId {
      id
      amount
    }
  }
  ${PassengerFragmentDoc}
`;
export const CommunicorAccreditationFieldsFragmentDoc = gql`
  fragment CommunicorAccreditationFields on CommunicorAccreditation {
    id
    name
    quantity
    totalPrice
    accreditationTypeId {
      id
      name
      isCarpass
    }
    carpassType {
      id
      name
    }
  }
`;
export const AccountTaxFieldsFragmentDoc = gql`
  fragment AccountTaxFields on AccountTax {
    id
    name
    amount
  }
`;
export const CommunicorTechnicalFieldsFragmentDoc = gql`
  fragment CommunicorTechnicalFields on CommunicorTechnical {
    id
    name
    quantity
    totalPrice
    technicalTypeId {
      id
      name
      isMg
    }
    mgType {
      id
      name
    }
    startDate
    endDate
  }
`;
export const CurrencyFieldsFragmentDoc = gql`
  fragment CurrencyFields on ResCurrency {
    id
    name
    symbol
  }
`;
export const VehicleFieldsFragmentDoc = gql`
  fragment VehicleFields on CommunicorVehicleType {
    id
    name
    description
    numberSeats
    luxury
  }
`;
export const PricelistFieldsFullFragmentDoc = gql`
  fragment PricelistFieldsFull on CommunicorPricelist {
    id
    currency {
      ...CurrencyFields
    }
    default
    divisionId {
      id
    }
    dailyLineIds {
      id
      vehicleTypeId {
        ...VehicleFields
      }
      pricePerDay
      ratePerExtraHour
      ratePerExtraKm
      maxHours
      maxKm
      currency {
        id
        name
        symbol
      }
    }
    airportLineIds {
      id
      vehicleTypeId {
        ...VehicleFields
      }
      pricePerUnit
      ratePerExtraHour
      ratePerExtra
      currency {
        id
        name
        symbol
      }
    }
    accreditationLineIds {
      id
      accreditationTypeId {
        id
        name
        isCarpass
      }
      name
      pricePerUnit
      createDate
      carpassType {
        id
        name
        description
      }
      currency {
        id
        name
        symbol
      }
    }
    technicalLineIds {
      id
      technicalTypeId {
        id
        name
        isMg
      }
      name
      pricePerUnit
      mgType {
        id
        name
        description
      }
      currency {
        id
        name
        symbol
      }
    }
  }
  ${CurrencyFieldsFragmentDoc}
  ${VehicleFieldsFragmentDoc}
`;
export const SaleOrderFieldsFragmentDoc = gql`
  fragment SaleOrderFields on SaleOrder {
    id
    dateOrder
    name
    state {
      key
      value
    }
    invoiceIds {
      id
      markReady
      state {
        key
        value
      }
      name
    }
  }
`;
export const BasketFieldsFullFragmentDoc = gql`
  fragment BasketFieldsFull on CommunicorBasket {
    id
    priceTotalHt
    priceTotalTtc
    priceTransportation
    priceTransfers
    priceAccreditation
    priceTechnical
    tvaExempt
    state {
      key
      value
    }
    eventId {
      ...EventFields
    }
    dailyLineIds {
      ...CommunicorDailyFields
    }
    airportLineIds {
      ...CommunicorAirportFields
    }
    accreditationLineIds {
      ...CommunicorAccreditationFields
    }
    accreditationTvaId {
      ...AccountTaxFields
    }
    technicalLineIds {
      ...CommunicorTechnicalFields
    }
    technicalTvaId {
      ...AccountTaxFields
    }
    currency {
      ...CurrencyFields
    }
    pricelistId {
      ...PricelistFieldsFull
    }
    saleOrderId {
      ...SaleOrderFields
    }
    pricelistId {
      cgv
      cancelPolicy
    }
  }
  ${EventFieldsFragmentDoc}
  ${CommunicorDailyFieldsFragmentDoc}
  ${CommunicorAirportFieldsFragmentDoc}
  ${CommunicorAccreditationFieldsFragmentDoc}
  ${AccountTaxFieldsFragmentDoc}
  ${CommunicorTechnicalFieldsFragmentDoc}
  ${CurrencyFieldsFragmentDoc}
  ${PricelistFieldsFullFragmentDoc}
  ${SaleOrderFieldsFragmentDoc}
`;
export const BasketFieldsLightFragmentDoc = gql`
  fragment BasketFieldsLight on CommunicorBasket {
    id
    createDate
    state {
      key
      value
    }
    eventId {
      id
      name
    }
    saleOrderId {
      ...SaleOrderFields
    }
    pricelistId {
      cgv
      cancelPolicy
    }
    priceTotalTtc
    currency {
      symbol
    }
  }
  ${SaleOrderFieldsFragmentDoc}
`;
export const DivisionInformationsFragmentDoc = gql`
  fragment DivisionInformations on ResPartner {
    id
    name
    vat
  }
`;
export const IbFieldsFragmentDoc = gql`
  fragment IBFields on ResUsers {
    id
    name
    displayName
    basketIds {
      id
      eventId {
        id
      }
    }
  }
`;
export const PartnerFieldsFragmentDoc = gql`
  fragment PartnerFields on ResPartner {
    id
    name
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on ResUsers {
    id
    firstname
    name
    role {
      key
    }
    parentId {
      ...PartnerFields
    }
    divisionId {
      ...PartnerFields
    }
  }
  ${PartnerFieldsFragmentDoc}
`;
export const GetEventNameDocument = gql`
  query getEventName($id: Int!) {
    getCommunicorEvent(id: $id) {
      id
      name
    }
  }
`;
export type GetEventNameProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetEventNameQuery,
    Types.GetEventNameQueryVariables
  >;
} &
  TChildProps;
export function withGetEventName<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetEventNameQuery,
    Types.GetEventNameQueryVariables,
    GetEventNameProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetEventNameQuery,
    Types.GetEventNameQueryVariables,
    GetEventNameProps<TChildProps, TDataName>
  >(GetEventNameDocument, {
    alias: "getEventName",
    ...operationOptions,
  });
}

/**
 * __useGetEventNameQuery__
 *
 * To run a query within a React component, call `useGetEventNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetEventNameQuery,
    Types.GetEventNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetEventNameQuery,
    Types.GetEventNameQueryVariables
  >(GetEventNameDocument, options);
}
export function useGetEventNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetEventNameQuery,
    Types.GetEventNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetEventNameQuery,
    Types.GetEventNameQueryVariables
  >(GetEventNameDocument, options);
}
export type GetEventNameQueryHookResult = ReturnType<
  typeof useGetEventNameQuery
>;
export type GetEventNameLazyQueryHookResult = ReturnType<
  typeof useGetEventNameLazyQuery
>;
export type GetEventNameQueryResult = Apollo.QueryResult<
  Types.GetEventNameQuery,
  Types.GetEventNameQueryVariables
>;
export const CurrentUserDocument = gql`
  query CurrentUser {
    getMyData {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type CurrentUserProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >;
} &
  TChildProps;
export function withCurrentUser<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables,
    CurrentUserProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables,
    CurrentUserProps<TChildProps, TDataName>
  >(CurrentUserDocument, {
    alias: "currentUser",
    ...operationOptions,
  });
}

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >(CurrentUserDocument, options);
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  Types.CurrentUserQuery,
  Types.CurrentUserQueryVariables
>;
export const GetEventsListDocument = gql`
  query getEventsList {
    searchCommunicorEvent {
      id
      name
      startDate
      endDate
    }
  }
`;
export type GetEventsListProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetEventsListQuery,
    Types.GetEventsListQueryVariables
  >;
} &
  TChildProps;
export function withGetEventsList<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetEventsListQuery,
    Types.GetEventsListQueryVariables,
    GetEventsListProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetEventsListQuery,
    Types.GetEventsListQueryVariables,
    GetEventsListProps<TChildProps, TDataName>
  >(GetEventsListDocument, {
    alias: "getEventsList",
    ...operationOptions,
  });
}

/**
 * __useGetEventsListQuery__
 *
 * To run a query within a React component, call `useGetEventsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEventsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetEventsListQuery,
    Types.GetEventsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetEventsListQuery,
    Types.GetEventsListQueryVariables
  >(GetEventsListDocument, options);
}
export function useGetEventsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetEventsListQuery,
    Types.GetEventsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetEventsListQuery,
    Types.GetEventsListQueryVariables
  >(GetEventsListDocument, options);
}
export type GetEventsListQueryHookResult = ReturnType<
  typeof useGetEventsListQuery
>;
export type GetEventsListLazyQueryHookResult = ReturnType<
  typeof useGetEventsListLazyQuery
>;
export type GetEventsListQueryResult = Apollo.QueryResult<
  Types.GetEventsListQuery,
  Types.GetEventsListQueryVariables
>;
export const FindAirportAlreadyTakenPassengersDocument = gql`
  query findAirportAlreadyTakenPassengers($filter: String!) {
    searchResUsers(filter: $filter) {
      id
      name
      role {
        key
        value
      }
      individualBooking {
        id
      }
      airportIds {
        id
        startDate
        endDate
      }
    }
  }
`;
export type FindAirportAlreadyTakenPassengersProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindAirportAlreadyTakenPassengersQuery,
    Types.FindAirportAlreadyTakenPassengersQueryVariables
  >;
} &
  TChildProps;
export function withFindAirportAlreadyTakenPassengers<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindAirportAlreadyTakenPassengersQuery,
    Types.FindAirportAlreadyTakenPassengersQueryVariables,
    FindAirportAlreadyTakenPassengersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindAirportAlreadyTakenPassengersQuery,
    Types.FindAirportAlreadyTakenPassengersQueryVariables,
    FindAirportAlreadyTakenPassengersProps<TChildProps, TDataName>
  >(FindAirportAlreadyTakenPassengersDocument, {
    alias: "findAirportAlreadyTakenPassengers",
    ...operationOptions,
  });
}

/**
 * __useFindAirportAlreadyTakenPassengersQuery__
 *
 * To run a query within a React component, call `useFindAirportAlreadyTakenPassengersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAirportAlreadyTakenPassengersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAirportAlreadyTakenPassengersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindAirportAlreadyTakenPassengersQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FindAirportAlreadyTakenPassengersQuery,
    Types.FindAirportAlreadyTakenPassengersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FindAirportAlreadyTakenPassengersQuery,
    Types.FindAirportAlreadyTakenPassengersQueryVariables
  >(FindAirportAlreadyTakenPassengersDocument, options);
}
export function useFindAirportAlreadyTakenPassengersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindAirportAlreadyTakenPassengersQuery,
    Types.FindAirportAlreadyTakenPassengersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindAirportAlreadyTakenPassengersQuery,
    Types.FindAirportAlreadyTakenPassengersQueryVariables
  >(FindAirportAlreadyTakenPassengersDocument, options);
}
export type FindAirportAlreadyTakenPassengersQueryHookResult = ReturnType<
  typeof useFindAirportAlreadyTakenPassengersQuery
>;
export type FindAirportAlreadyTakenPassengersLazyQueryHookResult = ReturnType<
  typeof useFindAirportAlreadyTakenPassengersLazyQuery
>;
export type FindAirportAlreadyTakenPassengersQueryResult = Apollo.QueryResult<
  Types.FindAirportAlreadyTakenPassengersQuery,
  Types.FindAirportAlreadyTakenPassengersQueryVariables
>;
export const FindDailyAlreadyTakenPassengersDocument = gql`
  query findDailyAlreadyTakenPassengers($filter: String!) {
    searchResUsers(filter: $filter) {
      id
      name
      role {
        key
        value
      }
      individualBooking {
        id
      }
      dailyIds {
        id
      }
    }
  }
`;
export type FindDailyAlreadyTakenPassengersProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindDailyAlreadyTakenPassengersQuery,
    Types.FindDailyAlreadyTakenPassengersQueryVariables
  >;
} &
  TChildProps;
export function withFindDailyAlreadyTakenPassengers<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindDailyAlreadyTakenPassengersQuery,
    Types.FindDailyAlreadyTakenPassengersQueryVariables,
    FindDailyAlreadyTakenPassengersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindDailyAlreadyTakenPassengersQuery,
    Types.FindDailyAlreadyTakenPassengersQueryVariables,
    FindDailyAlreadyTakenPassengersProps<TChildProps, TDataName>
  >(FindDailyAlreadyTakenPassengersDocument, {
    alias: "findDailyAlreadyTakenPassengers",
    ...operationOptions,
  });
}

/**
 * __useFindDailyAlreadyTakenPassengersQuery__
 *
 * To run a query within a React component, call `useFindDailyAlreadyTakenPassengersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDailyAlreadyTakenPassengersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDailyAlreadyTakenPassengersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindDailyAlreadyTakenPassengersQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FindDailyAlreadyTakenPassengersQuery,
    Types.FindDailyAlreadyTakenPassengersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FindDailyAlreadyTakenPassengersQuery,
    Types.FindDailyAlreadyTakenPassengersQueryVariables
  >(FindDailyAlreadyTakenPassengersDocument, options);
}
export function useFindDailyAlreadyTakenPassengersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindDailyAlreadyTakenPassengersQuery,
    Types.FindDailyAlreadyTakenPassengersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindDailyAlreadyTakenPassengersQuery,
    Types.FindDailyAlreadyTakenPassengersQueryVariables
  >(FindDailyAlreadyTakenPassengersDocument, options);
}
export type FindDailyAlreadyTakenPassengersQueryHookResult = ReturnType<
  typeof useFindDailyAlreadyTakenPassengersQuery
>;
export type FindDailyAlreadyTakenPassengersLazyQueryHookResult = ReturnType<
  typeof useFindDailyAlreadyTakenPassengersLazyQuery
>;
export type FindDailyAlreadyTakenPassengersQueryResult = Apollo.QueryResult<
  Types.FindDailyAlreadyTakenPassengersQuery,
  Types.FindDailyAlreadyTakenPassengersQueryVariables
>;
export const FindIbEventInvitationsDocument = gql`
  query FindIBEventInvitations($filter: String!) {
    searchCommunicorEventInvitation(filter: $filter) {
      id
      eventId {
        id
        name
      }
    }
  }
`;
export type FindIbEventInvitationsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindIbEventInvitationsQuery,
    Types.FindIbEventInvitationsQueryVariables
  >;
} &
  TChildProps;
export function withFindIbEventInvitations<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindIbEventInvitationsQuery,
    Types.FindIbEventInvitationsQueryVariables,
    FindIbEventInvitationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindIbEventInvitationsQuery,
    Types.FindIbEventInvitationsQueryVariables,
    FindIbEventInvitationsProps<TChildProps, TDataName>
  >(FindIbEventInvitationsDocument, {
    alias: "findIbEventInvitations",
    ...operationOptions,
  });
}

/**
 * __useFindIbEventInvitationsQuery__
 *
 * To run a query within a React component, call `useFindIbEventInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindIbEventInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindIbEventInvitationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindIbEventInvitationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FindIbEventInvitationsQuery,
    Types.FindIbEventInvitationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FindIbEventInvitationsQuery,
    Types.FindIbEventInvitationsQueryVariables
  >(FindIbEventInvitationsDocument, options);
}
export function useFindIbEventInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindIbEventInvitationsQuery,
    Types.FindIbEventInvitationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindIbEventInvitationsQuery,
    Types.FindIbEventInvitationsQueryVariables
  >(FindIbEventInvitationsDocument, options);
}
export type FindIbEventInvitationsQueryHookResult = ReturnType<
  typeof useFindIbEventInvitationsQuery
>;
export type FindIbEventInvitationsLazyQueryHookResult = ReturnType<
  typeof useFindIbEventInvitationsLazyQuery
>;
export type FindIbEventInvitationsQueryResult = Apollo.QueryResult<
  Types.FindIbEventInvitationsQuery,
  Types.FindIbEventInvitationsQueryVariables
>;
export const GetAirportCartDocument = gql`
  query GetAirportCart($id: Int!) {
    getCommunicorBasket(id: $id) {
      eventId {
        ...EventFields
      }
      pricelistId {
        airportLineIds {
          id
          vehicleTypeId {
            ...VehicleFields
          }
          pricePerUnit
          ratePerExtraHour
          ratePerExtra
          currency {
            id
            name
            symbol
          }
        }
        currency {
          ...CurrencyFields
        }
      }
    }
  }
  ${EventFieldsFragmentDoc}
  ${VehicleFieldsFragmentDoc}
  ${CurrencyFieldsFragmentDoc}
`;
export type GetAirportCartProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetAirportCartQuery,
    Types.GetAirportCartQueryVariables
  >;
} &
  TChildProps;
export function withGetAirportCart<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetAirportCartQuery,
    Types.GetAirportCartQueryVariables,
    GetAirportCartProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetAirportCartQuery,
    Types.GetAirportCartQueryVariables,
    GetAirportCartProps<TChildProps, TDataName>
  >(GetAirportCartDocument, {
    alias: "getAirportCart",
    ...operationOptions,
  });
}

/**
 * __useGetAirportCartQuery__
 *
 * To run a query within a React component, call `useGetAirportCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAirportCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAirportCartQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAirportCartQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetAirportCartQuery,
    Types.GetAirportCartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetAirportCartQuery,
    Types.GetAirportCartQueryVariables
  >(GetAirportCartDocument, options);
}
export function useGetAirportCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetAirportCartQuery,
    Types.GetAirportCartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetAirportCartQuery,
    Types.GetAirportCartQueryVariables
  >(GetAirportCartDocument, options);
}
export type GetAirportCartQueryHookResult = ReturnType<
  typeof useGetAirportCartQuery
>;
export type GetAirportCartLazyQueryHookResult = ReturnType<
  typeof useGetAirportCartLazyQuery
>;
export type GetAirportCartQueryResult = Apollo.QueryResult<
  Types.GetAirportCartQuery,
  Types.GetAirportCartQueryVariables
>;
export const GetCarPassCartDocument = gql`
  query GetCarPassCart($id: Int!) {
    getCommunicorBasket(id: $id) {
      eventId {
        ...EventFields
        carpassMap
        carpassEntryIds {
          id
          image
          image1920
          displayName
          carpassTypeId {
            id
          }
        }
      }
      pricelistId {
        accreditationLineIds {
          id
          accreditationTypeId {
            id
            name
            isCarpass
          }
          name
          pricePerUnit
          createDate
          carpassType {
            id
            name
            description
          }
          currency {
            ...CurrencyFields
          }
        }
        currency {
          ...CurrencyFields
        }
      }
    }
  }
  ${EventFieldsFragmentDoc}
  ${CurrencyFieldsFragmentDoc}
`;
export type GetCarPassCartProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetCarPassCartQuery,
    Types.GetCarPassCartQueryVariables
  >;
} &
  TChildProps;
export function withGetCarPassCart<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetCarPassCartQuery,
    Types.GetCarPassCartQueryVariables,
    GetCarPassCartProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetCarPassCartQuery,
    Types.GetCarPassCartQueryVariables,
    GetCarPassCartProps<TChildProps, TDataName>
  >(GetCarPassCartDocument, {
    alias: "getCarPassCart",
    ...operationOptions,
  });
}

/**
 * __useGetCarPassCartQuery__
 *
 * To run a query within a React component, call `useGetCarPassCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarPassCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarPassCartQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCarPassCartQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetCarPassCartQuery,
    Types.GetCarPassCartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetCarPassCartQuery,
    Types.GetCarPassCartQueryVariables
  >(GetCarPassCartDocument, options);
}
export function useGetCarPassCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetCarPassCartQuery,
    Types.GetCarPassCartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetCarPassCartQuery,
    Types.GetCarPassCartQueryVariables
  >(GetCarPassCartDocument, options);
}
export type GetCarPassCartQueryHookResult = ReturnType<
  typeof useGetCarPassCartQuery
>;
export type GetCarPassCartLazyQueryHookResult = ReturnType<
  typeof useGetCarPassCartLazyQuery
>;
export type GetCarPassCartQueryResult = Apollo.QueryResult<
  Types.GetCarPassCartQuery,
  Types.GetCarPassCartQueryVariables
>;
export const GetDailyCartDocument = gql`
  query GetDailyCart($id: Int!) {
    getCommunicorBasket(id: $id) {
      eventId {
        ...EventFields
      }
      pricelistId {
        dailyLineIds {
          id
          vehicleTypeId {
            ...VehicleFields
          }
          pricePerDay
          ratePerExtraHour
          ratePerExtraKm
          maxHours
          maxKm
          currency {
            id
            name
            symbol
          }
        }
        currency {
          ...CurrencyFields
        }
      }
    }
  }
  ${EventFieldsFragmentDoc}
  ${VehicleFieldsFragmentDoc}
  ${CurrencyFieldsFragmentDoc}
`;
export type GetDailyCartProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetDailyCartQuery,
    Types.GetDailyCartQueryVariables
  >;
} &
  TChildProps;
export function withGetDailyCart<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetDailyCartQuery,
    Types.GetDailyCartQueryVariables,
    GetDailyCartProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetDailyCartQuery,
    Types.GetDailyCartQueryVariables,
    GetDailyCartProps<TChildProps, TDataName>
  >(GetDailyCartDocument, {
    alias: "getDailyCart",
    ...operationOptions,
  });
}

/**
 * __useGetDailyCartQuery__
 *
 * To run a query within a React component, call `useGetDailyCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyCartQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDailyCartQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetDailyCartQuery,
    Types.GetDailyCartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetDailyCartQuery,
    Types.GetDailyCartQueryVariables
  >(GetDailyCartDocument, options);
}
export function useGetDailyCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetDailyCartQuery,
    Types.GetDailyCartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetDailyCartQuery,
    Types.GetDailyCartQueryVariables
  >(GetDailyCartDocument, options);
}
export type GetDailyCartQueryHookResult = ReturnType<
  typeof useGetDailyCartQuery
>;
export type GetDailyCartLazyQueryHookResult = ReturnType<
  typeof useGetDailyCartLazyQuery
>;
export type GetDailyCartQueryResult = Apollo.QueryResult<
  Types.GetDailyCartQuery,
  Types.GetDailyCartQueryVariables
>;
export const GetEventDocument = gql`
  query GetEvent($eventId: Int!) {
    getCommunicorEvent(id: $eventId) {
      ...EventFields
    }
  }
  ${EventFieldsFragmentDoc}
`;
export type GetEventProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetEventQuery,
    Types.GetEventQueryVariables
  >;
} &
  TChildProps;
export function withGetEvent<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetEventQuery,
    Types.GetEventQueryVariables,
    GetEventProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetEventQuery,
    Types.GetEventQueryVariables,
    GetEventProps<TChildProps, TDataName>
  >(GetEventDocument, {
    alias: "getEvent",
    ...operationOptions,
  });
}

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetEventQuery,
    Types.GetEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetEventQuery, Types.GetEventQueryVariables>(
    GetEventDocument,
    options
  );
}
export function useGetEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetEventQuery,
    Types.GetEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetEventQuery, Types.GetEventQueryVariables>(
    GetEventDocument,
    options
  );
}
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<
  typeof useGetEventLazyQuery
>;
export type GetEventQueryResult = Apollo.QueryResult<
  Types.GetEventQuery,
  Types.GetEventQueryVariables
>;
export const GetFullBasketDocument = gql`
  query GetFullBasket($id: Int!) {
    getCommunicorBasket(id: $id) {
      ...BasketFieldsFull
    }
  }
  ${BasketFieldsFullFragmentDoc}
`;
export type GetFullBasketProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetFullBasketQuery,
    Types.GetFullBasketQueryVariables
  >;
} &
  TChildProps;
export function withGetFullBasket<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetFullBasketQuery,
    Types.GetFullBasketQueryVariables,
    GetFullBasketProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetFullBasketQuery,
    Types.GetFullBasketQueryVariables,
    GetFullBasketProps<TChildProps, TDataName>
  >(GetFullBasketDocument, {
    alias: "getFullBasket",
    ...operationOptions,
  });
}

/**
 * __useGetFullBasketQuery__
 *
 * To run a query within a React component, call `useGetFullBasketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullBasketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullBasketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFullBasketQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetFullBasketQuery,
    Types.GetFullBasketQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetFullBasketQuery,
    Types.GetFullBasketQueryVariables
  >(GetFullBasketDocument, options);
}
export function useGetFullBasketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetFullBasketQuery,
    Types.GetFullBasketQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetFullBasketQuery,
    Types.GetFullBasketQueryVariables
  >(GetFullBasketDocument, options);
}
export type GetFullBasketQueryHookResult = ReturnType<
  typeof useGetFullBasketQuery
>;
export type GetFullBasketLazyQueryHookResult = ReturnType<
  typeof useGetFullBasketLazyQuery
>;
export type GetFullBasketQueryResult = Apollo.QueryResult<
  Types.GetFullBasketQuery,
  Types.GetFullBasketQueryVariables
>;
export const GetMyPassengerDocument = gql`
  query GetMyPassenger($id: Int!) {
    getResUsers(id: $id) {
      ...Passenger
    }
  }
  ${PassengerFragmentDoc}
`;
export type GetMyPassengerProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetMyPassengerQuery,
    Types.GetMyPassengerQueryVariables
  >;
} &
  TChildProps;
export function withGetMyPassenger<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetMyPassengerQuery,
    Types.GetMyPassengerQueryVariables,
    GetMyPassengerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetMyPassengerQuery,
    Types.GetMyPassengerQueryVariables,
    GetMyPassengerProps<TChildProps, TDataName>
  >(GetMyPassengerDocument, {
    alias: "getMyPassenger",
    ...operationOptions,
  });
}

/**
 * __useGetMyPassengerQuery__
 *
 * To run a query within a React component, call `useGetMyPassengerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPassengerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPassengerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMyPassengerQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetMyPassengerQuery,
    Types.GetMyPassengerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetMyPassengerQuery,
    Types.GetMyPassengerQueryVariables
  >(GetMyPassengerDocument, options);
}
export function useGetMyPassengerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetMyPassengerQuery,
    Types.GetMyPassengerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetMyPassengerQuery,
    Types.GetMyPassengerQueryVariables
  >(GetMyPassengerDocument, options);
}
export type GetMyPassengerQueryHookResult = ReturnType<
  typeof useGetMyPassengerQuery
>;
export type GetMyPassengerLazyQueryHookResult = ReturnType<
  typeof useGetMyPassengerLazyQuery
>;
export type GetMyPassengerQueryResult = Apollo.QueryResult<
  Types.GetMyPassengerQuery,
  Types.GetMyPassengerQueryVariables
>;
export const GetTechnicalCartDocument = gql`
  query GetTechnicalCart($id: Int!) {
    getCommunicorBasket(id: $id) {
      eventId {
        ...EventFields
      }
      pricelistId {
        technicalLineIds {
          id
          technicalTypeId {
            id
            name
            isMg
          }
          name
          pricePerUnit
          mgType {
            id
            name
            description
          }
          currency {
            id
            name
            symbol
          }
        }
        currency {
          ...CurrencyFields
        }
      }
    }
  }
  ${EventFieldsFragmentDoc}
  ${CurrencyFieldsFragmentDoc}
`;
export type GetTechnicalCartProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetTechnicalCartQuery,
    Types.GetTechnicalCartQueryVariables
  >;
} &
  TChildProps;
export function withGetTechnicalCart<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetTechnicalCartQuery,
    Types.GetTechnicalCartQueryVariables,
    GetTechnicalCartProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetTechnicalCartQuery,
    Types.GetTechnicalCartQueryVariables,
    GetTechnicalCartProps<TChildProps, TDataName>
  >(GetTechnicalCartDocument, {
    alias: "getTechnicalCart",
    ...operationOptions,
  });
}

/**
 * __useGetTechnicalCartQuery__
 *
 * To run a query within a React component, call `useGetTechnicalCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTechnicalCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTechnicalCartQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTechnicalCartQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetTechnicalCartQuery,
    Types.GetTechnicalCartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetTechnicalCartQuery,
    Types.GetTechnicalCartQueryVariables
  >(GetTechnicalCartDocument, options);
}
export function useGetTechnicalCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetTechnicalCartQuery,
    Types.GetTechnicalCartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetTechnicalCartQuery,
    Types.GetTechnicalCartQueryVariables
  >(GetTechnicalCartDocument, options);
}
export type GetTechnicalCartQueryHookResult = ReturnType<
  typeof useGetTechnicalCartQuery
>;
export type GetTechnicalCartLazyQueryHookResult = ReturnType<
  typeof useGetTechnicalCartLazyQuery
>;
export type GetTechnicalCartQueryResult = Apollo.QueryResult<
  Types.GetTechnicalCartQuery,
  Types.GetTechnicalCartQueryVariables
>;
export const GetIbEventInformationDocument = gql`
  query getIBEventInformation($id: Int!) {
    getCommunicorEvent(id: $id) {
      id
      name
    }
  }
`;
export type GetIbEventInformationProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetIbEventInformationQuery,
    Types.GetIbEventInformationQueryVariables
  >;
} &
  TChildProps;
export function withGetIbEventInformation<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetIbEventInformationQuery,
    Types.GetIbEventInformationQueryVariables,
    GetIbEventInformationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetIbEventInformationQuery,
    Types.GetIbEventInformationQueryVariables,
    GetIbEventInformationProps<TChildProps, TDataName>
  >(GetIbEventInformationDocument, {
    alias: "getIbEventInformation",
    ...operationOptions,
  });
}

/**
 * __useGetIbEventInformationQuery__
 *
 * To run a query within a React component, call `useGetIbEventInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIbEventInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIbEventInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetIbEventInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetIbEventInformationQuery,
    Types.GetIbEventInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetIbEventInformationQuery,
    Types.GetIbEventInformationQueryVariables
  >(GetIbEventInformationDocument, options);
}
export function useGetIbEventInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetIbEventInformationQuery,
    Types.GetIbEventInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetIbEventInformationQuery,
    Types.GetIbEventInformationQueryVariables
  >(GetIbEventInformationDocument, options);
}
export type GetIbEventInformationQueryHookResult = ReturnType<
  typeof useGetIbEventInformationQuery
>;
export type GetIbEventInformationLazyQueryHookResult = ReturnType<
  typeof useGetIbEventInformationLazyQuery
>;
export type GetIbEventInformationQueryResult = Apollo.QueryResult<
  Types.GetIbEventInformationQuery,
  Types.GetIbEventInformationQueryVariables
>;
export const GetBasketInformationsDocument = gql`
  query getBasketInformations($id: Int!) {
    getCommunicorBasket(id: $id) {
      id
      eventId {
        id
        name
      }
      name
      state {
        key
        value
      }
    }
  }
`;
export type GetBasketInformationsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetBasketInformationsQuery,
    Types.GetBasketInformationsQueryVariables
  >;
} &
  TChildProps;
export function withGetBasketInformations<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetBasketInformationsQuery,
    Types.GetBasketInformationsQueryVariables,
    GetBasketInformationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetBasketInformationsQuery,
    Types.GetBasketInformationsQueryVariables,
    GetBasketInformationsProps<TChildProps, TDataName>
  >(GetBasketInformationsDocument, {
    alias: "getBasketInformations",
    ...operationOptions,
  });
}

/**
 * __useGetBasketInformationsQuery__
 *
 * To run a query within a React component, call `useGetBasketInformationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasketInformationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasketInformationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBasketInformationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetBasketInformationsQuery,
    Types.GetBasketInformationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetBasketInformationsQuery,
    Types.GetBasketInformationsQueryVariables
  >(GetBasketInformationsDocument, options);
}
export function useGetBasketInformationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetBasketInformationsQuery,
    Types.GetBasketInformationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetBasketInformationsQuery,
    Types.GetBasketInformationsQueryVariables
  >(GetBasketInformationsDocument, options);
}
export type GetBasketInformationsQueryHookResult = ReturnType<
  typeof useGetBasketInformationsQuery
>;
export type GetBasketInformationsLazyQueryHookResult = ReturnType<
  typeof useGetBasketInformationsLazyQuery
>;
export type GetBasketInformationsQueryResult = Apollo.QueryResult<
  Types.GetBasketInformationsQuery,
  Types.GetBasketInformationsQueryVariables
>;
export const SearchCountriesDocument = gql`
  query searchCountries {
    searchResCountry {
      id
      name
    }
  }
`;
export type SearchCountriesProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.SearchCountriesQuery,
    Types.SearchCountriesQueryVariables
  >;
} &
  TChildProps;
export function withSearchCountries<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.SearchCountriesQuery,
    Types.SearchCountriesQueryVariables,
    SearchCountriesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.SearchCountriesQuery,
    Types.SearchCountriesQueryVariables,
    SearchCountriesProps<TChildProps, TDataName>
  >(SearchCountriesDocument, {
    alias: "searchCountries",
    ...operationOptions,
  });
}

/**
 * __useSearchCountriesQuery__
 *
 * To run a query within a React component, call `useSearchCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.SearchCountriesQuery,
    Types.SearchCountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SearchCountriesQuery,
    Types.SearchCountriesQueryVariables
  >(SearchCountriesDocument, options);
}
export function useSearchCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SearchCountriesQuery,
    Types.SearchCountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SearchCountriesQuery,
    Types.SearchCountriesQueryVariables
  >(SearchCountriesDocument, options);
}
export type SearchCountriesQueryHookResult = ReturnType<
  typeof useSearchCountriesQuery
>;
export type SearchCountriesLazyQueryHookResult = ReturnType<
  typeof useSearchCountriesLazyQuery
>;
export type SearchCountriesQueryResult = Apollo.QueryResult<
  Types.SearchCountriesQuery,
  Types.SearchCountriesQueryVariables
>;
export const SearchCurrenciesDocument = gql`
  query searchCurrencies {
    searchResCurrency {
      id
      displayName
    }
  }
`;
export type SearchCurrenciesProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.SearchCurrenciesQuery,
    Types.SearchCurrenciesQueryVariables
  >;
} &
  TChildProps;
export function withSearchCurrencies<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.SearchCurrenciesQuery,
    Types.SearchCurrenciesQueryVariables,
    SearchCurrenciesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.SearchCurrenciesQuery,
    Types.SearchCurrenciesQueryVariables,
    SearchCurrenciesProps<TChildProps, TDataName>
  >(SearchCurrenciesDocument, {
    alias: "searchCurrencies",
    ...operationOptions,
  });
}

/**
 * __useSearchCurrenciesQuery__
 *
 * To run a query within a React component, call `useSearchCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchCurrenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.SearchCurrenciesQuery,
    Types.SearchCurrenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SearchCurrenciesQuery,
    Types.SearchCurrenciesQueryVariables
  >(SearchCurrenciesDocument, options);
}
export function useSearchCurrenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SearchCurrenciesQuery,
    Types.SearchCurrenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SearchCurrenciesQuery,
    Types.SearchCurrenciesQueryVariables
  >(SearchCurrenciesDocument, options);
}
export type SearchCurrenciesQueryHookResult = ReturnType<
  typeof useSearchCurrenciesQuery
>;
export type SearchCurrenciesLazyQueryHookResult = ReturnType<
  typeof useSearchCurrenciesLazyQuery
>;
export type SearchCurrenciesQueryResult = Apollo.QueryResult<
  Types.SearchCurrenciesQuery,
  Types.SearchCurrenciesQueryVariables
>;
export const FindIbBasketsDocument = gql`
  query findIBBaskets($filter: String!, $limit: Int, $order: String) {
    searchCommunicorBasket(filter: $filter, limit: $limit, order: $order) {
      id
      state {
        key
        value
      }
    }
  }
`;
export type FindIbBasketsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindIbBasketsQuery,
    Types.FindIbBasketsQueryVariables
  >;
} &
  TChildProps;
export function withFindIbBaskets<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindIbBasketsQuery,
    Types.FindIbBasketsQueryVariables,
    FindIbBasketsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindIbBasketsQuery,
    Types.FindIbBasketsQueryVariables,
    FindIbBasketsProps<TChildProps, TDataName>
  >(FindIbBasketsDocument, {
    alias: "findIbBaskets",
    ...operationOptions,
  });
}

/**
 * __useFindIbBasketsQuery__
 *
 * To run a query within a React component, call `useFindIbBasketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindIbBasketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindIbBasketsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useFindIbBasketsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FindIbBasketsQuery,
    Types.FindIbBasketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FindIbBasketsQuery,
    Types.FindIbBasketsQueryVariables
  >(FindIbBasketsDocument, options);
}
export function useFindIbBasketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindIbBasketsQuery,
    Types.FindIbBasketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindIbBasketsQuery,
    Types.FindIbBasketsQueryVariables
  >(FindIbBasketsDocument, options);
}
export type FindIbBasketsQueryHookResult = ReturnType<
  typeof useFindIbBasketsQuery
>;
export type FindIbBasketsLazyQueryHookResult = ReturnType<
  typeof useFindIbBasketsLazyQuery
>;
export type FindIbBasketsQueryResult = Apollo.QueryResult<
  Types.FindIbBasketsQuery,
  Types.FindIbBasketsQueryVariables
>;
export const SearchEventInvitationDocument = gql`
  query searchEventInvitation($filter: String!) {
    searchCommunicorEventInvitation(filter: $filter) {
      id
      eventId {
        id
      }
      masterAccountId {
        id
        name
        parentName
      }
    }
  }
`;
export type SearchEventInvitationProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.SearchEventInvitationQuery,
    Types.SearchEventInvitationQueryVariables
  >;
} &
  TChildProps;
export function withSearchEventInvitation<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.SearchEventInvitationQuery,
    Types.SearchEventInvitationQueryVariables,
    SearchEventInvitationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.SearchEventInvitationQuery,
    Types.SearchEventInvitationQueryVariables,
    SearchEventInvitationProps<TChildProps, TDataName>
  >(SearchEventInvitationDocument, {
    alias: "searchEventInvitation",
    ...operationOptions,
  });
}

/**
 * __useSearchEventInvitationQuery__
 *
 * To run a query within a React component, call `useSearchEventInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchEventInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchEventInvitationQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchEventInvitationQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SearchEventInvitationQuery,
    Types.SearchEventInvitationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SearchEventInvitationQuery,
    Types.SearchEventInvitationQueryVariables
  >(SearchEventInvitationDocument, options);
}
export function useSearchEventInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SearchEventInvitationQuery,
    Types.SearchEventInvitationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SearchEventInvitationQuery,
    Types.SearchEventInvitationQueryVariables
  >(SearchEventInvitationDocument, options);
}
export type SearchEventInvitationQueryHookResult = ReturnType<
  typeof useSearchEventInvitationQuery
>;
export type SearchEventInvitationLazyQueryHookResult = ReturnType<
  typeof useSearchEventInvitationLazyQuery
>;
export type SearchEventInvitationQueryResult = Apollo.QueryResult<
  Types.SearchEventInvitationQuery,
  Types.SearchEventInvitationQueryVariables
>;
export const ListMyPassengersDocument = gql`
  query listMyPassengers {
    searchResUsers(domain: "[('role','=','passenger')]") {
      ...Passenger
    }
  }
  ${PassengerFragmentDoc}
`;
export type ListMyPassengersProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.ListMyPassengersQuery,
    Types.ListMyPassengersQueryVariables
  >;
} &
  TChildProps;
export function withListMyPassengers<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.ListMyPassengersQuery,
    Types.ListMyPassengersQueryVariables,
    ListMyPassengersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.ListMyPassengersQuery,
    Types.ListMyPassengersQueryVariables,
    ListMyPassengersProps<TChildProps, TDataName>
  >(ListMyPassengersDocument, {
    alias: "listMyPassengers",
    ...operationOptions,
  });
}

/**
 * __useListMyPassengersQuery__
 *
 * To run a query within a React component, call `useListMyPassengersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyPassengersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyPassengersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListMyPassengersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ListMyPassengersQuery,
    Types.ListMyPassengersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListMyPassengersQuery,
    Types.ListMyPassengersQueryVariables
  >(ListMyPassengersDocument, options);
}
export function useListMyPassengersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListMyPassengersQuery,
    Types.ListMyPassengersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListMyPassengersQuery,
    Types.ListMyPassengersQueryVariables
  >(ListMyPassengersDocument, options);
}
export type ListMyPassengersQueryHookResult = ReturnType<
  typeof useListMyPassengersQuery
>;
export type ListMyPassengersLazyQueryHookResult = ReturnType<
  typeof useListMyPassengersLazyQuery
>;
export type ListMyPassengersQueryResult = Apollo.QueryResult<
  Types.ListMyPassengersQuery,
  Types.ListMyPassengersQueryVariables
>;
export const LoggedInDocument = gql`
  query LoggedIn {
    isLoggedIn @client
  }
`;
export type LoggedInProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.LoggedInQuery,
    Types.LoggedInQueryVariables
  >;
} &
  TChildProps;
export function withLoggedIn<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.LoggedInQuery,
    Types.LoggedInQueryVariables,
    LoggedInProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.LoggedInQuery,
    Types.LoggedInQueryVariables,
    LoggedInProps<TChildProps, TDataName>
  >(LoggedInDocument, {
    alias: "loggedIn",
    ...operationOptions,
  });
}

/**
 * __useLoggedInQuery__
 *
 * To run a query within a React component, call `useLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.LoggedInQuery,
    Types.LoggedInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.LoggedInQuery, Types.LoggedInQueryVariables>(
    LoggedInDocument,
    options
  );
}
export function useLoggedInLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.LoggedInQuery,
    Types.LoggedInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.LoggedInQuery, Types.LoggedInQueryVariables>(
    LoggedInDocument,
    options
  );
}
export type LoggedInQueryHookResult = ReturnType<typeof useLoggedInQuery>;
export type LoggedInLazyQueryHookResult = ReturnType<
  typeof useLoggedInLazyQuery
>;
export type LoggedInQueryResult = Apollo.QueryResult<
  Types.LoggedInQuery,
  Types.LoggedInQueryVariables
>;
export const FindMaEventDocument = gql`
  query FindMAEvent($filter: String!) {
    searchCommunicorEvent(filter: $filter) {
      id
      name
    }
  }
`;
export type FindMaEventProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindMaEventQuery,
    Types.FindMaEventQueryVariables
  >;
} &
  TChildProps;
export function withFindMaEvent<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindMaEventQuery,
    Types.FindMaEventQueryVariables,
    FindMaEventProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindMaEventQuery,
    Types.FindMaEventQueryVariables,
    FindMaEventProps<TChildProps, TDataName>
  >(FindMaEventDocument, {
    alias: "findMaEvent",
    ...operationOptions,
  });
}

/**
 * __useFindMaEventQuery__
 *
 * To run a query within a React component, call `useFindMaEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMaEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMaEventQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindMaEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FindMaEventQuery,
    Types.FindMaEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FindMaEventQuery,
    Types.FindMaEventQueryVariables
  >(FindMaEventDocument, options);
}
export function useFindMaEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindMaEventQuery,
    Types.FindMaEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindMaEventQuery,
    Types.FindMaEventQueryVariables
  >(FindMaEventDocument, options);
}
export type FindMaEventQueryHookResult = ReturnType<typeof useFindMaEventQuery>;
export type FindMaEventLazyQueryHookResult = ReturnType<
  typeof useFindMaEventLazyQuery
>;
export type FindMaEventQueryResult = Apollo.QueryResult<
  Types.FindMaEventQuery,
  Types.FindMaEventQueryVariables
>;
export const MyBasketsDocument = gql`
  query MyBaskets {
    getMyData {
      basketIds {
        ...BasketFieldsLight
      }
    }
  }
  ${BasketFieldsLightFragmentDoc}
`;
export type MyBasketsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.MyBasketsQuery,
    Types.MyBasketsQueryVariables
  >;
} &
  TChildProps;
export function withMyBaskets<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.MyBasketsQuery,
    Types.MyBasketsQueryVariables,
    MyBasketsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.MyBasketsQuery,
    Types.MyBasketsQueryVariables,
    MyBasketsProps<TChildProps, TDataName>
  >(MyBasketsDocument, {
    alias: "myBaskets",
    ...operationOptions,
  });
}

/**
 * __useMyBasketsQuery__
 *
 * To run a query within a React component, call `useMyBasketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBasketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBasketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyBasketsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.MyBasketsQuery,
    Types.MyBasketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.MyBasketsQuery, Types.MyBasketsQueryVariables>(
    MyBasketsDocument,
    options
  );
}
export function useMyBasketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MyBasketsQuery,
    Types.MyBasketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.MyBasketsQuery,
    Types.MyBasketsQueryVariables
  >(MyBasketsDocument, options);
}
export type MyBasketsQueryHookResult = ReturnType<typeof useMyBasketsQuery>;
export type MyBasketsLazyQueryHookResult = ReturnType<
  typeof useMyBasketsLazyQuery
>;
export type MyBasketsQueryResult = Apollo.QueryResult<
  Types.MyBasketsQuery,
  Types.MyBasketsQueryVariables
>;
export const FindInvitedIbDocument = gql`
  query findInvitedIB($filter: String!, $limit: Int, $offset: Int) {
    searchCommunicorEventInvitation(
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      id
      individualBookerId {
        id
        displayName
        basketIds {
          id
          eventId {
            id
          }
          saleOrderId {
            id
            invoiceCount
          }
        }
      }
      eventId {
        id
      }
      masterAccountId {
        id
      }
    }
  }
`;
export type FindInvitedIbProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindInvitedIbQuery,
    Types.FindInvitedIbQueryVariables
  >;
} &
  TChildProps;
export function withFindInvitedIb<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindInvitedIbQuery,
    Types.FindInvitedIbQueryVariables,
    FindInvitedIbProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindInvitedIbQuery,
    Types.FindInvitedIbQueryVariables,
    FindInvitedIbProps<TChildProps, TDataName>
  >(FindInvitedIbDocument, {
    alias: "findInvitedIb",
    ...operationOptions,
  });
}

/**
 * __useFindInvitedIbQuery__
 *
 * To run a query within a React component, call `useFindInvitedIbQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindInvitedIbQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindInvitedIbQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFindInvitedIbQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FindInvitedIbQuery,
    Types.FindInvitedIbQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FindInvitedIbQuery,
    Types.FindInvitedIbQueryVariables
  >(FindInvitedIbDocument, options);
}
export function useFindInvitedIbLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindInvitedIbQuery,
    Types.FindInvitedIbQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindInvitedIbQuery,
    Types.FindInvitedIbQueryVariables
  >(FindInvitedIbDocument, options);
}
export type FindInvitedIbQueryHookResult = ReturnType<
  typeof useFindInvitedIbQuery
>;
export type FindInvitedIbLazyQueryHookResult = ReturnType<
  typeof useFindInvitedIbLazyQuery
>;
export type FindInvitedIbQueryResult = Apollo.QueryResult<
  Types.FindInvitedIbQuery,
  Types.FindInvitedIbQueryVariables
>;
export const GetEventInformationDocument = gql`
  query getEventInformation($id: Int!) {
    getCommunicorEvent(id: $id) {
      id
      name
      startDate
      endDate
    }
  }
`;
export type GetEventInformationProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetEventInformationQuery,
    Types.GetEventInformationQueryVariables
  >;
} &
  TChildProps;
export function withGetEventInformation<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetEventInformationQuery,
    Types.GetEventInformationQueryVariables,
    GetEventInformationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetEventInformationQuery,
    Types.GetEventInformationQueryVariables,
    GetEventInformationProps<TChildProps, TDataName>
  >(GetEventInformationDocument, {
    alias: "getEventInformation",
    ...operationOptions,
  });
}

/**
 * __useGetEventInformationQuery__
 *
 * To run a query within a React component, call `useGetEventInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetEventInformationQuery,
    Types.GetEventInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetEventInformationQuery,
    Types.GetEventInformationQueryVariables
  >(GetEventInformationDocument, options);
}
export function useGetEventInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetEventInformationQuery,
    Types.GetEventInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetEventInformationQuery,
    Types.GetEventInformationQueryVariables
  >(GetEventInformationDocument, options);
}
export type GetEventInformationQueryHookResult = ReturnType<
  typeof useGetEventInformationQuery
>;
export type GetEventInformationLazyQueryHookResult = ReturnType<
  typeof useGetEventInformationLazyQuery
>;
export type GetEventInformationQueryResult = Apollo.QueryResult<
  Types.GetEventInformationQuery,
  Types.GetEventInformationQueryVariables
>;
export const GetInvitedIndividualsDocument = gql`
  query getInvitedIndividuals($filter: String!) {
    searchCommunicorEventInvitation(filter: $filter) {
      id
      individualBookerId {
        ...IBFields
      }
    }
  }
  ${IbFieldsFragmentDoc}
`;
export type GetInvitedIndividualsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetInvitedIndividualsQuery,
    Types.GetInvitedIndividualsQueryVariables
  >;
} &
  TChildProps;
export function withGetInvitedIndividuals<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetInvitedIndividualsQuery,
    Types.GetInvitedIndividualsQueryVariables,
    GetInvitedIndividualsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetInvitedIndividualsQuery,
    Types.GetInvitedIndividualsQueryVariables,
    GetInvitedIndividualsProps<TChildProps, TDataName>
  >(GetInvitedIndividualsDocument, {
    alias: "getInvitedIndividuals",
    ...operationOptions,
  });
}

/**
 * __useGetInvitedIndividualsQuery__
 *
 * To run a query within a React component, call `useGetInvitedIndividualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitedIndividualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitedIndividualsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetInvitedIndividualsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetInvitedIndividualsQuery,
    Types.GetInvitedIndividualsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetInvitedIndividualsQuery,
    Types.GetInvitedIndividualsQueryVariables
  >(GetInvitedIndividualsDocument, options);
}
export function useGetInvitedIndividualsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetInvitedIndividualsQuery,
    Types.GetInvitedIndividualsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetInvitedIndividualsQuery,
    Types.GetInvitedIndividualsQueryVariables
  >(GetInvitedIndividualsDocument, options);
}
export type GetInvitedIndividualsQueryHookResult = ReturnType<
  typeof useGetInvitedIndividualsQuery
>;
export type GetInvitedIndividualsLazyQueryHookResult = ReturnType<
  typeof useGetInvitedIndividualsLazyQuery
>;
export type GetInvitedIndividualsQueryResult = Apollo.QueryResult<
  Types.GetInvitedIndividualsQuery,
  Types.GetInvitedIndividualsQueryVariables
>;
export const FindIbReservationsDocument = gql`
  query findIBReservations($filter: String!, $limit: Int, $order: String) {
    searchCommunicorBasket(filter: $filter, limit: $limit, order: $order) {
      id
      createDate
      eventId {
        id
        name
      }
      state {
        key
        value
      }
      saleOrderId {
        ...SaleOrderFields
      }
    }
  }
  ${SaleOrderFieldsFragmentDoc}
`;
export type FindIbReservationsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindIbReservationsQuery,
    Types.FindIbReservationsQueryVariables
  >;
} &
  TChildProps;
export function withFindIbReservations<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindIbReservationsQuery,
    Types.FindIbReservationsQueryVariables,
    FindIbReservationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindIbReservationsQuery,
    Types.FindIbReservationsQueryVariables,
    FindIbReservationsProps<TChildProps, TDataName>
  >(FindIbReservationsDocument, {
    alias: "findIbReservations",
    ...operationOptions,
  });
}

/**
 * __useFindIbReservationsQuery__
 *
 * To run a query within a React component, call `useFindIbReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindIbReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindIbReservationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useFindIbReservationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FindIbReservationsQuery,
    Types.FindIbReservationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FindIbReservationsQuery,
    Types.FindIbReservationsQueryVariables
  >(FindIbReservationsDocument, options);
}
export function useFindIbReservationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindIbReservationsQuery,
    Types.FindIbReservationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindIbReservationsQuery,
    Types.FindIbReservationsQueryVariables
  >(FindIbReservationsDocument, options);
}
export type FindIbReservationsQueryHookResult = ReturnType<
  typeof useFindIbReservationsQuery
>;
export type FindIbReservationsLazyQueryHookResult = ReturnType<
  typeof useFindIbReservationsLazyQuery
>;
export type FindIbReservationsQueryResult = Apollo.QueryResult<
  Types.FindIbReservationsQuery,
  Types.FindIbReservationsQueryVariables
>;
export const GetIbInformationsDocument = gql`
  query getIBInformations($id: Int!) {
    getResUsers(id: $id) {
      id
      name
    }
  }
`;
export type GetIbInformationsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetIbInformationsQuery,
    Types.GetIbInformationsQueryVariables
  >;
} &
  TChildProps;
export function withGetIbInformations<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetIbInformationsQuery,
    Types.GetIbInformationsQueryVariables,
    GetIbInformationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetIbInformationsQuery,
    Types.GetIbInformationsQueryVariables,
    GetIbInformationsProps<TChildProps, TDataName>
  >(GetIbInformationsDocument, {
    alias: "getIbInformations",
    ...operationOptions,
  });
}

/**
 * __useGetIbInformationsQuery__
 *
 * To run a query within a React component, call `useGetIbInformationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIbInformationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIbInformationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetIbInformationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetIbInformationsQuery,
    Types.GetIbInformationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetIbInformationsQuery,
    Types.GetIbInformationsQueryVariables
  >(GetIbInformationsDocument, options);
}
export function useGetIbInformationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetIbInformationsQuery,
    Types.GetIbInformationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetIbInformationsQuery,
    Types.GetIbInformationsQueryVariables
  >(GetIbInformationsDocument, options);
}
export type GetIbInformationsQueryHookResult = ReturnType<
  typeof useGetIbInformationsQuery
>;
export type GetIbInformationsLazyQueryHookResult = ReturnType<
  typeof useGetIbInformationsLazyQuery
>;
export type GetIbInformationsQueryResult = Apollo.QueryResult<
  Types.GetIbInformationsQuery,
  Types.GetIbInformationsQueryVariables
>;
export const FindUserTitleDocument = gql`
  query FindUserTitle {
    searchResPartnerTitle {
      id
      name
    }
  }
`;
export type FindUserTitleProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindUserTitleQuery,
    Types.FindUserTitleQueryVariables
  >;
} &
  TChildProps;
export function withFindUserTitle<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindUserTitleQuery,
    Types.FindUserTitleQueryVariables,
    FindUserTitleProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindUserTitleQuery,
    Types.FindUserTitleQueryVariables,
    FindUserTitleProps<TChildProps, TDataName>
  >(FindUserTitleDocument, {
    alias: "findUserTitle",
    ...operationOptions,
  });
}

/**
 * __useFindUserTitleQuery__
 *
 * To run a query within a React component, call `useFindUserTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserTitleQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindUserTitleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FindUserTitleQuery,
    Types.FindUserTitleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FindUserTitleQuery,
    Types.FindUserTitleQueryVariables
  >(FindUserTitleDocument, options);
}
export function useFindUserTitleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindUserTitleQuery,
    Types.FindUserTitleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindUserTitleQuery,
    Types.FindUserTitleQueryVariables
  >(FindUserTitleDocument, options);
}
export type FindUserTitleQueryHookResult = ReturnType<
  typeof useFindUserTitleQuery
>;
export type FindUserTitleLazyQueryHookResult = ReturnType<
  typeof useFindUserTitleLazyQuery
>;
export type FindUserTitleQueryResult = Apollo.QueryResult<
  Types.FindUserTitleQuery,
  Types.FindUserTitleQueryVariables
>;
export const GetProfileDocument = gql`
  query getProfile {
    getMyData {
      id
      name
      firstname
      phone
      title {
        id
      }
    }
  }
`;
export type GetProfileProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetProfileQuery,
    Types.GetProfileQueryVariables
  >;
} &
  TChildProps;
export function withGetProfile<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetProfileQuery,
    Types.GetProfileQueryVariables,
    GetProfileProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetProfileQuery,
    Types.GetProfileQueryVariables,
    GetProfileProps<TChildProps, TDataName>
  >(GetProfileDocument, {
    alias: "getProfile",
    ...operationOptions,
  });
}

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetProfileQuery,
    Types.GetProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetProfileQuery, Types.GetProfileQueryVariables>(
    GetProfileDocument,
    options
  );
}
export function useGetProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetProfileQuery,
    Types.GetProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetProfileQuery,
    Types.GetProfileQueryVariables
  >(GetProfileDocument, options);
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<
  typeof useGetProfileLazyQuery
>;
export type GetProfileQueryResult = Apollo.QueryResult<
  Types.GetProfileQuery,
  Types.GetProfileQueryVariables
>;
export const SearchPricelistDocument = gql`
  query searchPricelist($filter: String!, $limit: Int, $order: String) {
    searchCommunicorPricelist(filter: $filter, limit: $limit, order: $order) {
      ...PricelistFieldsFull
    }
  }
  ${PricelistFieldsFullFragmentDoc}
`;
export type SearchPricelistProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.SearchPricelistQuery,
    Types.SearchPricelistQueryVariables
  >;
} &
  TChildProps;
export function withSearchPricelist<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.SearchPricelistQuery,
    Types.SearchPricelistQueryVariables,
    SearchPricelistProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.SearchPricelistQuery,
    Types.SearchPricelistQueryVariables,
    SearchPricelistProps<TChildProps, TDataName>
  >(SearchPricelistDocument, {
    alias: "searchPricelist",
    ...operationOptions,
  });
}

/**
 * __useSearchPricelistQuery__
 *
 * To run a query within a React component, call `useSearchPricelistQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPricelistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPricelistQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSearchPricelistQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SearchPricelistQuery,
    Types.SearchPricelistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SearchPricelistQuery,
    Types.SearchPricelistQueryVariables
  >(SearchPricelistDocument, options);
}
export function useSearchPricelistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SearchPricelistQuery,
    Types.SearchPricelistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SearchPricelistQuery,
    Types.SearchPricelistQueryVariables
  >(SearchPricelistDocument, options);
}
export type SearchPricelistQueryHookResult = ReturnType<
  typeof useSearchPricelistQuery
>;
export type SearchPricelistLazyQueryHookResult = ReturnType<
  typeof useSearchPricelistLazyQuery
>;
export type SearchPricelistQueryResult = Apollo.QueryResult<
  Types.SearchPricelistQuery,
  Types.SearchPricelistQueryVariables
>;
export const GetMyEmailDocument = gql`
  query getMyEmail {
    getMyData {
      id
      email
    }
  }
`;
export type GetMyEmailProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetMyEmailQuery,
    Types.GetMyEmailQueryVariables
  >;
} &
  TChildProps;
export function withGetMyEmail<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetMyEmailQuery,
    Types.GetMyEmailQueryVariables,
    GetMyEmailProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetMyEmailQuery,
    Types.GetMyEmailQueryVariables,
    GetMyEmailProps<TChildProps, TDataName>
  >(GetMyEmailDocument, {
    alias: "getMyEmail",
    ...operationOptions,
  });
}

/**
 * __useGetMyEmailQuery__
 *
 * To run a query within a React component, call `useGetMyEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyEmailQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyEmailQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetMyEmailQuery,
    Types.GetMyEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetMyEmailQuery, Types.GetMyEmailQueryVariables>(
    GetMyEmailDocument,
    options
  );
}
export function useGetMyEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetMyEmailQuery,
    Types.GetMyEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetMyEmailQuery,
    Types.GetMyEmailQueryVariables
  >(GetMyEmailDocument, options);
}
export type GetMyEmailQueryHookResult = ReturnType<typeof useGetMyEmailQuery>;
export type GetMyEmailLazyQueryHookResult = ReturnType<
  typeof useGetMyEmailLazyQuery
>;
export type GetMyEmailQueryResult = Apollo.QueryResult<
  Types.GetMyEmailQuery,
  Types.GetMyEmailQueryVariables
>;
export const GetCountryInformationsDocument = gql`
  query getCountryInformations($id: Int!) {
    getResCountry(id: $id) {
      id
      name
    }
  }
`;
export type GetCountryInformationsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetCountryInformationsQuery,
    Types.GetCountryInformationsQueryVariables
  >;
} &
  TChildProps;
export function withGetCountryInformations<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetCountryInformationsQuery,
    Types.GetCountryInformationsQueryVariables,
    GetCountryInformationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetCountryInformationsQuery,
    Types.GetCountryInformationsQueryVariables,
    GetCountryInformationsProps<TChildProps, TDataName>
  >(GetCountryInformationsDocument, {
    alias: "getCountryInformations",
    ...operationOptions,
  });
}

/**
 * __useGetCountryInformationsQuery__
 *
 * To run a query within a React component, call `useGetCountryInformationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryInformationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryInformationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCountryInformationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetCountryInformationsQuery,
    Types.GetCountryInformationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetCountryInformationsQuery,
    Types.GetCountryInformationsQueryVariables
  >(GetCountryInformationsDocument, options);
}
export function useGetCountryInformationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetCountryInformationsQuery,
    Types.GetCountryInformationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetCountryInformationsQuery,
    Types.GetCountryInformationsQueryVariables
  >(GetCountryInformationsDocument, options);
}
export type GetCountryInformationsQueryHookResult = ReturnType<
  typeof useGetCountryInformationsQuery
>;
export type GetCountryInformationsLazyQueryHookResult = ReturnType<
  typeof useGetCountryInformationsLazyQuery
>;
export type GetCountryInformationsQueryResult = Apollo.QueryResult<
  Types.GetCountryInformationsQuery,
  Types.GetCountryInformationsQueryVariables
>;
export const GetDivisionInformationsDocument = gql`
  query getDivisionInformations($id: Int!) {
    getResPartner(id: $id) {
      id
      name
      street
      street2
      countryId {
        id
        name
      }
      city
      zip
    }
  }
`;
export type GetDivisionInformationsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetDivisionInformationsQuery,
    Types.GetDivisionInformationsQueryVariables
  >;
} &
  TChildProps;
export function withGetDivisionInformations<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetDivisionInformationsQuery,
    Types.GetDivisionInformationsQueryVariables,
    GetDivisionInformationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetDivisionInformationsQuery,
    Types.GetDivisionInformationsQueryVariables,
    GetDivisionInformationsProps<TChildProps, TDataName>
  >(GetDivisionInformationsDocument, {
    alias: "getDivisionInformations",
    ...operationOptions,
  });
}

/**
 * __useGetDivisionInformationsQuery__
 *
 * To run a query within a React component, call `useGetDivisionInformationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDivisionInformationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDivisionInformationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDivisionInformationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetDivisionInformationsQuery,
    Types.GetDivisionInformationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetDivisionInformationsQuery,
    Types.GetDivisionInformationsQueryVariables
  >(GetDivisionInformationsDocument, options);
}
export function useGetDivisionInformationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetDivisionInformationsQuery,
    Types.GetDivisionInformationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetDivisionInformationsQuery,
    Types.GetDivisionInformationsQueryVariables
  >(GetDivisionInformationsDocument, options);
}
export type GetDivisionInformationsQueryHookResult = ReturnType<
  typeof useGetDivisionInformationsQuery
>;
export type GetDivisionInformationsLazyQueryHookResult = ReturnType<
  typeof useGetDivisionInformationsLazyQuery
>;
export type GetDivisionInformationsQueryResult = Apollo.QueryResult<
  Types.GetDivisionInformationsQuery,
  Types.GetDivisionInformationsQueryVariables
>;
export const GetCompanyInformationsDocument = gql`
  query getCompanyInformations($id: Int!) {
    getResPartner(id: $id) {
      id
      name
      divisionIds {
        ...DivisionInformations
      }
    }
  }
  ${DivisionInformationsFragmentDoc}
`;
export type GetCompanyInformationsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetCompanyInformationsQuery,
    Types.GetCompanyInformationsQueryVariables
  >;
} &
  TChildProps;
export function withGetCompanyInformations<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetCompanyInformationsQuery,
    Types.GetCompanyInformationsQueryVariables,
    GetCompanyInformationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetCompanyInformationsQuery,
    Types.GetCompanyInformationsQueryVariables,
    GetCompanyInformationsProps<TChildProps, TDataName>
  >(GetCompanyInformationsDocument, {
    alias: "getCompanyInformations",
    ...operationOptions,
  });
}

/**
 * __useGetCompanyInformationsQuery__
 *
 * To run a query within a React component, call `useGetCompanyInformationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyInformationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyInformationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyInformationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetCompanyInformationsQuery,
    Types.GetCompanyInformationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetCompanyInformationsQuery,
    Types.GetCompanyInformationsQueryVariables
  >(GetCompanyInformationsDocument, options);
}
export function useGetCompanyInformationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetCompanyInformationsQuery,
    Types.GetCompanyInformationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetCompanyInformationsQuery,
    Types.GetCompanyInformationsQueryVariables
  >(GetCompanyInformationsDocument, options);
}
export type GetCompanyInformationsQueryHookResult = ReturnType<
  typeof useGetCompanyInformationsQuery
>;
export type GetCompanyInformationsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyInformationsLazyQuery
>;
export type GetCompanyInformationsQueryResult = Apollo.QueryResult<
  Types.GetCompanyInformationsQuery,
  Types.GetCompanyInformationsQueryVariables
>;
