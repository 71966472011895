/* Framework imports ----------------------------------- */
import React, {
  useState,
  useMemo
} from 'react';

/* Module imports -------------------------------------- */
import useMyPassengersStyles from '../MyPassengersStyles';

/* Component imports ----------------------------------- */
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { DeleteForever, Edit, Save } from '@material-ui/icons';

/* Type imports ---------------------------------------- */
import { PassengerFragment } from 'apollo/queries/types';
import { useDeletePassengerMutation, useEditPassengerMutation } from 'apollo/mutations';
import CustomTextfiled from './CustomTextfield/CustomTextfield';
import CustomRadio from './CustomRadio/CustomRadio';

/* PassengerDetails component prop types -------------------- */
interface PassengerDetailsProps {
  passenger: PassengerFragment;
  refetch: Function;
}

/* PassengerDetails component ------------------------------- */
const PassengerDetails: React.FC<PassengerDetailsProps> = ({ passenger, refetch }) => {
  const classes = useMyPassengersStyles();

  const [formValues, setFormValues] = useState<PassengerFragment>(passenger);
  const [editable, setEditable] = useState<boolean>(false);
  const [openDeleteModale, setOpenDeleteModale] = useState<boolean>(false);

  const [editPassengerMutation] = useEditPassengerMutation();

  const editPassenger = async (): Promise<void> => {
    if (
      formValues.firstname !== undefined && formValues.firstname !== null && formValues.firstname.length > 0 &&
      formValues.name !== undefined && formValues.name !== null && formValues.name.length > 0 &&
      formValues.email !== undefined && formValues.email !== null && formValues.email.length > 0 &&
      formValues.phone !== undefined && formValues.phone !== null && formValues.phone.length > 0 &&
      (
        !formValues.isPassengersGroup ||
        ((formValues.groupName?.length || '') > 0)
      )
    ) {
      try {
        const variables = {
          id: passenger.id,
          firstname: formValues.firstname,
          name: formValues.name,
          email: formValues.email,
          phone: formValues.phone,
        };
        await editPassengerMutation({
          variables: formValues.isPassengersGroup
            ? {
              ...variables,
              isPassengersGroup: true,
              groupName: formValues.groupName,
              groupSize: formValues.groupSize,
            } :
            {
              ...variables,
              isPassengersGroup: false,
            }
        });
        refetch();
        setEditable(false);
      } catch (error) {
        console.error(`[PassengerDetails.tsx] EditPassenger error : ${JSON.stringify(error)}`);
      }
    }
  };

  const [deletePassengerMutation] = useDeletePassengerMutation();

  const deletePassenger = async (): Promise<void> => {
    try {
      await deletePassengerMutation({
        variables: {
          id: passenger.id
        }
      });
    } catch (error) {
      console.error(`[PassengerDetails.tsx] DeletePassenger error : ${JSON.stringify(error)}`);
    }
  };

  const isDisabled = useMemo(() => {
    return (
      formValues.firstname === null || formValues.firstname === undefined || formValues.firstname.length === 0 ||
      formValues.name === null || formValues.name === undefined || formValues.name.length === 0 ||
      formValues.email === null || formValues.email === undefined || formValues.email.length === 0 ||
      formValues.phone === null || formValues.phone === undefined || formValues.phone.length === 0 ||
      (formValues.isPassengersGroup &&
        (
          (formValues.groupName === null || formValues.groupName === undefined || formValues.groupName.length === 0) ||
          (formValues.groupSize === null || formValues.groupSize === undefined || isNaN(formValues.groupSize) || formValues.groupSize < 2)
        )
      )
    );
  }, [
    formValues.email,
    formValues.firstname,
    formValues.groupName,
    formValues.groupSize,
    formValues.isPassengersGroup,
    formValues.name,
    formValues.phone,
  ]);

  return (
    <>
      <Grid item xs={6} lg={4} key={passenger.id}>
        <Card className={classes.card}>
          <CardContent>
            <Grid container>
              <Grid xs={10}>
                <div>
                  <CustomRadio
                    editable={editable}
                    onChange={(value) => setFormValues({
                      ...formValues,
                      isPassengersGroup: value === 'individual' ? false : true,
                      groupSize: value === 'individual' ?
                        formValues.groupSize :
                        formValues.groupSize !== undefined && formValues.groupSize !== null && formValues.groupSize > 2 ? formValues.groupSize : 2
                    })}
                    value={formValues.isPassengersGroup ? 'group' : 'individual'}
                    label="Group type"
                  />
                </div>
                {
                  formValues.isPassengersGroup &&
                  <>
                    <div>
                      <CustomTextfiled
                        editable={editable}
                        label="Group name"
                        value={formValues.groupName}
                        onChange={(value) => setFormValues({ ...formValues, groupName: value })}
                      />
                    </div>
                    <div>
                      <CustomTextfiled
                        editable={editable}
                        label="Group size"
                        value={formValues.groupSize}
                        onChange={(value) => setFormValues({ ...formValues, groupSize: parseInt(value) })}
                        type="number"
                      />
                    </div>
                  </>
                }
                <div>
                  <CustomTextfiled
                    editable={editable}
                    label={`${formValues.isPassengersGroup ? 'Point of contact\'s last name' : 'Passenger\'s last name'}`}
                    value={formValues.name}
                    onChange={(value) => setFormValues({ ...formValues, name: value })}
                  />
                </div>
                <div>
                  <CustomTextfiled
                    editable={editable}
                    label={`${formValues.isPassengersGroup ? 'Point of contact\'s first name' : 'Passenger\'s first name'}`}
                    value={formValues.firstname}
                    onChange={(value) => setFormValues({ ...formValues, firstname: value })}
                  />
                </div>
                <div>
                  <CustomTextfiled
                    editable={editable}
                    label={`${formValues.isPassengersGroup ? 'Point of contact\'s mail' : 'Passenger\'s mail'}`}
                    value={formValues.email}
                    onChange={(value) => setFormValues({ ...formValues, email: value })}
                  />
                </div>
                <div>
                  <CustomTextfiled
                    editable={editable}
                    label={`${formValues.isPassengersGroup ? 'Point of contact\'s phone number' : 'Passenger\'s phone number'}`}
                    value={formValues.phone}
                    onChange={(value) => setFormValues({ ...formValues, phone: value })}
                  />
                </div>
              </Grid>
              <Grid xs={2}>
                {!editable ?
                  <Edit
                    className={classes.cardButton}
                    onClick={() => setEditable(true)}
                  />
                  :
                  <Save
                    className={
                      isDisabled ?
                        classes['cardButton-disabled']
                        : classes.cardButton}
                    onClick={() => {
                      if (!isDisabled) {
                        editPassenger();
                      }
                    }
                    }
                    color={
                      isDisabled ?
                        'disabled' :
                        'inherit'
                    }
                  />
                }
                <DeleteForever className={classes.cardButton} onClick={() => setOpenDeleteModale(true)} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Dialog
        open={openDeleteModale}
        onClose={() => setOpenDeleteModale(false)}
      >
        <DialogTitle> Deleting passenger </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            Are you sure to delete the passenger {formValues.isPassengersGroup ? formValues.groupName : `${formValues.firstname} ${formValues.name}`} ?
          </Grid>
          <DialogActions>
            <Button
              onClick={() => deletePassenger()}
              color="primary"
              autoFocus
            >
              Validate
            </Button>
            <Button
              onClick={() => setOpenDeleteModale(false)}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>

          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

/* Export PassengerDetails component ------------------------ */
export default PassengerDetails;
