import { Button, CircularProgress, TableCell, TableRow, Typography } from '@material-ui/core';
import { useCurrentUserQuery, useGetInvitedIndividualsQuery, useMyBasketsQuery } from 'apollo/queries';
import { CommunicorEvent } from 'apollo/types';
import { useHistory } from 'react-router-dom';
import compile from 'utils/domain';
import * as Luq from 'luq';
import { formatDate } from 'utils/formatDate';
import { useMemo } from 'react';

interface Props {
  event: ({
    __typename?: 'CommunicorEvent' | undefined;
  } & Pick<CommunicorEvent, 'id' | 'name' | 'startDate' | 'endDate'>)
}

const FORMAT_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric'
};

const PMEventTableRow: React.FC<Props> = ({ event }) => {

  const history = useHistory();

  const { data: getUserData } = useCurrentUserQuery();

  const { data, loading } = useGetInvitedIndividualsQuery({
    variables: {
      filter: compile(
        <Luq.And>
          <Luq.Eq field="eventId" value={event.id} />
          <Luq.Eq field="masterAccountId" value={getUserData?.getMyData?.id} />
        </Luq.And>
      )
    },
    skip: event.id === undefined || getUserData?.getMyData?.id === undefined,
    fetchPolicy: 'cache-and-network',
  });

  const myBasketQueryResults = useMyBasketsQuery({
    skip: getUserData?.getMyData?.id === undefined
  });

  const myBasketForEvent = useMemo(() => {
    if (
      myBasketQueryResults.data?.getMyData !== undefined &&
      myBasketQueryResults.data.getMyData !== null &&
      getUserData?.getMyData?.id !== undefined
    ) {
      return myBasketQueryResults.data.getMyData.basketIds.filter((basket) => basket.eventId.id === event.id);
    } else {
      return [];
    }
  }, [
    myBasketQueryResults.data,
    getUserData?.getMyData?.id,
    event.id
  ]);

  const startDate = new Date(event.startDate || '');
  const endDate = new Date(event.endDate || '');

  return (<TableRow key={event.id}>
    <TableCell component="th" scope="row">
      <Typography variant="h5">
        {event.name}
      </Typography>
    </TableCell>
    <TableCell align="center">
      <Typography variant="h6">
        {formatDate(startDate, FORMAT_DATE_OPTIONS, 'en-GB')} to {formatDate(endDate, FORMAT_DATE_OPTIONS, 'en-GB')}
      </Typography>
    </TableCell>
    <TableCell align="center">
      <Typography variant="h5">
        {loading ?
          <CircularProgress /> :
          (data?.searchCommunicorEventInvitation.length ?? 0) + (myBasketForEvent?.length > 0 ? 1 : 0)
        }
      </Typography>
    </TableCell>
    <TableCell align="center">
      <Typography variant="h5">
        {loading ?
          <CircularProgress /> :
          (data?.searchCommunicorEventInvitation.reduce((acc, value) => { return acc + value.individualBookerId.basketIds.filter((basket) => basket.eventId.id === event.id).length; }, 0) ?? 0) + myBasketForEvent.length
        }
      </Typography>
    </TableCell>
    <TableCell align="center">
      <Button onClick={() => history.push(`/eventDetails/${event.id}`)} variant="contained" size="large">View more</Button>
    </TableCell>
  </TableRow>);
};

export default PMEventTableRow;