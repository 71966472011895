import { Button, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import PageLayout from 'layout/PageLayout/PageLayout';
import { useHistory, useParams } from 'react-router-dom';
import IndividualCard from 'components/IndividualCard/IndividualCard';
import { useCurrentUserQuery, useFindInvitedIbQuery, useGetEventInformationQuery } from 'apollo/queries';
import * as Luq from '../../luq';
import compile from 'utils/domain';
import GridContainerWithPadding from 'components/GridContainerWithPadding/GridContainerWithPadding';
import RequestHandling from 'components/RequestHandling';
import { monthName } from 'utils/formatDate';
import { SearchOutlined } from '@material-ui/icons';
import usePMEventDetailsStyles from './PMEventDetailsStyles';
import { CommunicorEventInvitation } from 'apollo/types';
import { useState, useEffect } from 'react';

const PMEventDetails: React.FC = () => {

  const history = useHistory();

  const { eventId } = useParams<{ eventId: string }>();

  const classes = usePMEventDetailsStyles();

  const { data: getUserData } = useCurrentUserQuery();

  const getEventInformationQueryResults = useGetEventInformationQuery({
    variables: {
      id: parseInt(eventId)
    },
    skip: eventId === undefined
  });

  const [ searchValue, setSearchValue ] = useState<string>('');
  const [ searchResult, setSearchResult ] = useState<CommunicorEventInvitation[]>([]);

  const findInvitedIbQueryResult = useFindInvitedIbQuery({
    variables: {
      filter: compile(
        <Luq.And>
          <Luq.Eq field="eventId" value={parseInt(eventId)} />
          <Luq.Eq field="masterAccountId" value={getUserData?.getMyData?.id} />
        </Luq.And>
      )
    },
    skip: eventId === undefined || getUserData?.getMyData?.id === undefined
  });

  // @ts-ignore
  useEffect(() => {
    let cancelled = false;
    if (!findInvitedIbQueryResult.loading && findInvitedIbQueryResult?.data?.searchCommunicorEventInvitation) {
      setTimeout(() => {
        if (!cancelled) {
          // @ts-ignore
          setSearchResult(findInvitedIbQueryResult?.data?.searchCommunicorEventInvitation.filter(
            (current) => 
              current
                .individualBookerId
                ?.displayName
                ?.toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(
                  searchValue
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, ''))
          ));
        }
      }, 500);
    }
    return () => cancelled = true;
  }, [ findInvitedIbQueryResult, searchValue] );

  const startDate = new Date(getEventInformationQueryResults.data?.getCommunicorEvent?.startDate || '');
  const endDate = new Date(getEventInformationQueryResults.data?.getCommunicorEvent?.endDate || '');

  return (
    <PageLayout
      title={getEventInformationQueryResults.data?.getCommunicorEvent?.name || ''}
      navigation
      backButton
    >
      <RequestHandling {...getEventInformationQueryResults}>
        {
          event => (
            <GridContainerWithPadding container spacing={9}>
              <Grid item xs={7} className={classes.eventTitle}>
                <Typography className={classes.eventName}>
                  {event.getCommunicorEvent?.name}
                </Typography>
                <Typography className={classes.stepLabel}>
                  From : { `${monthName(startDate.getMonth())} ${startDate.getDate() < 10 ? `0${startDate.getDate()}` : startDate.getDate()}${startDate.getFullYear() !== endDate.getFullYear() ? `, ${startDate.getFullYear()}` : ''} to ${monthName(endDate.getMonth())} ${endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate()}, ${endDate.getFullYear()}` }
                </Typography>
                <hr className={classes.eventDivider} />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  id="search"
                  variant="outlined"
                  margin="normal"
                  label="Search An Individual"
                  inputProps={{ 'aria-label': 'search google maps' }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchOutlined />
                      </InputAdornment>
                    ) } }
                  value={searchValue}
                  onChange={(event) => setSearchValue(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={9} className={classes.title}>
                    <Typography variant="h2">
                      List of individual
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Button variant="contained" size="large" fullWidth onClick={() => history.push(`/addIndividual/${eventId}`)}>
                      Add individual
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3} className={classes.noPadding}>
                      <RequestHandling {...findInvitedIbQueryResult}>
                        {
                          individualBookers => (
                            <>
                              {
                                searchResult.map((individualBooker) => (
                                  <Grid item xs={6} lg={4} key={individualBooker.id}>
                                    <IndividualCard individual={individualBooker.individualBookerId} eventId={eventId} />
                                  </Grid>
                                ))
                              }
                            </> 
                          )
                        }
                      </RequestHandling>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </GridContainerWithPadding>
          )
        }
      </RequestHandling>
    </PageLayout>
  );
};

export default PMEventDetails;