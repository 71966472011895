import { makeStyles } from '@material-ui/core';

const useIndividualCardStyles = makeStyles({
  card: {
    width: '100%',
    padding: '2px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  label: {
    color: '#CED4DA',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: '16px'
  },
  value: {
    color: '#CED4DA',
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: '16px'
  },
  individual: {
    fontWeight: 700,
    fontFamily: 'Karla',
    fontSize: '20px'
  },
  quantityBlock: { 
    display: 'flex', 
    justifyContent: 'space-between', 
    width: '95%', 
    marginTop: '10px' 
  }
});

export default useIndividualCardStyles;