import {
  Button,
  Grid,
  Table,
  TableBody,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  useGetFullBasketQuery,
} from 'apollo/queries';
import {
  CommunicorAccreditationFieldsFragment,
  CommunicorAirportFieldsFragment,
  CommunicorDailyFieldsFragment,
  CommunicorTechnicalFieldsFragment,
  AccountTaxFieldsFragment,
} from 'apollo/queries/types';
import Airport from 'components/CartItems/Airport';
import Daily from 'components/CartItems/Daily';
import RequestHandling from 'components/RequestHandling';
import PageLayout from 'layout/PageLayout/PageLayout';
import CarPass from 'components/CartItems/CarPass';
import { Link, useParams } from 'react-router-dom';
import useStyles from './IBEventReservationStyles';
import MeetAndGreet from 'components/CartItems/MeetAndGreet';
import GridContainerWithPadding from 'components/GridContainerWithPadding/GridContainerWithPadding';
import TableCellWithoutBorder from 'components/TableCellWithoutBorder/TableCellWithoutBordel';
import { formatPrice } from 'utils/formatPrice';
import { useHistory } from 'react-router';

const rateFromTax = (tax: AccountTaxFieldsFragment | undefined | null): number => {
  if (tax === null || tax === undefined) {
    return 0;
  } else {
    return tax.amount;
  }
};

const IBEventReservation: React.FC = () => {
  const { basketId } = useParams<{ eventId: string; basketId: string }>();
  const classes = useStyles();
  const history = useHistory();

  const fullBasketQueryResult = useGetFullBasketQuery({
    variables: {
      id: parseInt(basketId),
    },
    fetchPolicy: 'cache-and-network',
    skip: basketId === undefined,
  });

  const hasItems = fullBasketQueryResult.data?.getCommunicorBasket && (
    fullBasketQueryResult.data.getCommunicorBasket.dailyLineIds.length > 0 ||
    fullBasketQueryResult.data.getCommunicorBasket.airportLineIds.length > 0 ||
    fullBasketQueryResult.data.getCommunicorBasket.accreditationLineIds.length > 0 ||
    fullBasketQueryResult.data.getCommunicorBasket.technicalLineIds.length > 0
  );

  const calcVAT = (): { [key: number]: number } => {
    if (!fullBasketQueryResult.data || fullBasketQueryResult.data?.getCommunicorBasket?.tvaExempt) {
      return {
        0: 0,
      };
    }
    const basket = fullBasketQueryResult.data.getCommunicorBasket;
    if (basket === null || basket === undefined) {
      return {
        0: 0,
      };
    }
    const rates = {
      accreditation: rateFromTax(basket.accreditationTvaId),
      technical: rateFromTax(basket.technicalTvaId),
    };

    const groups: { [key: number]: number } = {};

    Object.values(rates).forEach(rate => {
      groups[rate] = 0;
    });
    basket.dailyLineIds.forEach(dailyLine => {
      if (dailyLine.tvaId?.amount) {
        if (groups[dailyLine.tvaId.amount] !== undefined) {
          groups[dailyLine.tvaId.amount] += (dailyLine.totalPrice || 0) * dailyLine.tvaId.amount / 100;
        } else {
          groups[dailyLine.tvaId.amount] = (dailyLine.totalPrice || 0) * dailyLine.tvaId.amount / 100;
        }
      }
    });
    basket.airportLineIds.forEach(airportLine => {
      if (airportLine.tvaId?.amount) {
        if (groups[airportLine.tvaId.amount] !== undefined) {
          groups[airportLine.tvaId.amount] += (airportLine.totalPrice || 0) * airportLine.tvaId.amount / 100;
        } else {
          groups[airportLine.tvaId.amount] = (airportLine.totalPrice || 0) * airportLine.tvaId.amount / 100;
        }
      }
    });

    groups[rates.accreditation] += (basket.priceAccreditation || 0) * rates.accreditation / 100;
    groups[rates.technical] += (basket.priceTechnical || 0) * rates.technical / 100;

    return groups;
  };

  return (
    <PageLayout title={fullBasketQueryResult.data?.getCommunicorBasket?.eventId.name} navigation backButton="/">
      <RequestHandling {...fullBasketQueryResult}>
        {eventCart => (
          <GridContainerWithPadding container spacing={9}>
            <Grid item xs={12} className={classes.eventTitle}>
              <Typography className={classes.eventName}>
                {eventCart.getCommunicorBasket?.eventId?.name}
              </Typography>
              <Typography className={classes.stepLabel}>Step : <span className={classes.step}>{hasItems ? 'Items Added' : 'Empty'}</span></Typography>
              <hr className={classes.eventDivider} />
            </Grid>
            <Grid direction="column" item xs={8} lg={7} container spacing={2} className={classes.items}>
              <Grid item className={classes.title}>
                <Typography className={classes.itemsTitle}>Items :</Typography>
                <Link to={`/addItem/${eventCart.getCommunicorBasket?.eventId?.id}/${basketId}`}>
                  <Button className={classes.addItemButton} variant="contained" size="large">Add an item</Button>
                </Link>
              </Grid>
              {eventCart.getCommunicorBasket?.dailyLineIds.map((dailyLine: CommunicorDailyFieldsFragment) => (
                <Grid item key={dailyLine.id}>
                  <Daily
                    line={dailyLine}
                    currency={eventCart.getCommunicorBasket?.currency?.symbol}
                    canDelete
                    canEdit
                    pricelist={eventCart.getCommunicorBasket?.pricelistId?.dailyLineIds}
                    basketId={basketId}
                  />
                </Grid>
              ))}
              {eventCart.getCommunicorBasket?.airportLineIds.map((airportLine: CommunicorAirportFieldsFragment) => (
                <Grid item key={airportLine.id}>
                  <Airport
                    eventId={fullBasketQueryResult.data?.getCommunicorBasket?.eventId.id}
                    line={airportLine}
                    currency={eventCart.getCommunicorBasket?.currency?.symbol}
                    canDelete
                    pricelist={eventCart.getCommunicorBasket?.pricelistId?.airportLineIds}
                    basketId={basketId}
                  />
                </Grid>
              ))}
              {eventCart.getCommunicorBasket?.accreditationLineIds.map((accreditationLine: CommunicorAccreditationFieldsFragment) => (
                <Grid item key={accreditationLine.id}>
                  <CarPass
                    line={accreditationLine}
                    currency={eventCart.getCommunicorBasket?.currency?.symbol}
                    pricelist={eventCart.getCommunicorBasket?.pricelistId?.accreditationLineIds}
                    canDelete />
                </Grid>
              ))}
              {eventCart.getCommunicorBasket?.technicalLineIds.map((technicalLine: CommunicorTechnicalFieldsFragment) => (
                <Grid item key={technicalLine.id}>
                  <MeetAndGreet
                    line={technicalLine}
                    currency={eventCart.getCommunicorBasket?.currency?.symbol}
                    canDelete
                    pricelist={eventCart.getCommunicorBasket?.pricelistId?.technicalLineIds} />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={4} lg={5} container direction="column" spacing={2}>
              <Grid item className={classes.title}>
                <Typography className={classes.itemsTitle}>Price :</Typography>
              </Grid>
              <Grid item>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellWithoutBorder className={classes.label}>Total VAT excluded:</TableCellWithoutBorder>
                      <TableCellWithoutBorder className={classes.value}>{formatPrice(eventCart.getCommunicorBasket?.priceTotalHt || 0, eventCart.getCommunicorBasket?.currency?.symbol || '£')}</TableCellWithoutBorder>
                    </TableRow>
                    {Object.entries(calcVAT()).map(([rate, amount]) => rate !== '0' && amount !== 0 && (
                      <TableRow key={rate}>
                        <TableCellWithoutBorder className={classes.label}>VAT {rate}%:</TableCellWithoutBorder>
                        <TableCellWithoutBorder className={classes.value}>{formatPrice(amount, eventCart.getCommunicorBasket?.currency?.symbol || '£')}</TableCellWithoutBorder>
                      </TableRow>
                    ))}
                    {eventCart.getCommunicorBasket?.tvaExempt && <TableRow>
                      <TableCellWithoutBorder className={classes.label}>VAT:</TableCellWithoutBorder>
                      <TableCellWithoutBorder className={classes.value}>EXEMPT</TableCellWithoutBorder>
                    </TableRow>}
                    <TableRow>
                      <TableCellWithoutBorder className={classes.label}>Total VAT included:</TableCellWithoutBorder>
                      <TableCellWithoutBorder className={classes.value}>{formatPrice(eventCart.getCommunicorBasket?.priceTotalTtc || 0, eventCart.getCommunicorBasket?.currency?.symbol || '£')}</TableCellWithoutBorder>
                    </TableRow>
                    {
                      ((eventCart.getCommunicorBasket?.dailyLineIds !== undefined && eventCart.getCommunicorBasket?.dailyLineIds.length > 0) ||
                      (eventCart.getCommunicorBasket?.airportLineIds !== undefined && eventCart.getCommunicorBasket?.airportLineIds.length > 0) ||
                      (eventCart.getCommunicorBasket?.accreditationLineIds !== undefined && eventCart.getCommunicorBasket?.accreditationLineIds.length > 0) ||
                      (eventCart.getCommunicorBasket?.technicalLineIds !== undefined && eventCart.getCommunicorBasket?.technicalLineIds.length > 0)) &&
                      <TableRow>
                        <TableCellWithoutBorder className={classes.value} colSpan={2}>
                          <Button
                            variant="contained"
                            size="large"
                            onClick={() => history.push(`/eventSummary/${basketId}`)}
                            disabled={
                              eventCart.getCommunicorBasket?.dailyLineIds !== undefined && eventCart.getCommunicorBasket?.dailyLineIds.length < 1 &&
                              eventCart.getCommunicorBasket?.airportLineIds !== undefined && eventCart.getCommunicorBasket?.airportLineIds.length < 1 &&
                              eventCart.getCommunicorBasket?.accreditationLineIds !== undefined && eventCart.getCommunicorBasket?.accreditationLineIds.length < 1 &&
                              eventCart.getCommunicorBasket?.technicalLineIds !== undefined && eventCart.getCommunicorBasket?.technicalLineIds.length < 1
                            }>Book</Button>
                        </TableCellWithoutBorder>
                      </TableRow>}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </GridContainerWithPadding>
        )}
      </RequestHandling>
    </PageLayout>
  );
};

export default IBEventReservation;
