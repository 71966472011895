import { makeStyles } from '@material-ui/core';


const usePMIBReservationsStyles = makeStyles({
  title: {
    alignSelf: 'center',
    borderBottom: '1px solid #CED4DA',
    padding: '0 !important',
    paddingBottom: '20px !important'
  },
  noPadding: {
    padding: '0px'
  },
  individualDivider: {
    width: '100%',
    float: 'left',
    marginTop: '15px',
    borderColor: '#CED4DA',
    border: 'unset',
    borderBottom: '2px solid #CED4DA'
  },
  pageTitle: {
    padding: '30px !important',
    flexBasis: 'unset'
  },
  individualName: {
    fontWeight: 500,
    fontSize: '40px',
    fontFamily: 'Karla',
  },
});

export default usePMIBReservationsStyles;