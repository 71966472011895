import { makeStyles } from '@material-ui/core';

const useCartItemCommonStyles = makeStyles({
  title: {
    fontSize: '16px',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    color: '#CED4DA',
    marginTop: '5px'
  },
  content: {
    fontWeight: 700,
    fontSize: '17px',
    fontFamily: 'Montserrat',
    color: '#CED4DA'
  }
});

export default useCartItemCommonStyles;