import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { MenuItem } from '@material-ui/core';
import PageLayout from 'layout/PageLayout/PageLayout';
import useAddBookingStyles from './MAAddBookingStyles';
import RequestHandling from 'components/RequestHandling';
import { useCreateBasketMutation } from 'apollo/mutations';
import { useHistory } from 'react-router';
import useUserId from 'utils/userId';
import compile from 'utils/domain';
import * as Luq from 'luq';
import { useFindMaEventQuery } from 'apollo/queries';
import usePricelist from 'hooks/usePriceList';

const MAAddBooking: React.FC = () => {

  const classes = useAddBookingStyles();
  const history = useHistory();
  const userId = useUserId();

  const [selectedEvent, setSelectedEvent] = useState<string>('');

  const eventListQueryResult = useFindMaEventQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: compile(
        <Luq.Eq field="authorizedUsers" value={userId}></Luq.Eq>
      )
    }
  });

  const [createBasketMutation] = useCreateBasketMutation();

  const pricelist = usePricelist(parseInt(selectedEvent));

  const handleValidate = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (!eventListQueryResult.loading && !eventListQueryResult.error && eventListQueryResult.data) {
      const event = eventListQueryResult.data.searchCommunicorEvent.find((event) => event.id === parseInt(selectedEvent));
      if (event && userId && pricelist.data) {
        try {
          const basket = await createBasketMutation({
            variables: {
              eventId: event.id,
              userId: userId,
              pricelist: pricelist.data.id
            }
          });
          if (basket.data?.createCommunicorBasket?.created?.id) {
            history.push(`/eventBasket/${basket.data.createCommunicorBasket.created.id}`);
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  return (
    <PageLayout
      title="Add a booking"
      navigation
      backButton
    >
      <RequestHandling {...eventListQueryResult}>
        {
          eventList => (
            <div className={classes.paper}>
              <Typography variant="h1" className={classes.margin}>
                Choose A Show Or Event
              </Typography>
              {
                eventList.searchCommunicorEvent.length < 1 ?
                  <div>
                    You have not been invited to any event yet.
                  </div>
                  :
                  <form className={classes.form} noValidate>
                    <TextField
                      required
                      id="event"
                      label="Show / Event"
                      onChange={(event) => setSelectedEvent(event.target.value)}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      select
                      className={classes.margin}
                      fullWidth
                    >
                      {
                        eventList.searchCommunicorEvent.map((event) => {
                          return <MenuItem key={event.id} value={event.id}>{event.name}</MenuItem>;
                        })
                      }
                    </TextField>
                    {
                      selectedEvent !== '' && pricelist.loading === false && pricelist.data === null && (
                        <>
                          <Typography>
                            There is no pricelist for your division or company yet.
                          </Typography>
                          <Typography className={classes.margin}>
                            Please contact your master account or Communicor account manager.
                          </Typography>
                        </>
                    
                      )
                    }
                    <Button
                      onClick={handleValidate}
                      variant="contained"
                      size="large"
                      disabled={pricelist.data === null}
                    >
                      Validate
                    </Button>
                  </form>
              }
            </div>
          )
        }
      </RequestHandling>

    </PageLayout>
  );
};

export default MAAddBooking;
