import { Button, CircularProgress, Dialog, DialogContent, Grid, IconButton, Typography } from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';
import config from 'config/config.json';
import SimCardDownloadRoundedIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { useAcceptInvoiceMutation } from 'apollo/mutations';
import { useMemo, useState } from 'react';
import { BasketFieldsLightFragment } from 'apollo/queries/types';
import { useHistory } from 'react-router';
import { withStyles } from '@material-ui/styles';
import useInvoiceModalStyles from './InvoiceModalStyles';
import { MailOutline } from '@material-ui/icons';
import { downloadFile } from 'utils/downloadFile';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  booking: BasketFieldsLightFragment;
};

const FullSizeDialog = withStyles({
  paper: {
    width: '100vw',
    height: '100vh',
    margin: '0',
    background: '#212529C8 0% 0% no-repeat padding-box',
  },
  root: {
    width: '100vw',
    height: '100vh'
  },
  paperScrollPaper: {
    maxHeight: 'unset'
  },
  paperWidthSm: {
    maxWidth: 'unset'
  }
})(Dialog);

const GridContainerWithLargePadding = withStyles({
  container: {
    paddingLeft: '150px',
    paddingRight: '150px'
  }
})(Grid);

const InvoiceModal: React.FC<Props> = ({ isOpen, setIsOpen, booking }) => {

  const history = useHistory();
  const classes = useInvoiceModalStyles();
  const [loading, setLoading] = useState<boolean>(false);

  const downloadInvoice = async () => {
    await downloadFile({
      fileName: `Invoice - Draft.pdf`,
      filePath: `${config.ODOO_HOST}/report/invoice/${booking.saleOrderId?.invoiceIds[0].id}`,
    });
  };

  const displayedFileComponent = useMemo(() => {
    const token = localStorage.getItem('session_id');
    return (
      <Document
        file={{
          url: `${config.ODOO_HOST}/report/invoice/${booking.saleOrderId?.invoiceIds[0].id}`,
          httpHeaders: {
            Accept: '*/*',
            'X-Openerp-Session-Id': token
          },
        }}
        onLoadError={console.error}
        loading={<CircularProgress />}
      // onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={1}
          height={600}
        />
      </Document>
    );
  }, [booking.saleOrderId?.id]);

  const [acceptInvoiceMutation] = useAcceptInvoiceMutation();

  const acceptInvoice = async () => {
    if (booking.saleOrderId?.invoiceIds && booking.saleOrderId?.invoiceIds.length > 0) {
      setLoading(true);
      try {
        await acceptInvoiceMutation({
          variables: {
            id: booking.saleOrderId?.invoiceIds[0].id
          }
        });
        setLoading(false);
        setIsOpen(false);
        history.push(`/invoiceConfirmed/${booking.eventId.id}`);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };

  return (
    <FullSizeDialog open={isOpen} onClose={() => setIsOpen(false)} >
      <DialogContent>
        <GridContainerWithLargePadding container spacing={3}>
          <Grid item lg={2}></Grid>
          <Grid item xs={4} lg={3} className={classes.center}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={10}>
                    <Typography className={classes.quoteNumber}>
                      Invoice
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={() => setIsOpen(false)}>
                      <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={downloadInvoice}
                >
                  Download&nbsp;&nbsp;<SimCardDownloadRoundedIcon />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={() => window.open(`mailto:${config.CONTACT_MAIL}`)}
                >
                  Contact Us&nbsp;&nbsp;<MailOutline />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  disabled={loading}
                  onClick={() => acceptInvoice()}
                >
                  {!loading ? <>Accept&nbsp;&nbsp;<CheckIcon /></> : <CircularProgress />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7} lg={6} className={classes.pdfViewer}>
            {displayedFileComponent}
          </Grid>
        </GridContainerWithLargePadding>
      </DialogContent>
    </FullSizeDialog>
  );
};

export default InvoiceModal;