import { TableCell, withStyles } from '@material-ui/core';

const TableCellWithoutBorder = withStyles({
  root: {
    border: 'unset !important',
    paddingLeft: '0px',
    paddingRight: '0px'
  }
})(TableCell);

export default TableCellWithoutBorder;