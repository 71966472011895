import { TextField,  MenuItem, Grid } from '@material-ui/core';
import { useSearchCountriesQuery } from 'apollo/queries';
import { InvoiceAddress } from 'type/InvoiceAddress';

interface Props {
  invoiceAddress: InvoiceAddress;
  setInvoiceAddress: (invoiceAddress: InvoiceAddress) => void;
}

const InvoiceAddressForm: React.FC<Props> = ({ invoiceAddress, setInvoiceAddress }) => {

  const searchCountryQueryResults = useSearchCountriesQuery();

  return (
    <>
      <Grid item xs={7}>
        <TextField
          required
          id="address1"
          label="Address 1"
          defaultValue={invoiceAddress.address1}
          onChange={(event) => setInvoiceAddress({ ...invoiceAddress, address1: event.target.value })}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          required
          id="zip"
          label="Post Code"
          defaultValue={invoiceAddress.zipCode}
          onChange={(event) => setInvoiceAddress({ ...invoiceAddress, zipCode: event.target.value })}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>
      <Grid item xs={7}>
        <TextField
          id="address2"
          label="Address 2"
          defaultValue={invoiceAddress.address2}
          onChange={(event) => setInvoiceAddress({ ...invoiceAddress, address2: event.target.value })}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          required
          id="city"
          label="City"
          defaultValue={invoiceAddress.city}
          onChange={(event) => setInvoiceAddress({ ...invoiceAddress, city: event.target.value })}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>
      <Grid item xs={7}>
        <TextField
          id="address3"
          label="Address 3"
          defaultValue={invoiceAddress.address3}
          onChange={(event) => setInvoiceAddress({ ...invoiceAddress, address3: event.target.value })}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          required
          id="country"
          label="Country"
          value={invoiceAddress.country}
          onChange={(event) => setInvoiceAddress({ ...invoiceAddress, country: event.target.value }) }
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          select
          disabled={searchCountryQueryResults.loading}
          fullWidth
        >
          {
            searchCountryQueryResults.data?.searchResCountry.map((country) => {
              return <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>;
            })
          }
        </TextField>
      </Grid>
      <Grid item xs={7}>
        <TextField
          id="address4"
          label="Address 4"
          defaultValue={invoiceAddress.address4}
          onChange={(event) => setInvoiceAddress({ ...invoiceAddress, address4: event.target.value })}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>
    </>
  );
};

export default InvoiceAddressForm;