import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import PageLayout from 'layout/PageLayout/PageLayout';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { login } from 'utils/authentication';
import useMAInvitationViewStyles from './MAInvitationViewStyle';
import { useCurrentUserQuery } from 'apollo/queries';
import GridContainerWithPadding from 'components/GridContainerWithPadding/GridContainerWithPadding';
import clsx from 'clsx';

const MAInvitationView: React.FC = () => {
  const classes = useMAInvitationViewStyles();
  const history = useHistory();

  // A custom hook that builds on useLocation to parse
  // the query string for you.
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const email = query.get('email');
  const token = query.get('token');
  const [logged, setLogged] = useState<boolean | null>(null);
  const [userId, setUserId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);


  //log user with query params
  useEffect(() => {
    if (email !== null && token !== null) {
      setLoading(true);
      login({ email, password: token })
        .then(
          () => {
            setLogged(true);
          }
        )
        .catch(
          (error) => {
            setLogged(false);
            history.push('/login');
          }
        ).finally(
          () => {
            setLoading(false);
          }
        );
    }
  }, [email, token, history]);

  const { data } = useCurrentUserQuery({
    skip: !logged
  });

  useEffect(() => {
    if (data?.getMyData?.id) {
      setUserId(data.getMyData.id);
    }
  }, [data?.getMyData?.id]);

  return (
    <PageLayout>
      <GridContainerWithPadding container className={classes.container}>
        <Grid item xs={11} sm={10} md={9} lg={8}>
          <GridContainerWithPadding container spacing={2}>
            <Grid item xs={12} className={classes.margin}>
              <Typography variant="h1">You have been invited to create a Master Account.</Typography>
            </Grid>
            {
              !loading ?
                <Grid item xs={12} className={clsx(classes.margin, classes.option)}>
                  {logged ?
                    <Button variant="contained" size="large" className={classes.button} onClick={() => history.push(`/masignup/${userId}`)} >
                      Create my account
                    </Button> :
                    <div>
                      <Button variant="contained" size="large" className={classes.button} onClick={() => history.push('/login')}>I already have an account</Button>
                    </div>
                  }
                </Grid>
                :
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', fontSize: 40 }}>
                  <CircularProgress />
                </div>
            }
          </GridContainerWithPadding>
        </Grid>
      </GridContainerWithPadding>


    </PageLayout>
  );
};

export default MAInvitationView;