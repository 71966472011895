import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import SelectableCard from 'components/SelectableCard/SelectableCard';
import clsx from 'clsx';
import GridContainerWithPadding from 'components/GridContainerWithPadding/GridContainerWithPadding';

const useStyles = makeStyles({
  container: {
    placeContent: 'center',
    textAlign: 'center',
    paddingTop: '150px'
  },
  margin: {
    marginBottom: '30px'
  },
  caption: {
    fontSize: '18px',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    color: '#CED4DA'
  },
  option: {
    height: '90px'
  }
});

interface PeriodChooserProps {
  arrival: boolean;
  departure: boolean;
  setArrival: (arrival: boolean) => void;
  setDeparture: (departure: boolean) => void;
  onValidate: () => void;
}

const PeriodChooser: React.FC<PeriodChooserProps> = ({ arrival, departure, setArrival, setDeparture, onValidate }) => {
  
  const classes = useStyles();

  return (
    <GridContainerWithPadding container className={classes.container}>
      <Grid item xs={11} sm={10} md={9} lg={8}>
        <GridContainerWithPadding container spacing={2}>
          <Grid item xs={12} className={classes.margin}>
            <Typography variant="h1">Airport Or City Transfer</Typography>
          </Grid>
          <Grid item xs={12} className={classes.caption}>
            <span className={classes.caption}>When ?</span>
          </Grid>
          <Grid item xs={6} className={classes.option}>
            <SelectableCard
              selected={arrival}
              onSelect={() => setArrival(!arrival)}
              title={'Arrival'}
            />
          </Grid>
          <Grid item xs={6} className={clsx(classes.margin, classes.option)}>
            <SelectableCard
              selected={departure}
              onSelect={() => setDeparture(!departure)}
              title={'Departure'}
            />
          </Grid>
          {
            (arrival || departure) && (
              <Grid item xs={12}>
                <Button 
                  onClick={() => onValidate()}
                  variant="contained" 
                  size="large"
                >
                  Next
                </Button>
              </Grid>
            )
          }
        </GridContainerWithPadding>
      </Grid>
    </GridContainerWithPadding>
  );
};

export default PeriodChooser;