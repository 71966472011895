import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core';
import { FlightInformation } from 'type/FlightInformation';
import { TrainInformation } from 'type/TrainInformation';

interface TransportInformationProps {
  transportType: string | null;
  setTransportType: (transportType: string) => void;
  flightInformation: FlightInformation;
  setFlightInformation: (flightInformation: FlightInformation) => void;
  trainInformation: TrainInformation;
  setTrainInformation: (trainInformation: TrainInformation) => void;
}

const TransportInformation: React.FC<TransportInformationProps> = ({ 
  transportType, 
  setTransportType, 
  flightInformation, 
  setFlightInformation, 
  trainInformation, 
  setTrainInformation 
}) => {
  return (
    <>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Transport</FormLabel>
          <RadioGroup row aria-label="transport" name="gender1" value={transportType} onChange={(event) => setTransportType(event.target.value)}>
            <FormControlLabel value="flight" control={<Radio />} label="Flight" />
            <FormControlLabel value="train" control={<Radio />} label="Train" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {transportType === 'flight' && (
        <>
          <Grid item xs={6}>
            <TextField
              label="Airport Code"
              required
              defaultValue={flightInformation.transportCode}
              onChange={(event) => setFlightInformation({ ...flightInformation, transportCode: event.target.value })}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Flight Number (if available)"
              defaultValue={flightInformation.transportNumber}
              onChange={(event) => setFlightInformation({ ...flightInformation, transportNumber: event.target.value })}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Port of Origin (if available)"
              defaultValue={flightInformation.transportOrigin}
              onChange={(event) => setFlightInformation({ ...flightInformation, transportOrigin: event.target.value })}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Port of Arrival (if available)"
              defaultValue={flightInformation.transportDestination}
              onChange={(event) => setFlightInformation({ ...flightInformation, transportDestination: event.target.value })}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </>
      )}
      {
        transportType === 'train' && (
          <>
            <Grid item xs={6}>
              <TextField
                label="Train Number"
                defaultValue={trainInformation.transportNumber}
                onChange={(event) => setTrainInformation({ ...trainInformation, transportNumber: event.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <TextField
                label="Station of departure"
                defaultValue={trainInformation.transportOrigin}
                onChange={(event) => setTrainInformation({ ...trainInformation, transportOrigin: event.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Station of arrival"
                defaultValue={trainInformation.transportDestination}
                onChange={(event) => setTrainInformation({ ...trainInformation, transportDestination: event.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </>
        )
      }
    </>
  );
};

export default TransportInformation;