/* Framework imports ----------------------------------- */
import { makeStyles } from '@material-ui/core';
/* ConfirmRide makeStyle hook ------------------------------- */
const useConfirmRideStyles = makeStyles({
  input: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'grey',
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'grey',
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: 'grey',
    },
    '& .MuiCheckbox-colorSecondary.Mui-disabled': {
      color: 'grey',
    },
  },
  button: {
    '&:disabled': {
      color: 'grey !important',
      border: '1px solid grey !important',
    },
  }
});

/* Export ConfirmRide makeStyles hook ------------------------ */
export default useConfirmRideStyles;
