import { Button, Grid, Typography } from '@material-ui/core';
import { useLoggedInQuery, useMyBasketsQuery } from 'apollo/queries';
import { BasketFieldsLightFragment } from 'apollo/queries/types';
import BookingCard from 'components/BookingCard/BookingCard';
import GridContainerWithPadding from 'components/GridContainerWithPadding/GridContainerWithPadding';
import RequestHandling from 'components/RequestHandling';
import PageLayout from 'layout/PageLayout/PageLayout';
import { useHistory } from 'react-router-dom';
import useIndividualHomeStyles from './IndividualHomeStyle';

const IndividualHome: React.FC = () => {

  const classes = useIndividualHomeStyles();
  const history = useHistory();

  const { data: loggedInData } = useLoggedInQuery();
  const myBasketQueryResults = useMyBasketsQuery({
    skip: loggedInData === undefined || !loggedInData.isLoggedIn,
    fetchPolicy: 'cache-and-network',
  });

  return (
    <PageLayout
      navigation
      title="Home"
    >
      <GridContainerWithPadding container spacing={2}>
        <Grid item xs={9} className={classes.title}>
          <Typography variant="h2">
            My bookings
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" size="large" fullWidth onClick={() => history.push(`/addBooking`)}>
            Book a transportation services
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} className={classes.noPadding}>
            <RequestHandling {...myBasketQueryResults}>
              {
                baskets => (
                  <>
                    { baskets.getMyData?.basketIds.map((basket: BasketFieldsLightFragment) => {
                      return (
                        <Grid item xs={6} sm={6} md={6} lg={4} key={basket.id}>
                          <BookingCard key={basket.id} booking={basket} />
                        </Grid>
                      );
                    })}
                    {
                      baskets.getMyData?.basketIds.length === 0 && (
                        <div>
                          <Typography className={classes.emptyText}>
                            No transportation services was added
                          </Typography>
                          <Button variant="contained" size="large" onClick={() => history.push(`/addBooking`)} className={classes.addButton}>
                            Add a booking
                          </Button>
                        </div> 
                      )
                    }
                  </> 
                )
              }
            </RequestHandling>
          </Grid>
        </Grid>
      </GridContainerWithPadding>
    </PageLayout>
  );
};

export default IndividualHome;