import { Card, CardContent, Typography } from '@material-ui/core';
import { BasketFieldsLightFragment } from 'apollo/queries/types';
import { useHistory } from 'react-router-dom';
import useBookingCardStyles from './BookingCardStyle';

interface Props {
  booking: BasketFieldsLightFragment | null | undefined;
  isMasterAccount?: boolean;
};

const BookingCard: React.FC<Props> = ({ booking, isMasterAccount = false }) => {
  const classes = useBookingCardStyles();
  const history = useHistory();

  const state =
    (booking?.saleOrderId?.invoiceIds !== undefined && booking?.saleOrderId.invoiceIds !== null && booking.saleOrderId.invoiceIds.length > 0) ?
      booking.saleOrderId.invoiceIds[0].markReady ?
        booking.saleOrderId.invoiceIds[0].state.value === 'Draft' ?
          'Provisional invoice'
          :
          `Invoice ${booking.saleOrderId.invoiceIds[0].state.value}`
        :
        'Sales order'
      :
      (booking?.saleOrderId !== null && booking?.saleOrderId !== undefined) ?
        booking?.saleOrderId?.state?.value
        :
        booking?.state?.value;

  return booking ?
    (
      <>
        <Card
          onClick={() => {
            if (!isMasterAccount) {
              if (booking.state?.key === 'draft') {
                history.push(`/eventBasket/${booking.id}`);
              } else {
                history.push(`/eventSummary/${booking.id}`);
              }
            } else {
              history.push(
                `/eventSummary/${booking.id}`,
                { isMasterAccount: true }
              );
            }
          }}
          className={classes.card}
        >
          <CardContent>
            <Typography className={classes.title}>
              {booking.eventId.name}
            </Typography>
            <Typography className={classes.section}>
              Step : <span className={classes.value}>{state || ''}</span>
            </Typography>
            {
              booking.priceTotalTtc !== undefined && 
              booking.priceTotalTtc !== null && 
              booking.currency !== undefined &&
              booking.currency !== null &&
              <Typography className={classes.section}>
                Amount : <span className={classes.value}>{`${booking.priceTotalTtc} ${booking.currency.symbol}`}</span>
              </Typography>
            }
          </CardContent>
        </Card>
      </>
    ) :
    null;
};

export default BookingCard;