import { Button, Grid, Typography } from '@material-ui/core';
import GridContainerWithPadding from 'components/GridContainerWithPadding/GridContainerWithPadding';
import PageLayout from 'layout/PageLayout/PageLayout';
import { useHistory, useParams } from 'react-router-dom';

import useAddAnItemStyles from './AddAnItemStyle';

const AddAnItem: React.FC = () => {
  const { eventId, basketId } = useParams<{eventId: string, basketId: string}>();
  const history = useHistory();

  const classes = useAddAnItemStyles();

  return (
    <PageLayout
      title="Add an item"
      navigation
      backButton
    >
      <div className={classes.paper}>
        <Typography variant="h1" className={classes.margin}>
          Add A New Item
        </Typography>
        <GridContainerWithPadding container spacing={3} className={classes.container}>
          <Grid item xs={3}>
            <Button variant="contained" size="large" onClick={() => history.push(`/dailyDisposal/${eventId}/${basketId}`)} fullWidth className={classes.button}>
              Daily Disposal
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" size="large" onClick={() => history.push(`/airportCityTransfert/${eventId}/${basketId}`)} fullWidth className={classes.button}>
              Airport or City Transfer
            </Button>
          </Grid>
        </GridContainerWithPadding>
        <GridContainerWithPadding container spacing={3} className={classes.container}>
          <Grid item xs={3}>
            <Button variant="contained" size="large" onClick={() => history.push(`/carPass/${eventId}/${basketId}`)} fullWidth className={classes.button}>
              Accreditations
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" size="large" onClick={() => history.push(`/technicalSupports/${eventId}/${basketId}`)} fullWidth className={classes.button}>
              Technical support
            </Button>
          </Grid>
        </GridContainerWithPadding>
      </div>
    </PageLayout>
  );
};

export default AddAnItem;