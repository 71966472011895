import PageLayout from 'layout/PageLayout/PageLayout';
import { useEffect, useState } from 'react';
import { getStaticContents } from 'utils/getStaticContents';
import useStaticContentStyles from './StaticContentsStyles';

const Legal: React.FC = () => {

  const classes = useStaticContentStyles();

  const [textToDisplay, setTextToDisplay] = useState();

  useEffect(() => {
    getStaticContents().then(body => {
      if (body.result.legal_mentions !== undefined) {
        if (body.result.legal_mentions === false) {
          //@ts-ignore
          setTextToDisplay('<div>The Legal has not yet been initialized</div>');
        } else {
          setTextToDisplay(body.result.legal_mentions);
        }
      }
    });
  }, []);

  return (
    <PageLayout
      backButton
      navigation
      title="Legal"
    >

      <div
        className={classes.text}
        dangerouslySetInnerHTML={{
          //@ts-ignore
          __html: textToDisplay
        }}
      />

    </PageLayout>
  );
};

export default Legal;