import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import setLoggedIn from 'store/loggedIn';
import {
  useCurrentUserQuery
} from 'apollo/queries';
import { useUpdatePasswordMutation } from 'apollo/mutations';
import { login } from 'utils/authentication';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PageLayout from 'layout/PageLayout/PageLayout';
import useResetPasswordPageStyle from './ResetPasswordPageStyle';

/* Example link :
 * http://localhost:3000/forgotPassword?email=john.doe%loginline.com&token=mystringtoken
 */

const ResetPasswordPage: React.FC = () => {
  const classes = useResetPasswordPageStyle();
  const history = useHistory();

  const [loggedInState, setLoggedInState] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const {
    data: currentUserData,
    loading: isLoadingCurrentUser,
    error: currentUserError,
  } = useCurrentUserQuery(
    {
      skip: !loggedInState,
    },
  );
  const currentUserID: number = isLoadingCurrentUser || currentUserError ?
    -1 :
    currentUserData?.getMyData?.id ?? -1;

  // A custom hook that builds on useLocation to parse
  // the query string for you.
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query: URLSearchParams = useQuery();
  const emailAddr: string | null = query.get('email');
  const token: string | null = query.get('token');

  // log user with query params
  useEffect(
    () => {
      if (emailAddr !== null && token !== null) {
        login(
          {
            email: emailAddr,
            password: token
          }
        )
          .then(
            () => {
              setLoggedInState(true);
              // console.log(`[DEBUG] <ResetPasswordPage> Login successful for email ${emailAddr} !`);
            }
          )
          .catch(
            (error) => {
              setLoggedInState(false);
              localStorage.removeItem('session_id');
              console.error(`[ERROR] <ResetPasswordPage> Login failed for email ${emailAddr} :`, error);
            }
          );
      }
    },
    [
      emailAddr,
      token,
    ]
  );

  const [updatePassword] = useUpdatePasswordMutation();

  const updatePasswordHandler = useCallback(
    async () => {
      if (password !== '' && confirmPassword === password && currentUserID > -1) {
        try {
          await updatePassword(
            {
              variables: {
                id: currentUserID,
                password: password,
              }
            }
          );
          history.push('/login');
          setLoggedInState(true);
        } catch (error) {
          console.log(error);
          setLoggedInState(false);
          setLoggedIn(false);
          localStorage.removeItem('session_id');
        }
      }
    },
    [
      currentUserID,
      updatePassword,
      password,
      confirmPassword,
      history
    ],
  );

  const onPasswordChanged: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    setPassword(event.target.value);
  };

  const onConfirmPasswordChanged: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    setConfirmPassword(event.target.value);
  };

  return (
    <PageLayout>
      {
        loggedInState ?
          <div className={classes.paper}>
            <Typography variant="h1" className={classes.margin}>
              Set your new password
            </Typography>
            <Typography variant="body1" className={classes.margin}>
              {
                isLoadingCurrentUser ?
                  `Loading user...` :
                  `User : ${currentUserData?.getMyData?.firstname} ${currentUserData?.getMyData?.name}`
              }
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                required
                autoFocus
                fullWidth
                variant="outlined"
                id="password"
                label="New password"
                name="password"
                type="password"
                value={password}
                onChange={onPasswordChanged}
                InputLabelProps={{ shrink: true }}
                className={classes.margin}
              />
              <TextField
                required
                fullWidth
                variant="outlined"
                id="confirmPassword"
                label="Confirm password"
                name="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={onConfirmPasswordChanged}
                error={password !== confirmPassword && confirmPassword.length > 0}
                helperText={
                  password !== confirmPassword &&
                  confirmPassword.length > 0 &&
                  `Passwords don't match`
                }
                InputLabelProps={{ shrink: true }}
                className={classes.margin}
              />
              <Button
                variant="contained"
                size="large"
                onClick={updatePasswordHandler}
                disabled={
                  password !== confirmPassword ||
                  password.length < 1 ||
                  confirmPassword.length < 1 ||
                  currentUserID < 0
                }
              >
                Reset password
              </Button>
            </form>
          </div> :
          <div className={classes.paper}>
            Loading...
          </div>
      }
    </PageLayout>
  );
};

export default ResetPasswordPage;
