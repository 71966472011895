import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import PageLayout from 'layout/PageLayout/PageLayout';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { login } from 'utils/authentication';
import useInvitationViewStyles from './InvitationViewStyle';
import { getInvitation } from 'utils/getInvitation';
import GridContainerWithPadding from 'components/GridContainerWithPadding/GridContainerWithPadding';
import clsx from 'clsx';

type MasterInfos = {
  id: number;
  name: string | false;
  firstname: string | false;
  parent_id: {
    id: number;
    name: string;
  }
};

const InvitationView: React.FC = () => {
  const classes = useInvitationViewStyles();
  const history = useHistory();

  // A custom hook that builds on useLocation to parse
  // the query string for you.
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const email = query.get('email');
  const token = query.get('token');
  const hash = query.get('hash');
  const [loading, setLoading] = useState<boolean>(true);
  const [logged, setLogged] = useState<boolean | null>(null);
  const [masterInfos, setMasterInfos] = useState<MasterInfos>();
  const [userId, setUserId] = useState<number>();


  //log user with query params
  useEffect(() => {
    if (email !== null && token !== null) {
      login({ email, password: token })
        .then(
          () => {
            setLogged(true);
          }
        )
        .catch(
          (error) => {
            setLogged(false);
            history.push('/login');
          }
        );
    }
  }, [email, token, history]);


  useEffect(() => {
    if (email !== null && hash !== null && logged) {
      getInvitation({ email, hash })
        .then((body) => {
          setMasterInfos(body.result.master_account_id);
          setUserId(body.result.individual_booker_id.id);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [email, hash, logged]);

  if (loading) {
    return (
      <PageLayout>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', fontWeight: 'bold', fontSize: '24px' }}>
          <CircularProgress />
        </div>
      </PageLayout>
    );
  }

  if (masterInfos === undefined) {
    return (
      <PageLayout>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', fontWeight: 'bold', fontSize: '24px' }}>
          There is a problem with your invitation, please contact your master account
        </div>
      </PageLayout>
    );
  }
  return (
    <PageLayout>
      <GridContainerWithPadding container className={classes.container}>
        <Grid item xs={11} sm={10} md={9} lg={8}>
          <GridContainerWithPadding container spacing={2}>
            <Grid item xs={12} className={classes.margin}>
              <Typography variant="h3">You have been invited by :</Typography>
            </Grid>
            <Grid item xs={12} >
              <Typography variant="h1">{masterInfos?.firstname && masterInfos.firstname} {masterInfos?.name && masterInfos.name}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.margin}>
              <Typography variant="h3">{masterInfos.parent_id.name}</Typography>
            </Grid>
            <Grid item xs={12} className={clsx(classes.margin, classes.option)}>
              <span className={classes.caption}>To book transportation, you need to create an account or log in to your account</span>
            </Grid>
            <Grid item xs={12} className={clsx(classes.margin, classes.option)}>
              {logged ?
                <Button className={classes.button} variant="contained" size="large" onClick={() => history.push(`/signup/${userId}/${masterInfos.parent_id.id}`)}>
                  Create my account
                </Button> :
                <div>
                  <Button className={classes.button} variant="contained" size="large" onClick={() => history.push('/login')}>I already have an account</Button>
                </div>
              }
            </Grid>
          </GridContainerWithPadding>
        </Grid>
      </GridContainerWithPadding>
    </PageLayout>
  );
};

export default InvitationView;