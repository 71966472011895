import { makeStyles } from '@material-ui/core';

const useAirportOrCityTransferStyles = makeStyles({
  cards: {
    display: 'flex'
  },
  card: {
    width: '250px',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px'
  },
  selected: {
    backgroundColor: 'red'
  },
  transportRadio: {
    display: 'inline'
  },
  root: {
    color: 'black'
  }
});

export default useAirportOrCityTransferStyles;