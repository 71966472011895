import { makeStyles } from '@material-ui/core';

const useUnderlinedTitleStyles = makeStyles({
  underlined: {
    borderBottom: '2px solid #969798',
    paddingBottom: '10px',
    width: 'fit-content'
  }
});

export default useUnderlinedTitleStyles;
  