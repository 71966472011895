import { makeStyles } from '@material-ui/core';

const useIndividualHomeStyles = makeStyles({
  booking: {
    borderBottom: '1px solid black',
    marginBottom: '10px',
    textAlign: 'left',
    padding: '10px 0',
    width: '400px'
  },
  firstLine: {
    display: 'flex'
  },
  title: {
    alignSelf: 'center',
    borderBottom: '1px solid #CED4DA',
    padding: '0 !important',
    paddingBottom: '20px !important'
  },
  noPadding: {
    padding: '0px'
  },
  emptyText: {
    fontSize: '20px',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    color: '#CED4DA'
  },
  addButton: {
    marginTop: '20px'
  }
});

export default useIndividualHomeStyles;