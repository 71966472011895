import { makeStyles } from '@material-ui/core';

const useMASignupStyles = makeStyles({
  alignCenter: {
    textAlign: 'center',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  bottomButton: {
    bottom: '70px',
    textAlign: 'center',
  },
  fullHeight: {
    minHeight: '100%',
  },
  container: {
    minHeight: 'calc(100vh - 210px)',
  },
  step: {
    paddingTop: '50px !important',
  }
});

export default useMASignupStyles;