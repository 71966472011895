import { Button, CircularProgress, Dialog, DialogContent, Grid, IconButton, Typography } from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';
import config from 'config/config.json';
import SimCardDownloadRoundedIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { useAcceptQuoteMutation, useDeclineQuoteMutation } from 'apollo/mutations';
import useQuoteModalStyles from './QuoteModalStyle';
import { useMemo, useState } from 'react';
import { BasketFieldsLightFragment } from 'apollo/queries/types';
import { useHistory } from 'react-router';
import { withStyles } from '@material-ui/styles';
import { downloadFile } from 'utils/downloadFile';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  booking: BasketFieldsLightFragment;
};

const FullSizeDialog = withStyles({
  paper: {
    width: '100vw',
    height: '100vh',
    margin: '0',
    background: '#212529C8 0% 0% no-repeat padding-box',
  },
  root: {
    width: '100vw',
    height: '100vh'
  },
  paperScrollPaper: {
    maxHeight: 'unset'
  },
  paperWidthSm: {
    maxWidth: 'unset'
  }
})(Dialog);

const GridContainerWithLargePadding = withStyles({
  container: {
    paddingLeft: '150px',
    paddingRight: '150px'
  }
})(Grid);

const QuoteModal: React.FC<Props> = ({ isOpen, setIsOpen, booking }) => {

  const history = useHistory();
  const classes = useQuoteModalStyles();
  const [openCancelPolicyModal, setOpenCancelPolicyModal] = useState<boolean>(false);
  const [openCGVModal, setOpenCGVModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const token = localStorage.getItem('session_id');


  const downloadQuote = async () => {
    downloadFile({
      fileName: `Quote - ${booking.saleOrderId?.name}.pdf`,
      filePath: `${config.ODOO_HOST}/report/quotation/${booking.saleOrderId?.id}`,
    });
  };


  const displayedFileComponent = useMemo(() => {
    return (
      <Document
        file={{
          url: `${config.ODOO_HOST}/report/quotation/${booking.saleOrderId?.id}`,
          httpHeaders: {
            Accept: '*/*',
            'X-Openerp-Session-Id': token
          },
        }}
        onLoadError={console.error}
        loading={<CircularProgress />}
      // onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={1}
          height={600}
        />
      </Document>
    );
  }, [booking.saleOrderId?.id, token]);

  const [acceptQuoteMutation] = useAcceptQuoteMutation();
  const [declineQuoteMutation] = useDeclineQuoteMutation();

  const acceptQuote = async () => {
    if (booking.saleOrderId) {
      setLoading(true);
      try {
        await acceptQuoteMutation({
          variables: {
            id: booking.saleOrderId.id
          }
        });
        setLoading(false);
        setOpenCGVModal(false);
        setIsOpen(false);
        history.push(`/quoteConfirmed/${booking.eventId.id}`);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };

  const declineQuote = async () => {
    if (booking.saleOrderId) {
      setLoading(true);
      try {
        await declineQuoteMutation({
          variables: {
            id: booking.saleOrderId.id
          }
        });
        setLoading(false);
        setIsOpen(false);
        history.push('/');
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };

  const openCancelPolicy = () => {
    setIsOpen(false);
    setOpenCancelPolicyModal(true);
  };

  const validateCancelPolicy = () => {
    setOpenCancelPolicyModal(false);
    setOpenCGVModal(true);
  };

  const validateTOS = async () => {
    await acceptQuote();
  };

  return (
    <>
      <FullSizeDialog open={isOpen} onClose={() => setIsOpen(false)} >
        <DialogContent>
          <GridContainerWithLargePadding container spacing={3}>
            <Grid item lg={2}></Grid>
            <Grid item xs={4} lg={3} className={classes.center}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography className={classes.title}>
                        Quote N°
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => setIsOpen(false)}>
                        <CloseIcon className={classes.closeIcon} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.quoteNumber}>
                    {booking?.saleOrderId?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={downloadQuote}
                  >
                    Download&nbsp;&nbsp;<SimCardDownloadRoundedIcon />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    disabled={loading}
                    onClick={declineQuote}
                  >
                    {!loading ? <>Decline&nbsp;&nbsp;<CloseIcon /></> : <CircularProgress />}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => openCancelPolicy()}
                  >
                    Accept&nbsp;&nbsp;<CheckIcon />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={7} lg={6} className={classes.pdfViewer}>
              {displayedFileComponent}
            </Grid>
          </GridContainerWithLargePadding>
        </DialogContent>
      </FullSizeDialog>
      <FullSizeDialog open={openCancelPolicyModal} onClose={() => { setOpenCancelPolicyModal(false); }}>
        <DialogContent>
          <GridContainerWithLargePadding container spacing={3} style={{ height: 'calc(100vh - 20px)' }}>
            <Grid item xs={8} className={classes.textBlock}>
              {
                booking.pricelistId?.cancelPolicy
                  ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: booking.pricelistId.cancelPolicy
                      }}
                    />
                  )
                  : (
                    <Typography></Typography>
                  )
              }
            </Grid>
            <Grid item xs={4} lg={3} className={classes.center}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography className={classes.quoteNumber}>
                        Cancel Policy
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => setOpenCancelPolicyModal(false)}>
                        <CloseIcon className={classes.closeIcon} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => validateCancelPolicy()}
                    disabled={!booking.pricelistId?.cancelPolicy || booking.pricelistId?.cancelPolicy === '<p><br></p>'}
                  >
                    Accept&nbsp;&nbsp;<CheckIcon />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </GridContainerWithLargePadding>
        </DialogContent>
      </FullSizeDialog>
      <FullSizeDialog open={openCGVModal} onClose={() => { setOpenCGVModal(false); }}>
        <DialogContent>
          <GridContainerWithLargePadding container spacing={3} style={{ height: 'calc(100vh - 20px)' }}>
            <Grid item xs={8} className={classes.textBlock}>
              {
                booking.pricelistId?.cgv
                  ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: booking.pricelistId.cgv
                      }}
                    />
                  )
                  : (
                    <p>An error has occured. Please refresh the page</p>
                  )
              }
            </Grid>
            <Grid item xs={4} lg={3} className={classes.center}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography className={classes.quoteNumber}>
                        Terms of Service
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      {!loading && <IconButton onClick={() => setOpenCGVModal(false)}>
                        <CloseIcon className={classes.closeIcon} />
                      </IconButton>}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => { validateTOS(); }}
                    disabled={loading || !booking.pricelistId?.cgv || booking.pricelistId?.cgv === '<p><br></p>'}
                  >
                    {!loading ? <>Accept&nbsp;&nbsp;<CheckIcon /></> : <CircularProgress />}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </GridContainerWithLargePadding>
        </DialogContent>
      </FullSizeDialog>
    </>
  );
};

export default QuoteModal;