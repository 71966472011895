import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import CompanyInformation from 'pages/SignUp/CompanyInformation/CompanyInformation';
import CreatePassword from 'pages/SignUp/CreatePassword/CreatePassword';
import PersonalInformation from 'pages/SignUp/PersonalInformation/PersonalInformation';
import SignUpSummary from 'pages/SignUp/SignUpSummary/SignUpSummary';
import PageLayout from 'layout/PageLayout/PageLayout';
import React, { useEffect, useState } from 'react';
import { CompanyState } from 'type/Company';
import { User } from 'type/User';
import clsx from 'clsx';
import useSignupStyles from './SignupStyles';
import InvoiceAddressForm from './InvoiceAddress/InvoiceAddressForm';
import { InvoiceAddress } from 'type/InvoiceAddress';
import GridContainerWithPadding from 'components/GridContainerWithPadding/GridContainerWithPadding';
import { DivisionState } from 'type/Division';
import { useHistory, useParams } from 'react-router';
import { useGetCompanyInformationsQuery } from 'apollo/queries';
import { DivisionInformationsFragment } from 'apollo/queries/types';
import { useCreateNewDivisionMutation, useUpdateIbInformationsMutation } from 'apollo/mutations';

const stepNames: string[] = ['', 'Create Password', 'Personal information', 'Company information', 'Invoice address', 'Summary']; //Add a empty string at the beginning to respect the index of step

const SignUp: React.FC = () => {
  const history = useHistory();

  const { userId, companyId } = useParams<{ userId: string, companyId: string }>();

  const { data } = useGetCompanyInformationsQuery({
    variables: {
      id: parseInt(companyId)
    },
    skip: companyId === null
  });

  const [step, setStep] = useState<number>(1);

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [user, setUser] = useState<User>({
    firstName: '',
    lastName: '',
    gender: '',
    mobilePhone: '',
  });

  const [company, setCompany] = useState<CompanyState>({
    name: '',
    division: '',
    vatNumber: '',
    divisionName: ''
  });

  const [newDivision, setNewDivision] = useState<DivisionState>({
    name: '',
    vatNumber: '',
  });

  const [invoiceAddress, setInvoiceAddress] = useState<InvoiceAddress>({
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    zipCode: '',
    city: '',
    country: '',
  });

  const [creationLoading, setCreationLoading] = useState<boolean>(false);

  const classes = useSignupStyles();

  useEffect(() => {
    if (data?.getResPartner?.name) {
      setCompany({ ...company, name: data.getResPartner.name as string });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getResPartner?.name]);


  const [createdNewDivision] = useCreateNewDivisionMutation();
  const [updateIB] = useUpdateIbInformationsMutation();

  const createAccount = async () => {
    try {
      setCreationLoading(true);
      let newDivisionId = undefined;
      if (company.division === 'add') {
        //create division
        const result = await createdNewDivision({
          variables: {
            parentId: parseInt(companyId),
            name: newDivision.name,
            vatNumber: newDivision.vatNumber,
            street: invoiceAddress.address1,
            street2: invoiceAddress.address2 + ' ' + invoiceAddress.address3 + ' ' + invoiceAddress.address4,
            city: invoiceAddress.city,
            zipCode: invoiceAddress.zipCode,
            countryId: parseInt(invoiceAddress.country),
          }
        });
        newDivisionId = result.data?.createResPartner?.created?.id;
      }
      //updateResUser
      await updateIB({
        variables: {
          id: parseInt(userId),
          password: password,
          firstName: user.firstName,
          lastName: user.lastName,
          phoneNumber: user.mobilePhone,
          gender: parseInt(user.gender),
          divisionId: newDivisionId ? newDivisionId : parseInt(company.division),
        }
      });
      setCreationLoading(false);
      history.push('/login');
    } catch (err) {
      setCreationLoading(false);
      console.log(err);
    }
  };

  const moveToNextStep = () => {
    if (step === 1) {
      if (password !== '' && confirmPassword === password) {
        setStep(2);
      }
    } else if (step === 2) {
      if (user.firstName !== '' && user.lastName !== '' && user.gender !== '' && user.mobilePhone !== '') {
        setStep(3);
      }
    } else if (step === 3) {
      if (company.name !== '' && company.division !== 'add') {
        setStep(5);
      } else if (company.division === 'add' && newDivision.name !== '') {
        setStep(4);
      }
    } else if (step === 4) {
      if (invoiceAddress.address1 !== '' && invoiceAddress.city !== '' && invoiceAddress.country !== '' && invoiceAddress.zipCode !== '') {
        setStep(5);
      }
    } else {
      createAccount();
    }
  };

  return (
    <PageLayout>
      <Grid container spacing={3} className={classes.container}>
        <Grid item lg={1}></Grid>
        <Grid item xs={3} lg={2}>
          <Typography variant="h1">
            {stepNames[step]}
          </Typography>
          <Typography variant="h3">
            Step: {step}/5
          </Typography>
        </Grid>
        <Grid item xs={step === 4 || step === 5 ? 8 : 6} alignItems="center" className={clsx(classes.fullHeight, classes.alignCenter)}>
          <Grid container spacing={3}>
            {step === 1 && (
              <CreatePassword
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
              />
            )}
            {step === 2 && (
              <PersonalInformation
                user={user}
                setUser={setUser}
              />
            )}
            {step === 3 && (
              <CompanyInformation company={company} setCompany={setCompany} newDivision={newDivision} setNewDivision={setNewDivision} companyDivisions={data?.getResPartner?.divisionIds as DivisionInformationsFragment[]} />
            )}
            {step === 4 && (
              <InvoiceAddressForm invoiceAddress={invoiceAddress} setInvoiceAddress={setInvoiceAddress} />
            )}
            {step === 5 && (
              <SignUpSummary password={password} user={user} company={company} invoiceAddress={invoiceAddress} />
            )}
          </Grid>     

        </Grid>
      </Grid>
      <GridContainerWithPadding container className={classes.bottomButton} alignItems="center" alignContent="center">
        <Grid item xs={12} alignContent="center">
          {step !== 1 && <Button onClick={() => {
            if (step === 5 && company.division !== 'add') {
              setStep(3);
            } else {
              setStep(step - 1);
            }
          }}
          disabled={creationLoading}
          variant="contained"
          size="large"
          >
            Previous
          </Button>}
          <Button onClick={moveToNextStep} variant="contained" size="large">{!creationLoading ? <>{step === 5 ? 'Validate' : 'Next'}</> : <CircularProgress />}</Button>
        </Grid>
      </GridContainerWithPadding>
    </PageLayout>
  );
};


export default SignUp;