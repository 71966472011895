/* Framework imports ----------------------------------- */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { useCreatePassengerMutation } from 'apollo/mutations';
import { useGetMyPassengerLazyQuery } from 'apollo/queries';
import { PassengerFragment } from 'apollo/queries/types';
import AddPassengerForm from 'components/AddPassengerForm/AddPassengerForm';
import React, {
  useEffect,
  useState
} from 'react';
import { Passenger } from 'type/Passenger';
import useAddPassengerModalStyles from './AddPassengerModalStyle';

/* Module imports -------------------------------------- */

/* Component imports ----------------------------------- */

/* AddPassengerModal component prop types -------------------- */
interface AddPassengerModalProps {
  opened: boolean;
  setOpened: (value: boolean) => void;
  setPassenger: (passenger: PassengerFragment) => void;
  refetch: Function;
}

/* AddPassengerModal component ------------------------------- */
const AddPassengerModal: React.FC<AddPassengerModalProps> = ({ opened, setOpened, setPassenger, refetch }) => {

  const classes = useAddPassengerModalStyles();

  const [formValues, setFormValues] = useState<Passenger>({
    id: '',
    groupName: '',
    name: '',
    firstName: '',
    mail: '',
    phoneNumber: '',
    groupSize: 2,
    isGroup: false,
  });

  const [createPassengerMutation] = useCreatePassengerMutation();
  const [getMyPassengerQuery, { data }] = useGetMyPassengerLazyQuery();

  const handleValidate = async (): Promise<void> => {
    try {
      const variables = {
        firstname: formValues.firstName,
        name: formValues.name,
        email: formValues.mail,
        phone: formValues.phoneNumber,
      };
      const passengerCreated = await createPassengerMutation({
        variables: formValues.isGroup
          ? {
            ...variables,
            isGroup: true,
            groupName: formValues.groupName,
            groupSize: formValues.groupSize,
          } :
          {
            ...variables,
            isGroup: false,
          }
      });
      if (
        passengerCreated.data?.actionCreatePassenger !== undefined &&
        passengerCreated.data.actionCreatePassenger !== null &&
        passengerCreated.data.actionCreatePassenger.id !== null &&
        passengerCreated.data.actionCreatePassenger.id !== undefined
      ) {
        await getMyPassengerQuery({
          variables: {
            id: passengerCreated.data.actionCreatePassenger.id
          },
        });
        await refetch();
      }
    } catch (error) {
      console.error(`[AddPassenger.tsx] createPassenger error : ${JSON.stringify(error)}`);
    }
  };

  useEffect(() => {
    if (opened && data?.getResUsers !== undefined && data.getResUsers !== null) {
      setPassenger(data.getResUsers);
      setOpened(false);
    }
  }, [data?.getResUsers, setPassenger, setOpened, opened]);

  return (
    <Dialog
      open={opened}
      onClose={() => setOpened(false)}
    >
      <DialogTitle> Add a passenger </DialogTitle>
      {
        <DialogContent>
          <Grid container style={{ padding: '0 20px' }}>
            <AddPassengerForm
              formValues={formValues}
              setFormValues={setFormValues}
              fullWidth
            />
          </Grid>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={() => handleValidate()}
              color="primary"
              disabled={
                formValues.firstName.length === 0 ||
                formValues.name.length === 0 ||
                formValues.mail.length === 0 ||
                formValues.phoneNumber.length === 0 ||
                (
                  formValues.isGroup && (
                    formValues.groupName === undefined || formValues.groupName.length === 0 ||
                    formValues.groupSize === undefined || isNaN(formValues.groupSize) || formValues.groupSize < 2
                  )
                )
              }
            >
              Validate
            </Button>
          </DialogActions>
        </DialogContent>
      }

    </Dialog>
  );
};

/* Export AddPassengerModal component ------------------------ */
export default AddPassengerModal;
