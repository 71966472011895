import { makeStyles } from '@material-ui/core';

const useQuoteModalStyles = makeStyles({
  modal: {
    width: '80%',
    height: '80%',
    marginLeft: '10%',
    marginTop: '5%',
  },
  modalText: {
    width: '80%',
    height: '80%',
    marginLeft: '10%',
    marginTop: '5%',
    backgroundColor: '#171A1D',
    color: 'white',
    overflow: 'auto',
    whiteSpace: 'break-spaces',
  },
  modalTextDiv: {
    '& p': {
      color: 'white !important'
    }
  },
  buttonDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  center: {
    alignSelf: 'center'
  },
  pdfViewer: {
    height: 'calc(100vh - 20px)',
    '& canvas': {
      height: '100% !important',
      width: 'unset !important'
    },
    '& .react-pdf__Document': {
      height: '100%'
    },
    '& .react-pdf__Page': {
      height: '100%'
    }
  },
  title: {
    fontFamily: 'Karla',
    fontSize: '28px',
    fontWeight: 400
  },
  closeIcon: {
    fontSize: '35px',
    color: '#CED4DA',
    marginTop: '-21px',
    marginLeft: '10px'
  },
  quoteNumber: {
    fontSize: '32px',
    fontFamily: 'Karla',
    fontWeight: 700,
    borderBottom: '2px solid #797B7D',
    marginTop: '-20px'
  },
  textBlock: {
    alignSelf: 'center',
    textAlign: 'center',
    whiteSpace: 'break-spaces',
    '& p': {
      color: 'white !important',
      fontFamily: 'Montserrat !important',
      fontSize: '16px !important'
    },
    maxHeight: '100%',
    overflowY: 'auto'
  }
});

export default useQuoteModalStyles;