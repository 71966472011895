import { makeStyles } from '@material-ui/core';

const useSignupStyles = makeStyles({
  alignCenter: {
    textAlign: 'center',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  bottomButton: {
    bottom: '70px',
    textAlign: 'center',
  },
  fullHeight: {
    minHeight: '100%',
  },
  container: {
    minHeight: 'calc(100vh - 210px)',
  }
});

export default useSignupStyles;