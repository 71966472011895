import { Card } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import useSelectableCardStyles from './SelectableCardStyles';

interface SelectableCardProps {
  selected: boolean;
  onSelect: () => void;
  title: string;
  subtitle?: string | undefined | null;
}

const SelectableCard: React.FC<SelectableCardProps> = ({ selected, onSelect, title, subtitle }) => {

  const classes = useSelectableCardStyles();

  return (
    <Card 
      className={clsx(classes.vehicleCard, selected ? classes.selected : '')}
      onClick={() => onSelect()}
    >
      <div className={classes.vehicleName}>{ title }</div>
      {
        subtitle !== null && subtitle !== undefined && <div className={clsx(classes.vehicleDescription, selected ? classes.selectedDescription : '')}>{ subtitle }</div>
      }
    </Card>
  );
};

export default SelectableCard;