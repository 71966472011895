import { makeStyles } from '@material-ui/core';

const useCarPassStyles = makeStyles({
  map: {
    width: '100%'
  },
  mapContainer: {
    height: 'auto',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '18px',
    color: '#CED4DA',
    marginTop: '30px',
    marginBottom: '15px'
  },
  type: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  },
  typeName: {
    marginRight: '10px',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: '16px',
    color: '#CED4DA',
    overflowWrap: 'break-word'
  },
  typeQuantity: {
    margin: '0',
    height: '40px'
  },
  price: {
    marginTop: '30px'
  },
  verticalAlign: {
    alignSelf: 'center'
  },
  modal:{
    width: '80%',
    height: '80%',
    marginLeft: '10%',
    marginTop: '5%',
  },
  modalMap:{
    height: '100%',
    width: '100%',
  },
  info: {
    fontSize: '18px'
  }
});

export default useCarPassStyles;