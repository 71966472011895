import { makeStyles } from '@material-ui/core';

const useSelectableCardStyles = makeStyles({
  vehicleCard:{
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '12px',
    padding: '15px',
    backgroundColor: '#171A1D',
    border: '2px solid #CED4DA',
    borderRadius: '10px',
    color: '#FFFFFF',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  vehicleName: {
    fontWeight: 700,
    fontFamily: 'Montserrat',
    fontSize: '17px'
  },
  selected: {
    backgroundColor: '#CED4DA',
    color: '#212529',
  },
  vehicleDescription: {
    fontWeight: 300,
    fontSize: '14px',
    fontFamily: 'Montserrat',
    color: '#CED4DA'
  },
  selectedDescription: {
    color: '#495057'
  }
});

export default useSelectableCardStyles;