import { makeStyles } from '@material-ui/core/styles';

const useResetPasswordPageStyle = makeStyles(
  (theme) => (
    {
      paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 156px)',
      },
      form: {
        width: '494px', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
      margin: {
        marginBottom: '55px'
      },
      alignCenter: {
        textAlign: 'center',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
      },
      bottomButton: {
        bottom: '70px',
        textAlign: 'center',
      },
      fullHeight: {
        minHeight: '100%',
      },
      container: {
        minHeight: 'calc(100vh - 210px)',
      },
      step: {
        paddingTop: '50px !important',
      }
    }
  )
);

export default useResetPasswordPageStyle;
