import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ListMyPassengersQuery, PassengerFragment, VehicleFieldsFragment } from 'apollo/queries/types';
import AddPassengerModal from 'components/AddPassengerModal/AddPassengerModal';
import DatePicker from 'components/DatePicker/DatePicker';
import VehicleSelector from 'components/VehicleSelector/VehicleSelector';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { DailyLine, DailyLineID } from 'type/DailyLine';
import { daysBetween } from 'utils/datesManipulation';
import { formatPrice } from 'utils/formatPrice';

interface EditDailyLineModalProps {
  dailyLine: DailyLine;
  setDailyLine: (dailyLine: DailyLine) => void;
  onValidate: () => Promise<void>;
  vehicles: VehicleFieldsFragment[] | undefined;
  priceline: DailyLineID | undefined;
  passengersList?: ListMyPassengersQuery;
  passengersLoading: boolean;
  dailyAlreadyTakenPassengersIds: number[];
  refetch: Function;
}

const useStyles = makeStyles({
  root: {
    color: 'black'
  }
});

const EditDailyLineModal = forwardRef((props: EditDailyLineModalProps, ref) => {

  const classes = useStyles();

  const initialDailyLine = useMemo(() => props.dailyLine, []);

  const [opened, setOpened] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleValidate = async () => {
    try {
      await props.onValidate();
      setOpened(false);
    } catch (err) {
      console.log(err);
    }
  };

  const open = () => {
    setOpened(true);
  };

  useImperativeHandle(ref, () => ({
    open
  }));

  const price = (props.priceline?.pricePerDay || 0) * (daysBetween(new Date(props.dailyLine.startDate), new Date(props.dailyLine.endDate)) + 1);

  const selectedVehicleDetails = useMemo(() => {
    if (props.vehicles !== null && props.vehicles !== undefined && props.dailyLine.vehicle !== null) {
      const vehicle = props.vehicles.find((vehicle) => vehicle.id === props.dailyLine.vehicle);
      return vehicle || null;
    } else {
      return null;
    }
  }, [props.vehicles, props.dailyLine.vehicle]);

  return (
    <Dialog
      open={opened}
      onClose={() => setOpened(false)}
    >
      <DialogTitle> Editing Daily disposal </DialogTitle>
      {
        props.dailyLine && (
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="none"
                  id="date"
                  label="Starting Date"
                  value={props.dailyLine.startDate}
                  onChange={(date) => date !== null && props.setDailyLine({ ...props.dailyLine, startDate: date?.toISOString() })}
                  inputVariant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="none"
                  id="date"
                  label="Finishing Date"
                  value={props.dailyLine.endDate}
                  onChange={(date) => date !== null && props.setDailyLine({ ...props.dailyLine, endDate: date?.toISOString() })}
                  inputVariant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                {
                  props.vehicles && (
                    <VehicleSelector
                      selectedVehicle={props.dailyLine.vehicle}
                      setSelectedVehicle={(vehicleId: number) => props.setDailyLine({ ...props.dailyLine, vehicle: vehicleId })}
                      options={props.vehicles}
                    />
                  )
                }
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={[{ id: null }, ...props.passengersList?.searchResUsers || []]}
                  value={props.dailyLine.passenger}
                  getOptionLabel={
                    (passenger) => {
                      if (passenger.id === null) {
                        return 'Create a new passenger';
                      } else {
                        return passenger.isPassengersGroup ? `${passenger.groupName}` : `${passenger.name} ${passenger.firstname}`;
                      }
                    }
                  }
                  renderInput={(params) => props.passengersLoading ? <CircularProgress /> : <TextField {...params} label={props.dailyLine.passenger?.isPassengersGroup ? 'Passenger group name' : 'Passenger'} />}
                  classes={{
                    paper: classes.root,
                  }}
                  onChange={
                    (event, newValue) => {
                      if (newValue === null) {
                        props.setDailyLine({ ...props.dailyLine, passenger: newValue });
                      } else if (newValue.id === null) {
                        setOpenModal(true);
                      } else {
                        props.setDailyLine({ ...props.dailyLine, passenger: newValue });
                      }
                    }
                  }
                />
                {
                  selectedVehicleDetails !== null &&
                  props.dailyLine.passenger !== null &&
                  props.dailyLine.passenger.isPassengersGroup &&
                  props.dailyLine.passenger.groupSize &&
                  props.dailyLine.passenger.groupSize > selectedVehicleDetails.numberSeats! &&
                  <div>
                    Selected vehicle has less seats than the group size
                  </div>
                }
              </Grid>
            </Grid>
            <DialogActions>
              <Typography>
                {`Price: ${formatPrice(price, props.priceline?.currency.symbol || '£')}`}
              </Typography>
              {props.dailyLine.passenger !== null && initialDailyLine.passenger !== null &&
                <>
                  <Button
                    onClick={() => handleValidate()}
                    color="primary"
                    autoFocus
                    variant="outlined"
                    style={{ marginLeft: '30px' }}
                    disabled={props.dailyLine.passenger.id !== initialDailyLine.passenger.id && props.dailyAlreadyTakenPassengersIds.includes(props.dailyLine.passenger.id)}
                  >
                    Validate
                  </Button>
                  <div>
                    {
                      props.dailyLine.passenger.id !== initialDailyLine.passenger.id && props.dailyAlreadyTakenPassengersIds.includes(props.dailyLine.passenger.id) &&
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        This passenger already has a Daily Disposal
                      </div>
                    }
                  </div>
                </>
              }
            </DialogActions>
          </DialogContent>
        )
      }
      <AddPassengerModal
        opened={openModal}
        setOpened={setOpenModal}
        setPassenger={(value: PassengerFragment) => { props.setDailyLine({ ...props.dailyLine, passenger: value }); }}
        refetch={props.refetch}
      />
    </Dialog>
  );
});

export default EditDailyLineModal;